/*
 * The controller is basically just a collection of methods that correspond
 * to routes and are called by the application router.
 */

/*jslint browser: true*/
define('routers/controller',[

    // Application.
    'app',

    // Misc.
    'main/settings',

    'main/auth',

    // Modules
    "main/UI",
    "main/lib",
    "modules/chat",
    "modules/account",
    "modules/contact",
    "main/integrations",
    "main/support",
    "main/setup",
], function (App, Settings, session, UI, Lib, Chat, Account, Contact, Integrations, support, Setup) {

    var region = new Backbone.Marionette.Region({
        template: "layouts/body",
        el: "#content"
    });

    return {
        // The Vox page.
        vox_thread: function (thread_id) {
            // If is an invite link we check for suggested user_id
            if ((!App.Auth.authenticated() && get_parameter_by_name('suggested_user_id')) ||
                get_parameter_by_name('email')) {
                if (!App.sign_up_data) {
                    App.sign_up_data = new Account.SignUpModel();
                }
                App.sign_up_data.set({
                    'thread_id': thread_id,
                    'suggested_user_id': get_parameter_by_name('suggested_user_id'),
                    'invitee_details': get_parameter_by_name('invitee_details')
                });

                return App.Router.navigate("/sign_up/step1", {trigger: true});
            }
            // ENG-2750 - if there's no logged in user, it's better to redirect to login page
            if (!App.Auth.authenticated()) {
                return App.Router.navigate("/login", { trigger: true })
            }

            UI.setTitle('Chats');
            UI.menu("Chats");
            App.vent.trigger('metrics', 'track', {stuff: 'abc'});
            UI.context_button.show();

            var version = detectBrowser();
            if (version.indexOf('msie 9') !== -1) {
                $('.chat-panel .panel-body').each(function (idx, scroll) {
                    var $scroll = $(scroll);
                    $scroll.prop({scrollTop: $scroll.prop("scrollHeight")});
                });
            }

            $(window).trigger('resize');

            var active = {};
            active[thread_id] = (new Date()) / 1000;
            Settings.set({'active_chats': JSON.stringify(active)});
            if (App.Settings.get('finished_setup')) {
                App.vent.trigger('render_active_chats');
            }
        },
        vox: function () {
            UI.setTitle('Chats');
            UI.menu(Backbone.history.location.hash);
            App.vent.trigger('metrics', 'track', {stuff: 'abc'});
            UI.context_button.show();

            var version = detectBrowser();
            if (version.indexOf('msie 9') !== -1) {
                $('.chat-panel .panel-body').each(function (idx, scroll) {
                    var $scroll = $(scroll);
                    $scroll.prop({scrollTop: $scroll.prop("scrollHeight")});
                });
            }

            $(window).trigger('resize');
        },
        new_chat: function () {
            UI.setTitle('Chats');
            UI.menu("Chats");

            App.vent.trigger('metrics', 'track', {stuff: 'abc'});

            UI.context_button.show();

            if (!App.Auth.authenticated()) {
                App.vent.once('render_active_chats', function () {
                    App.layout.regionContent.currentView.left.$el.find('.new_chat').click();
                });
                App.Router.navigate('/chats', {trigger: true});
            }
            else if (App.layout.regionContent.currentView && App.layout.regionContent.currentView.left.$el) {
                App.layout.regionContent.currentView.left.$el.find('.new_chat').click();
            }

        },
        // The main page.
        index: function () {
            if (!App.Auth.authenticated()) {
                App.Router.navigate('/login', {trigger: true});
            } else {
                App.Router.navigate('/chats', {trigger: true});
            }
        },
        // The login page.
        login: function () {
            region.reset();

            if (localStorage.hash) {
                App.Router.navigate('/chats', {trigger: true});
                return;
            }

            Setup._home_router().then(function (home_router) {
                home_router = JSON.parse(home_router);
                App.home_router = 'https://' + home_router.router;
                App.Settings.set({home_router: App.home_router});

                App.layout.regionHeader.currentView.menuRegion.destroy();
                if (App.shoulderLayout) {
                    App.shoulderLayout.destroy();
                }
                if (App.layout.regionContacts.$el)
                    App.layout.regionContacts.$el.hide();
                if (App.layout.regionSettings.$el)
                    App.layout.regionSettings.$el.hide();
                if (App.layout.regionSupport.$el)
                    App.layout.regionSupport.$el.hide();
                if (App.layout.regionManageTeam.$el)
                    App.layout.regionManageTeam.$el.hide();
                if (App.layout.regionIntegrations.$el)
                    App.layout.regionIntegrations.$el.hide();
                if (App.layout.regionInvites.$el)
                    App.layout.regionInvites.$el.hide();
                if (App.layout.regionToPro.$el)
                    App.layout.regionToPro.$el.hide();

                region.show(new Account.Views.Login({
                    voxer: {},
                    router: 'a string',
                    flash: Settings.fetch('flash') || ""
                }));
                Settings.delete('flash');
            });
        },
        login_sso: function () {
            region.reset();

            if (localStorage.hash) {
                App.Router.navigate('/chats', {trigger: true});
                return;
            }

            Setup._home_router().then(function (home_router) {
                home_router = JSON.parse(home_router);
                App.home_router = 'https://' + home_router.router;
                App.Settings.set({home_router: App.home_router});

                App.layout.regionHeader.currentView.menuRegion.destroy();
                if (App.shoulderLayout) {
                    App.shoulderLayout.destroy();
                }
                if (App.layout.regionContacts.$el)
                    App.layout.regionContacts.$el.hide();
                if (App.layout.regionSettings.$el)
                    App.layout.regionSettings.$el.hide();
                if (App.layout.regionSupport.$el)
                    App.layout.regionSupport.$el.hide();
                if (App.layout.regionManageTeam.$el)
                    App.layout.regionManageTeam.$el.hide();
                if (App.layout.regionIntegrations.$el)
                    App.layout.regionIntegrations.$el.hide();
                if (App.layout.regionInvites.$el)
                    App.layout.regionInvites.$el.hide();
                if (App.layout.regionToPro.$el)
                    App.layout.regionToPro.$el.hide();

                region.show(new Account.Views.LoginSso({
                    voxer: {},
                    router: 'abc',
                    flash: Settings.fetch('flash') || ""
                }));
                Settings.delete('flash');
            });
        },
        login_saml_sso: function (response) {
            const business_id = localStorage.getItem('business_id');

            if (business_id) {
                const credentials = {
                    sso_response: response,
                    business_id: business_id,
                    sso_type: 'ping',
                    sso_ping_saml_auth: true
                };
                App.vent.trigger('login', credentials);
            } else {
                const decodedResponse = atob(response);
                const attributes = JSON.parse(decodedResponse);
                if (attributes.email) {
                    App.API.sso_provider({
                        email: attributes.email,
                        client_type: "web"
                    }, {
                        router: App.Settings.get('home_router')
                    }, function(error, sso_provider_response) {
                        if(error && (sso_provider_response.status === 500 || sso_provider_response.status === 404)) {
                            console.log('Error: ', error);
                            window.location.href = App.Settings.get('home_router') + '/login_sso';
                        }
                    }, function(sso_provider_response) {
                        // For methods like SAML that require password
                        if (sso_provider_response.sso_method && sso_provider_response.sso_method === 'saml') {
                            if (sso_provider_response.businessId) {
                                localStorage.setItem('business_id', sso_provider_response.businessId);
                                const credentials = {
                                    sso_response: response,
                                    business_id: sso_provider_response.businessId,
                                    sso_type: 'ping',
                                    sso_ping_saml_auth: true
                                };
                                App.vent.trigger('login', credentials);
                            }
                        }
                    });
                }
            }      
        },
        authcallback: function (access_token) {
            var sso_token = access_token;

            if (!sso_token) {
                const url = window.location.href;
                const hash = url.split('#')[1];

                if (hash) {
                    const params = new URLSearchParams(hash);
                    const business_id = localStorage.getItem('business_id');
                    sso_token = params.get('access_token');

                    if (sso_token && business_id) {
                        const credentials = {
                            sso_token: sso_token,
                            business_id: business_id,
                            sso_type: 'ping'
                        };

                        App.vent.trigger('login', credentials);
                    }
                    return;
                } 
            }

            App.Router.navigate('/login', { trigger: true });
        },
        logout: function () {
            clearInterval(App.Updates.monitor);
            App.Updates.stop();
            App.Auth.logout();
            var save = {
                'finished_setup': Settings.fetch('finished_setup'),
                'flash': 'You have logged out. Please come back soon!',
                'first_render': Settings.fetch('first_render'),
                'sound-level': Settings.fetch('sound-level'),
                'sound-on': Settings.fetch('sound-on'),
                'sort-by': Settings.fetch('sort-by'),
                'cache-timeline': Settings.fetch('cache-timeline'),
                'talk-method': Settings.fetch('talk-method'),
                'desktop-notifications': Settings.fetch('desktop-notifications'),
                'desktop-notifications-dismissed': Settings.fetch('desktop-notifications-dismissed'),
                'reply-pop-up': Settings.fetch('reply-pop-up'),
                'reply-pop-up-dismissed': Settings.fetch('reply-pop-up-dismissed'),
                'users_routes': Settings.fetch('users_routes'),
                'location': Settings.fetch('location')
            };
            Settings.destroy();
            Settings.set(save);

            App.chats.reset(null, { silent: true });
            App.messages.reset(null, { silent: true });
            App.profiles.reset(null, { silent: true });
            App.contacts.reset(null, { silent: true });
            App.MyProfile.set({
                "initial_timestamp": undefined,
                "first": undefined,
                "last": undefined,
                "dialing_code": undefined,
                "email": undefined,
                "username": undefined,
                "type_of_signup": undefined,
                "last_auth_source": undefined,
                "device_info": undefined,
                "is_validated": undefined,
                "display_name": undefined,
                "phone": undefined,
                "city": undefined,
                "geo": undefined,
                "image_id": undefined,
                "account_flags": undefined,
                "business_ids": undefined,
                "user_identities": undefined
            });
            // redirect
            App.Router.navigate('/login', {trigger: true});
            App.layout.regionContent.$el.show();
        },
        forgot_password: function () {
            region.show(new Account.Views.ForgotPassword({
                voxer: {},
                router: 'abc'
            }));
        },
        sign_up: function (step) {
            if (!App.sign_up_data)
                App.sign_up_data = new Account.SignUpModel();

            region.show(new Account.Views.SignUp({
                voxer: {},
                step: step || 'step1',
                model: App.sign_up_data,
                router: 'abc'
            }));
        },
        change_password: function (params) {
            var id = App.Auth.get('password_id');
            if (id !== undefined) {
                region.show(new Account.Views.ChangePassword({
                    password_id: id
                }));
            }
        },

        // The Support page.
        upgrade: function () {

            UI.setTitle('Upgrade to PRO');
            // set the menu item
            UI.menu('/upgrade');

            // shoulder
            UI.context_button.hide();

            if (!App.Auth.authenticated()) {
                // If user is not autentificated let's return after autentification
                App.vent.once('render_active_chats', function () {
                    App.Router.navigate('/upgrade', {trigger: true});
                });
                App.Router.navigate('/chats', {trigger: true});
            }
            // render
            App.layout.regionToPro.show(new UI.Views.UpgradeView());


        },
        pay_for_others: function () {
            if (!App.Auth.authenticated()) {
                // If user is not autentificated let's return after autentification
                App.vent.once('render_active_chats', function () {
                    App.Router.navigate('/pay-for-others', {trigger: true});
                });
                App.Router.navigate('/chats', {trigger: true});
            } else {
                if (App.layout.regionContent.$el)
                    App.layout.regionContent.$el.hide();
                if (App.layout.regionContacts.$el)
                    App.layout.regionContacts.$el.hide();
                if (App.layout.regionSettings.$el)
                    App.layout.regionSettings.$el.hide();
                if (App.layout.regionToPro.$el)
                    App.layout.regionToPro.$el.hide();

                App.layout.regionManageTeam.show(new UI.Views.PayTeams());
                App.layout.regionManageTeam.$el.show();
            }
             UI.setTitle('Manage PRO users');

        },
        manage_members: function () {
            if (!App.Auth.authenticated()) {
                // If user is not autentificated let's return after autentification
                App.vent.once('render_active_chats', function () {
                    App.Router.navigate('/add-team-members', {trigger: true});
                });
                App.Router.navigate('/chats', {trigger: true});
            } else {
                if (App.layout.regionContent.$el)
                    App.layout.regionContent.$el.hide();
                if (App.layout.regionContacts.$el)
                    App.layout.regionContacts.$el.hide();
                if (App.layout.regionSettings.$el)
                    App.layout.regionSettings.$el.hide();
                if (App.layout.regionToPro.$el)
                    App.layout.regionToPro.$el.hide();

                App.layout.regionManageTeam.show(new UI.Views.ManageTeamSubscriptions());
                App.layout.regionManageTeam.$el.show();
            }
             UI.setTitle('Add PRO users');

        },
        contact: function (id) {
            if (!App.Auth.authenticated()) {
                // If user is not autentificated let's return after autentification
                App.vent.once('render_active_chats', function () {
                    App.Router.navigate('/contact/'+ id , {trigger: true});
                });
                App.Router.navigate('/chats', {trigger: true});
            }


            if (id === App.Auth.get('user_id')) {
                return App.Router.navigate('/profile', {trigger: true});
            }

            this.contacts(id);
            var deferred =new $.Deferred(),
                profile = App.profiles.get(id);

            if (profile) {
                deferred.resolve(profile);
            } else {
                App.profiles.get_profile(id, false).then(function (results) {
                    deferred.resolve(new App.Profile.Model(results[0]));
                });
            }

            deferred.then(function (profile) {
                // get the canvas for the profile view to render
                var profile_region = App.UI.ViewsContainer.findByCustom('Contact.Layout').regionManager.get('workspace');
                var contacts_region = App.UI.ViewsContainer.findByCustom('Contact.Layout').regionManager.get('left');
                // profile view
                var profile_view = new Contact.Views.Contact({
                    model: profile,
                    id: profile.cid
                });
                profile_region.show(profile_view);

                contacts_region.$el.find('li.contact-item.active').removeClass('active');
                contacts_region.$el.find('#' + profile.cid).addClass('active');
            });
        },

        contacts: function (id) {
            // window title
            UI.setTitle("Contacts")
            // set the menu item
            UI.menu('/contacts');
            // shoulder
            UI.context_button.hide();

            // determine what portion of profiles we're going to show
            var profiles_context = App.Settings.get('sort') || "Contacts";
            if (id) {
                if (id.indexOf('biz_team') !== -1) {
                    profiles_context = 'Teams';
                }
                else {
                    profiles_context = 'Contacts';
                }
            }

            if (!App.UI.ViewsContainer.findByCustom('Contact.Layout')) {
                var contact_split = new UI.Layouts.SplitBody();
                App.layout.regionContacts.show(contact_split);
                // store the contacts Layout for later use
                App.UI.ViewsContainer.add(contact_split, 'Contact.Layout');
            }

            var contacts_region = App.UI.ViewsContainer.findByCustom('Contact.Layout').regionManager.get('left');


            // load the profiles collection
            var profiles = Lib.FilteredCollection(App.profiles);

            // the list view
            var list_layout = new Contact.Layouts.List();

            // only render region once on load
            if (!contacts_region.currentView) {
                // where was Contact.Layout added to the views babysitter?
                App.UI.ViewsContainer.findByCustom('Contact.Layout').regionManager.get('left').show(list_layout);

                // the contacts/teams list view
                var collection_view = new Contact.Views.List({
                    context: profiles_context,
                    collection: profiles,
                    active: id
                });
                // the selector view
                var collection_selector_view = new Contact.Views.Selector({
                    context: profiles_context,
                    list_view: collection_view
                });

                list_layout.selector.show(collection_selector_view);
                list_layout.list.show(collection_view);

            }
            else {
                // in case a profile was rendered before, remove it
                if (App.UI.ViewsContainer.findByCustom('Contact.Layout').regionManager.get('workspace').currentView) {
                    App.UI.ViewsContainer.findByCustom('Contact.Layout').regionManager.get('workspace').currentView.destroy();
                    $(list_layout.list.el + ' .active').removeClass('active');
                }
            }
            contacts_region.$el.show();
            //empty view
            var empty_region = App.UI.ViewsContainer.findByCustom('Contact.Layout').regionManager.get('workspace');
            var empty_view = new Contact.Views.EmptyContactsView();
                empty_region.show(empty_view);
        },

        u: function (username) {
            // redirect user to login page if not authenticated
            if (!App.Auth.authenticated()) {
                return App.Router.navigate("/login", { trigger: true })
            }

            var deferred = new $.Deferred(),
                contacts_region = false;
            try {
                contacts_region = App.UI.ViewsContainer.findByCustom("Contact.Layout").regionManager.get("left").currentView

                // Render the left view if is not displayed yet
                if (!contacts_region) {
                    // this redirection is bad, this was breaking share profile feature
                    // App.Router.navigate('/contacts', {trigger: true});
                    contacts_region = App.UI.ViewsContainer.findByCustom("Contact.Layout").regionManager.get("left").currentView
                }
            } catch (err) {
                console.error(err)
            }

            UI.setTitle(username + "'s Profile")
            App.vent.trigger("metrics", "/view_deep_link_profile", { client: "web" })
            if (App.Auth.authenticated() && App.Settings.fetch("user_id")) {
                deferred.resolve()
            } else {
                App.Auth.getAuth().done(function (session_data) {
                    deferred.resolve(session_data)
                })
            }

            deferred.done(function () {
                App.profiles.get_profile_by_username(username).then(function (user) {
                    UI.show_region("contacts")
                    if (user) {
                        if (App.profiles.get(user.user_id)) {
                            // if user exist in contacts list, redirect to user contact page
                            App.Router.navigate("/contact/" + user.user_id, { trigger: true })
                        } else {
                            //App.profiles.get_profile(user.user_id, false).then(function (profiles) {
                                var profile = new App.Profile.Model(user)
                                // window title
                                UI.setTitle(profile.name() + "'s Profile")
                                // get the canvas for the profile view to render
                                App.UI.ViewsContainer.findByCustom("Contact.Layout")
                                    .regionManager.get("workspace")
                                    .show(
                                        new Contact.Views.Contact({
                                            model: profile,
                                            id: user.user_id,
                                        })
                                    )

                                if (contacts_region && contacts_region.$el.find("li.contact-item.active")) {
                                    contacts_region.$el.find(".active").removeClass("active")
                                    contacts_region.$el.find("#" + user.user_id).addClass("active")
                                }
                            // }).fail(function(err) {
                            //     console.log(err)
                            //     App.Router.navigate('/contacts', {trigger: true})
                            // })
                        }
                    } else {
                        // redirect to contact list if unable to find profile by username
                        App.Router.navigate('/contacts', {trigger: true})
                    }
                }).fail(function(err) {
                    console.log(err)
                    App.Router.navigate('/contacts', {trigger: true})
                })
            })
        },

        profile: function () {

            if (!App.Auth.authenticated()) {
                // If user is not autentificated let's return after autentification
                App.vent.once('render_active_chats', function () {
                    App.Router.navigate('/profile', {trigger: true});
                });
                App.Router.navigate('/chats', {trigger: true});
            }

            var contacts_region_displayed = false;
            try {
                contacts_region_displayed = App.UI.ViewsContainer.findByCustom('Contact.Layout')
                                               .regionManager.get('left').currentView;

                // Render the left view if is not displayed yet
                if (!contacts_region_displayed) {
                    this.contacts();
                } else {
                    // in case a profile was rendered before, remove it
                    if (App.UI.ViewsContainer.findByCustom('Contact.Layout').regionManager.get('workspace').currentView) {
                        App.UI.ViewsContainer.findByCustom('Contact.Layout').regionManager.get('workspace').currentView.destroy();

                        contacts_region_displayed.$el.find('.active').removeClass('active');
                    }
                }
            } catch (err) {
                console.error(err);
            }

            UI.setTitle('My profile');
            App.vent.trigger('metrics', 'track', {view: 'profile'});

            // shoulder
            UI.context_button.hide();
            var my_profile_view = new Contact.Views.MyProfile({
                model: App.MyProfile
            });
            my_profile_view.on('render', function () {
                this.$('img.lazy').lazyload({
                    effect: 'fadeIn'
                });
            });
            App.layout.regionContacts.currentView.workspace.show(my_profile_view);
            my_profile_view.trigger('render');
        },
        invites: function () {
            UI.setTitle('Invite Friends to Voxer');
             // set the menu item
            UI.menu('/invites');
            // shoulder

            UI.context_button.hide();
            if (!App.Auth.authenticated()) {
                // If user is not autentificated let's return after autentification
                App.vent.once('render_active_chats', function () {
                    App.Router.navigate('/invites', {trigger: true});
                });
                App.Router.navigate('/chats', {trigger: true});
            }

            // render
            App.layout.regionInvites.show(new UI.Views.InvitesView());
        },
        integrations: function () {
            UI.setTitle('Integrations');

            if (!App.Auth.authenticated()) {
                App.vent.once('render_active_chats', function () {
                    App.Router.navigate('/integrations', {trigger: true});
                });
                App.Router.navigate('/chats', {trigger: true});
            }


            // render
            App.layout.regionIntegrations.show(new Integrations.Views.IntegrationView());

        },
        accountdeletion: function () {
            UI.setTitle('Account Deletion');

            if (!App.Auth.authenticated()) {
                App.vent.once('render_active_chats', function () {
                    App.Router.navigate('/accountdeletion', {trigger: true});
                });
                App.Router.navigate('/', {trigger: true});
            }


            // render
            App.layout.regionAccountdeletion.show(new Accountdeletion.Views.AccountdeletionView());

        },
        // The Settings page.
        settings: function (type) {

            UI.setTitle('Settings');
            App.vent.trigger('metrics', 'track', {stuff: 'abc'});

            App.splitBodyLayout = new UI.Layouts.SplitBody();
            App.layout.regionSettings.show(App.splitBodyLayout);


            // shoulder
            UI.context_button.hide();

            if (!App.Auth.authenticated()) {
                // If user is not autentificated let's return after autentification
                App.vent.once('render_active_chats', function () {
                    if(type == 'myaccount' ){
                        App.Router.navigate('/myprofile', {trigger: true});
                        App.UI.ViewsContainer.findByCustom('Contact.Layout')
                           .regionManager.get('workspace')
                           .currentView.show_edit_profile_modal();
                    } else{
                        App.Router.navigate('/settings', {trigger: true});
                    }
                });
                App.Router.navigate('/chats', {trigger: true});
            }

            var settingsMenu = new Account.Views.SettingsMenu();
            var settingsClient = new Account.Views.SettingsClient({settings: 'webclient'});

            if(type == 'myaccount' && App.Auth.authenticated() ){
                App.Router.navigate('/myprofile', {trigger: true});
                App.UI.ViewsContainer.findByCustom('Contact.Layout')
                   .regionManager.get('workspace')
                   .currentView.show_edit_profile_modal();
            } else {
                App.layout.regionSettings.currentView.left.show(settingsMenu);
                App.layout.regionSettings.currentView.workspace.show(settingsClient);
            }
        },

        // The Support page.
        support: function () {
            UI.setTitle('Support');
            // shoulder
            UI.context_button.hide();

            if (!App.Auth.authenticated()) {
                // If user is not autentificated let's return after autentification
                App.vent.once('render_active_chats', function () {
                    App.Router.navigate('/support', {trigger: true});
                });
                App.Router.navigate('/chats', {trigger: true});
            }

            // render
            App.layout.regionSupport.show(new support.Views.SupportView());

        },

        // The Support page.
        support_success: function () {
            UI.setTitle('Support');
            // shoulder
            UI.context_button.hide();

            // render
            App.layout.regionSupport.show(new support.Views.SupportSuccess());

        },

        // Redirects to the login screen if the user is not logged in.
        isAuthenticated: function () {
            if (!App.Auth.authenticated()) {
                App.Router.navigate('/login', {trigger: true});
            }
        }
    };
});

