define('modules/profile',[
        // Global application context.
        "app",

        // Third-party libraries.
        "backbone",

        // Modules
        'main/lib',
        'main/settings',
        'main/cache',

        'constants'

    ],

    function (App, Backbone, Lib, Settings, Cache) {
        var Profile = App.module();

        Profile.MyModel = Backbone.Model.extend({
            idAttribute: "user_id",
            initialize: function() {
                var initial_timestamp = Settings.fetch('initial_timestamp');
                this.set({
                    initial_timestamp: initial_timestamp|| (new Date()).getTime()
                });

                if (!initial_timestamp)
                    Settings.set({initial_timestamp: this.get('initial_timestamp')});
            },
            url: function () {
                return App.create_url('header_object', {'message_id': 'profile'});
            },
            fetch: function (options) {
                var deferred = new $.Deferred(),
                    model = this;

                options = options || {};
                options.dataType = 'text';

                options.success = function (model, response, options) {
                    var model_data = model.parse(response);
                    model.clear();
                    model.set(model_data);
                    // preload the avatar
                    var img = new Image;
                    img.src = model.avatar_src();


                    deferred.resolve(model);
                };
                var that = this;
                // to test slow loading times, wrap this into a setTimeout
                Backbone.Collection.prototype.fetch.call(that, options);
                return deferred.promise();
            },
            parse: function (data) {
                if (data) {
                    data = JSON.parse(data);
                    if (data && data.args && data.args.profile) {
                        return data.args.profile;
                    }
                }
            },
            avatar_src: function () {
                var params = {},
                    url = Profile.Model.prototype.avatar_src.call(this, true);

                if (this.get('canvas_image'))
                    return url;

                if (this.get('avatar_bust')) {
                    this.set({'avatar_busted': (new Date()).getTime()});
                    params['cache_bust'] = this.get('avatar_busted');
                    this.unset('avatar_bust');
                }

                if (this.get('avatar_busted')) {
                    params['timestamp'] = this.get('avatar_busted');
                    Settings.set({initial_timestamp: this.get('avatar_busted')});
                } else {
                    params['timestamp'] = this.get('initial_timestamp');
                }

                return Lib.create_url(url, params);
            }
        });

        Profile.Model = Backbone.Model.extend({
            idAttribute: "user_id",
            initialize: function () {
                var that = this;
                App.vent.on('avatar_bust', function () {
                    that.avatar_busted = true;
                });
            },
            avatar_src: function (forced, force_paint) {
                // default = walkie
                var avatar_src = "/assets/img/person.png", src = '';

                if (this.get('user_id') === App.Auth.get('rebelvox_user_id') && !forced) {
                    return App.MyProfile.avatar_src();
                }
                if (this.get('is_team')) {
                    src = this.get('image_url').replace('http://', 'https://');
                    return src !== "" ? src : avatar_src;
                }
                // voxer first
                if (this.get('image_id') && this.get('image_id') !== "") {
                    var public_www = App.Config.get('public_www');

                    if (public_www === undefined) {
                        avatar_src = App.create_url('get_body', {
                            "message_id": encodeURIComponent(this.get('image_id'))
                        });
                    } else {
                        var user_id = this.get('user_id') || App.Auth.get('rebelvox_user_id');

                        public_www = public_www.replace('http://', 'https://');
                        avatar_src = public_www + '/profile/' + user_id + '.jpg';

                        // For my profile we relay on previous busting mechanism
                        if (user_id !== App.Auth.get('rebelvox_user_id') && this.get('last_modified')) {
                            avatar_src = Lib.create_url(avatar_src, {
                                timestamp: this.get('last_modified')
                            });
                        }
                    }
                }
                // then facebook
                else if (this.get('image_url') && this.get('image_url') !== "") {
                    avatar_src = this.get('image_url').replace('http://', 'https://');
                } else if (Cache.fetch_raw('profile_avatar_' + this.get('user_id'))) {
                    avatar_src = Cache.fetch_raw('profile_avatar_' + this.get('user_id'));

                    if (forced) {
                        this.set({canvas_image: true});
                    }
                } else if (this.avatar_busted || forced || force_paint) {
                    var canvas = document.createElement('canvas'),
                        ctx = canvas.getContext("2d"),
                        text,
                        random_colors = ['#9B4287', "#00BCD4", "#5A51B4", "#47A4D9", "#FF6600",
                            "#FF3366", "#5A51B4", "#42B642", "#2196F3"];

                    try {
                        text = this.get('first')[0] + this.get('last')[0];
                        text = text.toUpperCase();
                    } catch (e) {
                        return avatar_src;
                    }

                    canvas.width = canvas.height = CONSTANTS.profileImage.CANVAS_NORMAL;

                    ctx.font = canvas.width / 2 + "px Roboto";
                    ctx.textBaseline = "middle";
                    ctx.fillStyle = random_colors[parseInt(Math.random() * random_colors.length)];
                    ctx.fillRect(0, 0, canvas.width, canvas.height);
                    ctx.measureText(text).width;
                    ctx.fillStyle = "#fff";
                    ctx.fillText(text,
                        (canvas.width - ctx.measureText(text).width) / 2,
                        canvas.height / 2);
                    avatar_src =  canvas.toDataURL("image/png");

                    Cache.sync_raw('profile_avatar_' + this.get('user_id'), avatar_src);
                    if (forced) {
                        this.set({canvas_image: true});
                    }
                }

                return avatar_src;
            },
            name: function () {
                return this.get('first') + ' ' + this.get('last');
            },
            is_team: function () {
                return this.get('is_team');
            },
            is_deleted: function () {
                var account_flags = this.get('account_flags') || [],
                    tags = this.get('tags') || [];
                return account_flags.indexOf('user_deleted') !== -1 || tags.indexOf('deleted') !== -1;
            }
        });

        Profile.Collection = Backbone.Collection.extend({
            model: Profile.Model,
            url: function () {
                return App.create_url('2/cs/public_profiles');
            },
            // how to keep the collection sorted
            comparator: function (model) {
                if (Settings.get('sort-by') === undefined || Settings.get('sort-by') === 'last-name') {
                    return model.get('is_contact') &&
                           (model.get("last") + model.get("first")).toLowerCase();
                } else {
                    return model.get('is_contact') &&
                           (model.get("first") + model.get("last")).toLowerCase();
                }
            },
            initialize: function (models, options) {
                this.on("add", function (model) {
                    if (model.get('name') === undefined) {
                        model.set({
                            name: model.get('first') + " " + model.get('last')
                        }, {
                            silent: true
                        });
                    }
                    // extend the contact model with the profile information (for sorting)
                    var contact = App.contacts.findWhere({user_id: model.get('user_id')});
                    if (contact) {
                        contact.set(model.attributes);
                    } else {
                        App.contacts.add(model.attributes);
                    }
                }, this);
            },
            add_contact: function (user_ids, options) {
                var url = App.create_url('add_contact', {contact_user_id: user_ids}),
                    that = this;

                App.API.add_contact({
                    user_ids: user_ids
                }, {
                    router: App.Settings.fetch("home_router")
                }, function (value) {
                    console.error(value);
                }, function (value) {
                    options.success(value);
                });
            },
            get_profile: function (user_ids, add) {
                var url = App.create_url('2/cs/public_profiles'),
                    deferred = $.Deferred();
                    that = this,
                    add = (typeof add !== "undefined") ? add : true;
                const uids_payload = Array.isArray(user_ids) ? user_ids : [user_ids]

                if(uids_payload.length > 0) {
                    $.ajax({
                        url: url,
                        type: 'POST',
                        dataType: "text",
                        data: JSON.stringify({uids: uids_payload}),
                        xhrFields: {
                            withCredentials: true
                        },
                        success: function (data, textStatus, jqXHR) {
                            App.profiles.parse(data, true, function (profiles) {
                                deferred.resolve(profiles);
                            }, add);
                        },
                        error: function (jqXHR, textStatus, errorThrow) {
                            deferred.reject(jqXHR);
                        }
                    });
                } else {
                    deferred.resolve()
                }
                return deferred.promise();
            },
            get_profile_by_username: function (username) {
                var url = App.create_url('1/cs/username_public_profile'),
                    deferred = $.Deferred();
                    that = this

                $.ajax({
                    url: url,
                    type: 'GET',
                    data: {username: username, profile_by: 'username'},
                    xhrFields: {
                        withCredentials: true
                    },
                    success: function (data, textStatus, jqXHR) {
                        try { data = JSON.parse(data) } catch(e){}
                        deferred.resolve(data);
                    },
                    error: function (jqXHR, textStatus, errorThrow) {
                        console.error(errorThrow)
                        deferred.reject(jqXHR);
                    }
                });
                return deferred.promise();
            },
            fetch: function (options) {
                var deferred = new $.Deferred(),
                    collection = this,
                    profiles_array = [],
                    post_body;

                if (options.profiles) {
                    profiles_array = _.uniq(options.profiles.concat(this.pluck("user_id")));
                }
                post_body = JSON.stringify({
                    uids: profiles_array
                });

                options = options || {};
                options.dataType = 'text';
                options.type = 'POST';
                options.data = post_body;

                options.success = function () {
                    deferred.resolve(collection, profiles_array);
                };
                //Call Backbone's fetch
                Backbone.Collection.prototype.fetch.call(this, options);
                return deferred.promise();
            },

            // TODO: this is obsolete, please check if it can be removed
            // teams are special contacts
            fetch_teams: function (options) {
                var that = this, teams = [],
                deferred = $.Deferred();

                options = options || {};
                teams = this.filter(function (c) {
                    return c.get('is_team') === true;
                });

                this.remove(teams);

                $.ajax({
                    method: "GET",
                    cache: false,
                    url: App.create_url("2/cs/teams"),
                    dataType: "text",
                    success: function (data, xhr) {
                        if (data) {
                            data = JSON.parse(data);
                            var teams = [];
                            // parse
                            _.each(data.teams, function (team, id, list) {
                                team.user_id = id;
                                team.first = "";
                                team.last = team.name;
                                team.is_team = true;
                                teams.push(team);
                            });
                            // add to collection
                            that.add(teams);
                        }
                        deferred.resolve(that);
                        if (options.success) {
                            return options.success(that);
                        }
                    },
                    error: function (xhr) {
                        deferred.reject(xhr);
                        if (options.error) {
                            options.error(xhr);
                        }
                    }
                });
                return deferred.promise();
            },

            parse: function (profiles_response_body, merge, success, add) {
                var profiles = profiles_response_body.split("\r\n"),
                    profile,
                    add = (typeof add !== "undefined") ? add : true;

                merge = merge || false;
                // slash the empty one
                profiles.pop();

                _.each(profiles, function (profile_text, index, list) {
                    profile = JSON.parse(profile_text);
                    // set flag if profile is a contact
                    profile.is_contact = App.contacts.pluck('user_id').indexOf(profile.user_id) !== -1;
                    if (profile.is_contact) {
                        if (App.contacts.get(profile.user_id)){
                            App.contacts.get(profile.user_id).set({
                                'name': (profile.first + ' ' + profile.last).trim()
                            });
                        }
                    }
                    if (profile !== undefined) {
                        list[index] = profile;
                    }
                }, this);

                if (add) {
                    this.add(profiles, { merge: merge });
                }

                if (success) {
                    success(profiles);
                }
            }
        });

        return Profile;
    });

