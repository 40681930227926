define('main/update_channel',[
    // Global application context.
    "app",

    // Modules
    "main/cache",
    "main/settings"
],

function (App, Cache, Settings) {

    var Updates = App.module();
    Updates.Channel = Backbone.Model.extend({
        defaults: {
            since_id: 0,
            last_started: 0
        },
        url: function (reset_since_id) {
            var since_id =  (reset_since_id) ? 0 : this.get('since_id');
            return since_id > 0 ? App.create_url('updates', {'since_id': since_id}) : App.create_url('updates');
        },
        initialize: function () {
            $(window).on('active:changed', $.proxy(function (event, visible) {
                // make sure an updatechannel is running
                if (visible === true && App.Auth.authenticated()) {
                    this.start();
                }
            }, this));
        },
        promptReconnect: function() {
            if ($('#intro .modal.logout-modal').length === 0) {
                var that = this;
                var modal = new App.UI.Views.TaskModal({
                    callback: function (self) {
                        that.reAuthenticate();

                        // close the modal
                        self.$el.find('[data-dismiss="modal"]').click();
                    },
                    headline: 'An error occurred',
                    info_text: 'Connection lost',
                    ok_text: ' reconnect',
                    cancel_text: null
                });
                App.layout.regionIntro.show(modal);
            }
        },
        reAuthenticate: function() {

            // We try to get a new session, if that is posible, we should re-fetch the timeline
            App.Auth.getAuth().done(function() {
                App.Message.update_timeline(undefined, undefined, Cache.fetch_raw('start_time'));

                // We used to reconnect the socket here but getAuth calls API.auth which already opens the socket
            }).fail(function() {
                console.log('failed to reauthenticate');
            });
        },
        start: function (reset_since_id) {
            var that = this,
                ajax_callback = $.proxy(this.process_messages, this),  // make sure the callback has the right context
                url = that.url();

            reset_since_id = reset_since_id || false;

            if (!this.get('last_started') && !reset_since_id) {
                App.vent.trigger('Updates.Channel:started');
            }

            // let's not start multiple channels
            if (this.xhr && this.xhr.state() === 'pending' && this.is_active()) {
                //console.log('UC returns early, another UC is running');
                return this;
            }
            if (this.xhr && this.xhr.state() === 'pending' && !this.is_active()) {
                this.stop();
                this.start(true);
                return this;
            }

            // update timestamp
            var date = new Date();
            date = date.setMinutes(date.getMinutes() - 5);

            // If the computer was idle for more than 5 minutes we should prompt for a reconnect
            if (that.get('last_started') && date > that.get('last_started')) {
                this.promptReconnect();
            }

            that.set({
                last_started: (new Date()).getTime()
            });

            this.xhr = $.ajax({
                url: url,
                dataType: "text",
                crossDomain: true,
                cache: false,
                xhrFields: {
                    withCredentials: true
                },
                success: function (data, textStatus, jqXHR) {
                    ajax_callback(data);
                    that.start();
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    console.error('Updates.Channel request error :' + textStatus + new Date().format('HH:mm:ss l').toString());
                    console.log('Updates.Channel request error :' + (jqXHR && jqXHR.status))
                    console.log('Updates.Channel request error :' + errorThrown)
                    if (jqXHR.status === 402) {
                        that.reAuthenticate();
                    }
                    else {
                        //
                    }
                }
            });

            return this;
        },
        process_messages: function (data) {
            var incoming_messages, messages = [], that = this,
                ignore_op = ["waiting"];

            if (typeof(data) === 'string') {
                incoming_messages = data.split("\r\n");
                incoming_messages.pop();

                // parse incoming to json
                var i = 0;
                _.each(incoming_messages, function (message, index) {
                    if (message !== "") {
                        var msg = JSON.parse(message);
                        if (msg && msg.args !== undefined || ignore_op.indexOf(msg.op) === -1) {
                            msg.args.update = true;     // indicate message came in through update channel (for rendering)
                            msg.args.live_audio = (msg.op === "put_message_live" || msg.op === "message_with_media")
                                                  ? true : false;
                            msg.args.consumed = false;
                            messages.push(msg);
                            console.log("UC got a message type: " + msg.args.content_type);
                        }
                    }
                });

                if (messages.length > 0) {
                    // sort by sequence nr.
                    messages = _.sortBy(messages, function (msg) {return msg.seqno; });
                    that.set({'since_id': messages[messages.length - 1].seqno});

                    messages = _.map(messages, function (message) {
                        return message.args;
                    });
                    // add message(s) to main message collection
                    if (messages.length > 0) {
                        App.Message.route(messages, {live: true});
                    }
                }
            }
            else if (data.error !== undefined) {
                App.Auth.Login();
            }
        },
        stop: function () {
            if (this.xhr) {
                this.xhr.abort();
            }
            this.set({
                since_id: 0,
                last_started: 0
            });
        },
        active_count: function () {
            return $.active;
        },
        is_active: function () {
            return this.age() < 55000;
        },
        is_stopped: function () {
            return this.xhr.state;
        },
        age: function () {
            return (new Date()).getTime() - this.get('last_started');
        },
        monitor: function () {
            // TODO.....
            this.monitor = setInterval($.proxy(function () {
                this.start();
            }, this), 10000);
            console.log('UC monitor started. Polling every 10 seconds');
        }
    });


    return Updates;
});

