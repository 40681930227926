define('main/lib',[
    'jquery',
    'main/settings'
], function ($, Settings) {
    var Lib = {};

    Lib.create_url = function (uri, query_params, options) {
        query_params = query_params || {};
        options = options  || {};
        options.home_router = options.home_router || null;

        var url;

        if (!options.home_router && (uri.indexOf('https://') || uri.indexOf('https://'))) {
        url = uri + '?';
        } else {
            // do not wrap get_body calls
            if (uri.indexOf('get_body') === -1) {
                //query_params.wrapped = true;
            }

            if (uri.indexOf('://') === -1) {
                url = options.home_router + "/" + uri + '?';
            }
            else {
                url = uri + '?';
            }

        }

        for (var arg in query_params) {
            if (query_params[arg]) {
                url += "&" + arg + "=" + query_params[arg].toString();
            }
        }
        return url;
    };

    Lib.truncate_string = function (string, length) {
        var string = string || "";
        length = length ? length : string.length;
        if (string.length > length) {
            string = string.slice(0, length) + '...';
        }
        return string;
    };

    Lib.hash = function(val){
        var hash = 0, i, char, l;
        if (val.length == 0) return hash;
        for (i = 0, l = val.length; i < l; i++) {
            char  = val.charCodeAt(i);
            hash  = ((hash<<5)-hash)+char;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    };

    // For a better entropy we have to check in additionto userAgent to plugins and timezone
    // https://wiki.mozilla.org/Fingerprinting
    Lib.getBrowserFingerprint = function(username) {
        var plugins = Object.keys(navigator.plugins);
        var timezone = new Date().getTimezoneOffset();
        var tabCount = Settings.fetch('tabCount');

        if (typeof tabCount === 'undefined') {
            tabCount = 0;
        } else {
            tabCount += 1;
        }

        if (tabCount != Settings.fetch('tabCount')) {
            Settings.set({tabCount: tabCount});
        }

        if (plugins.length) {
            plugins = plugins.reduce(function (previous, key) {
                return previous + navigator.plugins[key].name;
            });
        } else {
            plugins = "";
        }

        return this.hash(navigator.userAgent + plugins + timezone) + '_' + username + '_tab-' + tabCount;
    };

    // streamer for timeline data
    // splits incoming data by CRLFs
    // calls callback with parsed split data
    // use of deferred is for convenience
    Lib.json_crlf_streamer = function (url, callback) {
        var STREAM_TIMEOUT = 155,     // how long should we give the stream to produce something of value? in seconds
            deferred = new $.Deferred(),
            xhReq = undefined;

            /* using native js xhr request for accessing the result stream.
            jQuery is not 100% cooperative
        */
        if (detectBrowser().indexOf('msie 9') === -1) {
            xhReq = new XMLHttpRequest();
            xhReq.withCredentials = true;
        } else {
            xhReq = new XDomainRequest();
        }
        xhReq.open("GET", url);
        xhReq.send(null);

        /* set a timer to police the timeline request. kill the request when it takes too long */
        var setRequestTimer = function () {
            if (requestTimer !== undefined) {
                window.clearTimeout(requestTimer);
            }

            return window.setTimeout(function () {
                xhReq.abort();
                // TODO Handle timeout situation, e.g. retry or inform user.
                deferred.reject("request timed out");
            }, STREAM_TIMEOUT * 1000);
        };
        var requestTimer = setRequestTimer();

        /* timer to poll the result stream */
        var pollTimer = setInterval(pollLatestResponse, 200);
        var nextReadPos = 0;

        function pollLatestResponse() {
            var allMessages = xhReq.responseText;
            var messageCRLFEndIndex = 0;

            // check if xhr request is finished
            if (xhReq.readyState === 4) {
                // pass the baton back
                deferred.resolve();

                // close up shop
                window.clearTimeout(pollTimer);
                window.clearTimeout(requestTimer);
                if (xhReq.responseText) {
                    var split = xhReq.responseText.split("\r\n");
                    // this part handles the voxer wrapped response (for IE 9)
                    if (JSON.parse(split[0]).code !== 200) {
                        callback({code: 402});
                    }
                }
            }

            do {
                var unprocessed = "";
                if (allMessages !== undefined) {
                    unprocessed = allMessages.substring(nextReadPos);
                }
                messageCRLFEndIndex = unprocessed.indexOf("\r\n");

                if (messageCRLFEndIndex !== -1) {
                    var endOfFirstMessageIndex = messageCRLFEndIndex + "\r\n".length;
                    var anUpdate = unprocessed.substring(0, endOfFirstMessageIndex);
                    nextReadPos += endOfFirstMessageIndex;

                    try {
                        var object = JSON.parse(anUpdate);
                        callback(null, object);
                    }
                    catch (err) {
                        deferred.reject(err);
                    }
                }
            } while (messageCRLFEndIndex !== -1);
        }

        return deferred.promise();
    };


    // sanitize revox message ids for use as jquery selectors
    Lib.sanitize_message_id = function (message_id) {
        if (message_id === undefined) {
            return undefined;
        }

        if (message_id.indexOf('.rvx.') !== -1) {
            message_id = message_id.replace('.rvx.', '_rvx_');
        }
        if (message_id.indexOf(':rvx:') !== -1) {
            message_id = message_id.replace(':rvx:', '_rvx_');
        }
        if (message_id.indexOf('.gif') !== -1) {
            message_id = message_id.split('.gif')[0];
        }
        if (message_id.indexOf('.jpg') !== -1) {
            message_id = message_id.split('.jpg')[0];
        }
        if (message_id.indexOf('.mp4') !== -1) {
            message_id = message_id.split('.mp4')[0];
        }
        return message_id;
    };

    Lib.format_message_timestamp = function format_message_timestamp(timestamp, now) {
        now = now || new Date();
        timestamp = timestamp || (Date.now() / 1000);

        var ms = timestamp * 1000,
            start_of_day = +new Date(now.getFullYear(), now.getMonth(), now.getDate()),
            start_of_week_ago = now.getTime() - (7 * 24 * 60 * 60 * 1000);

        /*if (ms.toString().indexOf('.') !== -1) {
            ms = ms * 1000;
        }*/

        if (ms >= start_of_day) {
            return new Date(ms).format('h:MM TT');
        }
        else if (ms < start_of_day && ms > start_of_week_ago) {
            return new Date(ms).format('ddd h:MM TT');
        }
        else {
            return new Date(ms).format('mm/dd h:MM TT');
        }
    };

    Lib.curry = function (func,args,space) {
        var n  = func.length - args.length; //arguments still to come
        var sa = Array.prototype.slice.apply(args); // saved accumulator array
        function accumulator(moreArgs,sa,n) {
            var saPrev = sa.slice(0); // to reset
            var nPrev  = n; // to reset
            for(var i=0;i<moreArgs.length;i++,n--) {
                sa[sa.length] = moreArgs[i];
            }
            if ((n-moreArgs.length)<=0) {
                var res = func.apply(space,sa);
                // reset vars, so curried function can be applied to new params.
                sa = saPrev;
                n  = nPrev;
                return res;
            } else {
                return function (){
                    // arguments are params, so closure bussiness is avoided.
                    return accumulator(arguments,sa.slice(0),n);
                }
            }
        }
        return accumulator([],sa,n);
    }
    // TODO: make the global go away
    window.format_message_timestamp = Lib.format_message_timestamp;


    Lib.FilteredCollection = function (original) {
        var filtered = new original.constructor();
        filtered.add(original.models);

        // allow this object to have it's own events
        filtered._callbacks = {};

        // call 'where' on the original function so that
        // filtering will happen from the complete collection
        filtered.where = function (criteria) {
            var items;

            // call 'where' if we have criteria
            // or just get all the models if we don't
            if (criteria) {
                items = original.where(criteria);
            } else {
                items = original.models;
            }

            // store current criteria
            filtered._currentCriteria = criteria;
            filtered._type = 'where';

            // reset the filtered collection with the new items
            filtered.reset(items);

            return items;
        };

        filtered.filter = function (criteria) {
            var items;

            // call 'where' if we have criteria
            // or just get all the models if we don't
            if (criteria) {
                items = original.filter(criteria);
            } else {
                items = original.models;
            }

            // store current criteria
            filtered._currentCriteria = criteria;
            filtered._type = 'filter';

            // reset the filtered collection with the new items
            filtered.reset(items);

            return items;
        };

        original.on("reset", function () {
            if (filtered._type === 'where') {
                filtered.where(filtered._currentCriteria);
            } else {
                filtered.filter(filtered._currentCriteria);
            }
        });
        original.on("sort", function () {
            if (filtered._type === 'where') {
                filtered.where(filtered._currentCriteria);
            } else {
                filtered.filter(filtered._currentCriteria);
            }
        });

        return filtered;
    };

    return Lib;
});


