define('main/track',[

], function () {
    var Tracker = {};

    Tracker.track = function (key, value) {
        var mixpanel = window.mixpanel || null;
        value = value || null;
        if (mixpanel && mixpanel.track) {
            console.log("MIXPANEL tracked ", key + ":", value);
            return mixpanel.track(key, value);
        }
        return false;
    };
    Tracker.register = function (object) {
        var mixpanel = window.mixpanel || null;
        if (mixpanel && mixpanel.register) {
            console.log("MIXPANEL registerd ", object);
            return mixpanel.register(object);
        }
    };

    Tracker.identify = function (distinct_id) {
        var mixpanel = window.mixpanel || null;
        if (mixpanel && mixpanel.identify && distinct_id) {
            console.log("MIXPANEL identify ", distinct_id);
            return mixpanel.identify(distinct_id);
        }
    };
    return Tracker;
});
