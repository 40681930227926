define('main/support',[
    'app',
    // Libraries.
    'backbone'

], function (App, Backbone) {
    var M = Backbone.Marionette;
    var support = new App.module();

    support.Views.SupportView = M.ItemView.extend({
        template: "support/support_body",
        events: {
            "click button": "handle_click",
            "keyup .subject": "handle_key",
            "change select.system": "handle_show_hint",
            "keyup .description": "handle_key"
        },
        onRender: function() {
            App.vent.trigger('metrics:track', '/support');
            this.$el.find(".hint-mobile").addClass('hide');
        },
        handle_show_hint:function(){
            if (this.$el.find('.support-form select').val() != "Web browser") {
                this.$el.find(".hint-mobile").removeClass('hide');
            }else{
                this.$el.find(".hint-mobile").addClass('hide');
            }
        },
        handle_click: function () {
            var post_data = {
                "subject": this.$el.find(".subject").val(),
                "description": this.$el.find(".description").val(),
                "user_id": App.Auth.get('user_id'),
                "email": App.MyProfile.get('email'),
                "first": App.MyProfile.get('first'),
                "last": App.MyProfile.get('last'),
                "client_version": App.MyProfile.attributes.device_info.client_version.join(','),
                "system_name": this.$el.find('.support-form select').val(),
                "os": window.get_os().os,
                "os_version": window.get_os().version,
                "browser": window.detectBrowser()
            };

            if (App.Auth.get('business_id')) {
                post_data["serial"] = '8uS1NEss';
            } else if (App.Auth.isProUser()) {
                post_data["serial"] = 'pR0FF35ti0n4L';
            } else {
                post_data["serial"] = 'PHR33451N833R';
            }

            var error = false;
            if (!post_data.subject) {
                var label = this.$el.find("label[id='subject']");
                label.html("Please add a subject.");
                label.parent().addClass('has-error');
                error = true;
            }
            if (!post_data.description) {
                var label = this.$el.find("label[id='description']");
                label.html("Please add a description.");
                label.parent().addClass('has-error');
                error = true;
            }

            if (error)
                return;

            // Append console log to tickets description
            post_data.description += " \n \n \n \n \n \n" + console_history.join('\n');

            App.layout.regionSupport.show(new support.Views.SupportLoading());
            var url = 'https://' + App.webserver + "/api/1/support";
            $.ajax({
                type: 'POST',
                url: url,
                data: JSON.stringify(post_data),
                contentType: 'application/json',
                dataType: "text",
                success: function (data) {
                    console.log(data);
                    App.vent.trigger('metrics:track', '/support/send', {status: 'success'});
                    App.Router.navigate('/support_success', { trigger: true })
                },
                error: function (e) {
                    console.log(e);
                    App.layout.regionSupport.show(new support.Views.SupportFail());
                    App.vent.trigger('metrics:track', '/support/send', {status: 'error'});
                }
            });
        },
        handle_key: function() {
            this.$el.find(".has-error").removeClass("has-error");
            this.$el.find(".control-label").html("");
        }
    });
    support.Views.SupportLoading = M.ItemView.extend({
        template: "support/support_loading"
    });
    support.Views.SupportSuccess = M.ItemView.extend({
        template: "support/support_success"
    });
    support.Views.SupportFail = M.ItemView.extend({
        template: "support/support_fail"
    });
    return support;
});
