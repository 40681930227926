define('main/auth',[
    'app',
    'backbone',
    'main/settings',
    'main/lib',
    'main/cache',
    'constants'
], function (App, Backbone, Settings, Lib, Cache) {
    var M = Backbone.Marionette,
    SessionModel = Backbone.Model.extend({
        logout_after: App.voxer.SESSION_TO, //5 * 60,  // seconds (55 * 60 = 55 minutes)
        url: function () {
            return Lib.create_url('3/cs/start_session', null, {home_router: Settings.fetch('home_router')});
        },
        create_password_hash: function (password) {
            var shaObj = new jsSHA(password, "TEXT");
            return shaObj.getHash("SHA-512", "HEX");
        },
        save: function (authHash) {
            var that = this, deferred = new $.Deferred(), shaObj, hash, post_data;
            if (!authHash.hash && authHash.password) {
                hash = this.create_password_hash(authHash.password);
            } else {
                hash = authHash.hash;
            }

            if ((authHash.hash !== undefined || authHash.password !== undefined) && authHash.username !== undefined) {
                var router = Settings.fetch('home_router'),
                    users_routes = Settings.fetch('users_routes') || {};

                if (authHash.username in users_routes){
                    router = users_routes[authHash.username];
                }

                App.API.auth({
                    username: authHash.username,
                    hash: hash,
                    uuid: Lib.getBrowserFingerprint(authHash.username),
                    version: voxer.version
                }, {
                    router: router,
                    timeout: CONSTANTS.auth_timeout
                }, function (value, xhr) {
                    if (xhr && xhr.status === 400) {
                        that.unset('login_id');
                        Settings.delete('login-id');
                        Settings.delete('hash');

                        if (window.location.pathname.indexOf('/login') == -1) {
                            // render login page if we are not autentificated
                            App.Router.navigate("/login", {trigger: true});
                            return;
                        }
                    }
                    deferred.reject(value, xhr);
                }, function (data) {
                    that.set({login_id: authHash.username});
                    Settings.set({ 'login-id': authHash.username});
                    Settings.set({'hash': hash});
                    data.user_id = data.rebelvox_user_id;
                    if (data.businesses && data.business_id && data.businesses[data.business_id] && data.businesses[data.business_id].roles) {
                        data.is_admin = data.businesses[data.business_id].roles.is_admin || false;
                    }
                    App.home_router = "https://" + data.home_router.address;
                    // Set the home_router for each user in local-storage,
                    // this will increase the start-up speed on the next login
                    users_routes[authHash.username] = App.home_router;
                    Settings.set({users_routes: users_routes});

                    Settings.set({home_router: App.home_router});
                    that.set(data);
                    Settings.set({user_id: data.user_id});
                    deferred.resolve(data);
                });

            } else if (authHash.code || authHash.sso_token) {
                var parameters = {
                    version: voxer.version
                };

                if (authHash.sso_token && authHash.sso_type === 'ping') {
                    parameters.token = authHash.sso_token;
                    parameters.sso_type = authHash.sso_type;
                    parameters.business_id = authHash.business_id;
                } else if (authHash.sso_token) {
                    parameters.token = authHash.sso_token;
                } else {
                    parameters.code = authHash.code;
                }


                App.API.auth(parameters, {
                    router: Settings.fetch('home_router'),
                    timeout: CONSTANTS.auth_timeout
                }, function (value, object) {
                    that.unset('login_id');
                    that.unset('hash');
                    that.unset('code');
                    that.unset('sso_token');
                    Settings.delete('code');
                    Settings.delete('sso_token');
                    deferred.reject(value, object);
                }, function (data) {
                    App.home_router = "https://" + data.home_router.address;
                    Settings.set({home_router: App.home_router});
                    Settings.set({"sso_token": data.sso_token});

                    that.unset('code');
                    Settings.delete('code');
                    data.user_id = data.rebelvox_user_id;
                    if (data.businesses && data.business_id && data.businesses[data.business_id] && data.businesses[data.business_id].roles) {
                        data.is_admin = data.businesses[data.business_id].roles.is_admin || false;
                    }
                    that.set(data);
                    Settings.set({user_id: data.user_id});

                    deferred.resolve(data);
                });
            } else if (authHash.accessToken !== undefined) {
                var parameters = {
                    version: voxer.version,
                    accessToken: authHash.accessToken
                };


                App.API.auth(parameters, {
                    router: Settings.fetch('home_router'), timeout: CONSTANTS.auth_timeout
                }, function (value, object) {
                    that.unset('login_id');
                    that.unset('accessToken');
                    deferred.reject(value, object);
                }, function (data) {
                    that.unset('login_id');
                    that.unset('accessToken');
                    data.user_id = data.rebelvox_user_id;
                    if (data.businesses && data.business_id && data.businesses[data.business_id] && data.businesses[data.business_id].roles) {
                        data.is_admin = data.businesses[data.business_id].roles.is_admin || false;
                    }
                    App.home_router = "https://" + data.home_router.address;
                    Settings.set({home_router: App.home_router});
                    Settings.set({'accessToken': parameters.accessToken});
                    that.set(data);
                    Settings.set({user_id: data.user_id});

                    deferred.resolve(data);
                });
            } else if (authHash.sso_ping_saml_auth) {
                var parameters = authHash
                parameters.version = voxer.version

                App.API.auth(parameters, {
                    router: Settings.fetch('home_router'),
                    timeout: CONSTANTS.auth_timeout
                }, function (value, object) {
                    that.unset('sso_response');
                    that.unset('sso_ping_saml_auth');
                    that.unset('sso_type');
                    deferred.reject(value, object);
                }, function (data) {
                    that.unset('sso_ping_saml_auth');
                    that.unset('sso_response');
                    that.unset('sso_type');
                    App.home_router = "https://" + data.home_router.address;
                    data.user_id = data.rebelvox_user_id;
                    if (data.businesses && data.business_id && data.businesses[data.business_id] && data.businesses[data.business_id].roles) {
                        data.is_admin = data.businesses[data.business_id].roles.is_admin || false;
                    }
                    Settings.set({home_router: App.home_router});
                    Settings.set({sso_response: parameters.sso_response});
                    Settings.set({sso_ping_saml_auth: parameters.sso_ping_saml_auth});
                    Settings.set({business_id: parameters.business_id});
                    Settings.set({sso_type: parameters.sso_type});
                    that.set(data);
                    Settings.set({user_id: data.user_id});

                    deferred.resolve(data);
                });
            } else {
                return deferred.reject();
            }

            return deferred.promise();
        },
        login: function (creds) {
            // Do a POST to /api/session and send the serialized form creds
            var that = this, promise;

            promise = this.save(creds)
                .done(function (session) {
                    window.history.replaceState(null, "", window.location.origin + "/chats");
                    that.set(session);
                    if (App.VoxerAudio) {
                        App.VoxerAudio.updateSession();
                    }

                });

            return promise;
        },
        logout: function () {
            // Do a DELETE to /api/session and clear the client side data
            var that = this,
                timeline_key = 'timeline_' + App.Auth.get('user_id');
            return this.destroy({
                success: function (model, resp) {
                    model.clear({silent: true});

                    // Set auth to false to trigger a change:logged_in event
                    // The server also returns a new csrf token so that
                    // the user can relogin without refreshing the page
                    that.set({logged_in: false});

                    Settings.delete('hash');
                    Settings.delete('login-id');
                    Settings.delete('home_router');
                    Settings.delete('logged-since');
                    Cache.set({blocked: true})
                    Settings.delete(timeline_key);
                    App.vent.trigger('logged_out');
                }
            });
        },
        getAuth: function () {
            var that = this;

            if (!App.Auth) {
                App.Auth = this;
            }
            // getAuth is wrapped around our router
            // before we start any routers let us see if the user is valid
            var auth = {
                username: that.get('login_id') || Settings.fetch('login-id'),
                hash: that.get('hash') || Settings.fetch('hash'),
                sso_token: Settings.fetch('sso_token'),
                accessToken: Settings.fetch('accessToken'),
                sso_ping_saml_auth: Settings.fetch('sso_ping_saml_auth'),
                sso_response: Settings.fetch('sso_response'),
                sso_type: Settings.fetch('sso_type')

            };

            return this.save(auth).done(function (session_data) {
                if (Settings.fetch('home_router') !== session_data.home_router.address) {
                    Settings.set({home_router: "https://" + session_data.home_router.address});
                    Settings.set({port: session_data.home_router.port});
                }

                if (App.VoxerAudio) {
                    App.VoxerAudio.updateSession();
                }
            }).fail(function (xhr) {
                console.log('failed to get auth')
            });

        },
        authenticated: function () {
            return ((Settings.fetch('login-id') !== undefined && Settings.fetch('hash') !== undefined) 
                    || Settings.fetch('sso_token') !== undefined || Settings.fetch('accessToken') 
                    || (Settings.fetch('sso_response') && Settings.fetch('sso_ping_saml_auth') && Settings.fetch('business_id')));
        },

        session_age: function () {
            return (+new Date() - this.TIMER) / 1000;
        },
        isProUser: function() {
            return this.get('capabilities') && Object.keys(this.get('capabilities').capability_keys).length;
        }
    });

    return new SessionModel();
});

