define('constants',[], function () {
	var constants = {
	    FOF_LABEL: "Friend of Friend",
        cancel_subscription_link: "https://support.voxer.com/hc/en-us/articles/204331453-How-do-I-cancel-and-what-is-the-refund-policy-",
        max_participants: 500,
        playback_x: {
            '1': { value: 1.0, string: "1x" },
            '2': { value: 1.2, string: "1.2x" },
            '3': { value: 1.5, string: "1.5x" },
            '4': { value: 2.0, string: "2x" }
        },
        profileImage: {
            MAX_WIDTH: 400,
            MAX_HEIGHT: 400,
            CANVAS_NORMAL: 150
        },
        chatImage: {
            MAX_WIDTH: 1200,
            MAX_HEIGHT: 1200
        },
        cut_audio_timeout: 900000, // 15 minutes
        fadeOutDuration: 4500,
        upgrade_plans: {
            monthly: '3.99',
            yearly: '29.99'
        },
        skus: {
            "default": "",
            "com.voxer.monthly.299": "2.99",
            "com.voxer.yearly.2999": "29.99",
            "com.voxer.monthly.399": "3.99",
            "com.voxer.recurring.monthly.399": "3.99",
            "com.voxer.monthly.recurring.299": "2.99",
            "com.voxer.yearly.recurring.2999": "29.99",
            "com.voxer.recurring.monthly.299": "2.99",
            "com.voxer.recurring.yearly.2999": "29.99"
        },
        giphy_api_key: "9758e85239dc43f09cf963e5cb021ad1",
        giphy_rating: "pg",
        giphy_route: "api.giphy.com",
        giphy_trending_path: "v1/gifs/trending",
        giphy_search_path: "v1/gifs/search",
        auth_timeout: 30000
    }

    constants.cut_audio_timeout = 900000; // 15 minutes

    constants.auth_timeout = 30000;

	return window.CONSTANTS = constants;
});

