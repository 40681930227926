// Set the require.js configuration for your application.
require.config({
    // Initialize the application with the main application file and the JamJS
    // generated configuration file.
    deps: ['main'],
    // Packeges defined at jam/require.config.js required by shim libraries
    'packages': [
        {
            'name':     'EventSource',
            'location': '../vendor/jam/EventSource',
            'main':     'eventsource.js'
        }
    ],
    paths: {
        // Put paths here.
        'jquery':                   '../assets/bower/jquery/jquery.min',
        'backbone':                 '../assets/bower/backbone/backbone-min',

        // Plug-ins.
        'underscore' :              '../assets/bower/lodash/dist/lodash.underscore',
        'binaryjs' :                '../assets/bower/binaryjs/dist/binary.min',

        'recurly':                  '../vendor/js/libs/recurly',
        'bootstrap':                '../assets/bower/bootstrap/dist/js/bootstrap.min',
        'backbone.marionette':      '../assets/bower/backbone.marionette.js/index',
        'plugins/jquery.cookie':    '../assets/bower/jquery.cookie/jquery.cookie',
        'plugins/jquery.lazyload':  '../assets/bower/jquery.lazyload/jquery.lazyload',
        'plugins/favico':           '../assets/bower/favico.js/favico-0.3.1.min',
        'libs/sha512':              '../assets/js/libs/sha512',
        'plugins/voxer':            '../assets/js/plugins/voxer',
        'plugins/multiregion':      '../assets/js/plugins/marionette-multiregion',
        'plugins/sliding-view':     '../assets/js/marionette.sliding-view/marionette.sliding-view',
        'libs/minEmoji':            '../assets/js/libs/minEmoji',
        'plugins/jquery.xdomain':   '../assets/js/plugins/jquery.xdomain',
        'moment':                   '../assets/bower/momentjs/min/moment.min',
        'plugins/placeholder':      '../assets/bower/jquery.placeholder/jquery.html5-placeholder-shim',
        'plugins/sound':            '../assets/bower/soundmanager2/script/soundmanager2-nodebug-jsmin',
        'plugins/jquery.scrollTo':  '../assets/bower/jquery.scrollTo/jquery.scrollTo',
        // next 3 dependencies for fileuploader
        'jquery.ui.widget':         '../assets/bower/blueimp-file-upload/js/vendor/jquery.ui.widget',
        // backbone plugin
        'routefilter':              '../assets/bower/backbone.routefilter/dist/backbone.routefilter.min'
    },
    'shim': {
        'backbone': {
            'deps': [
                'jquery',
                'underscore'
            ],
            'exports': 'Backbone'
        },
        'backbone.marionette' : {
            'deps' : ['jquery', 'underscore', 'backbone'],
            'exports' : 'Marionette'
        },
        'plugins/multiregion': ['backbone', 'backbone.marionette'],
        'plugins/sliding-view': ['underscore', 'backbone', 'backbone.marionette'],
        'libs/minEmoji' : {
            'exports' : 'minEmoji'
        },
        'bootstrap': {
            "deps": ['jquery']
        },
        'recurly': {
            'deps': ['jquery'],
            'exports': 'Recurly'
        },
        'EventSource': {
            'exports': 'EventSource'
        },
        'routefilter':               ['backbone'],
        'plugins/voxer':             ['jquery', 'moment'],
        'libs/sha512':               ['jquery'],
        'plugins/placeholder':       ['jquery'],
        'plugins/jquery.cookie':     ['jquery'],
        'plugins/jquery.lazyload':   ['jquery'],
        'plugins/jquery.xdomain':    ['jquery'],
        'plugins/jquery.scrollTo':   ['jquery'],
        'plugins/jquery.fileupload': ['jquery', 'plugins/jquery.iframe-transport', 'jquery.ui.widget'],
        'plugins/jquery.iframe-transport': ['jquery']
    },
    waitSeconds: 0
});

define("config", function(){});

