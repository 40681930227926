define('main/UI',[
    'app',
    // Libraries.
    'backbone',
    'main/settings',
    "main/lib",
    'constants'

], function (App, Backbone, Settings, Lib) {
    var M = Backbone.Marionette;
    var UI = new App.module();

    // const VOXER_USERS = [
    //     "my.info.voxer.1452790769392.03192268",
    //     "patri.kokou.voxer.1620688308530.31031858",
    //     "user.irv.r.1323290336341_63195932",
    //     "xavie.bonal.voxer.1606967331884.87906997",
    // ];

    UI.Views.MainMenu = M.ItemView.extend({
        template: "ui/main_menu",
        events: {
            "click a.logout": "handle_logout",
            'click a[href="/manage"]': "handle_redirect_manage",
            "click #mute-all": "handle_sound",
            "click #menu-manage-team" : "handle_manage_team",
            "click #upgrade" : "handle_upgrade",
            "click a": "handle_click"
        },
        initialize: function () {
            var that = this;
            this.user_name = App.MyProfile.get("first") || "My Profile";

            // make this view listen to my profile changes
            App.MyProfile.on('change', function (profile) {
                this.avatar_src = profile.avatar_src();
                this.user_name = profile.get("first");

                if (!this.isDestroyed)
                    this.render();
            }, this);

            App.vent.off('upgrade:success', function () { if (!that.isDestroyed) that.render();});
            App.vent.off('downgrade:success', function () { if (!that.isDestroyed) that.render();});
            App.vent.off('renew:success', function () { if (!that.isDestroyed) that.render();});
            Settings.off('change:sound-on', function () { if (!that.isDestroyed) that.render();});
            App.vent.on('upgrade:success', function () { if (!that.isDestroyed) that.render();});
            App.vent.on('downgrade:success', function () { if (!that.isDestroyed) that.render();});
            App.vent.on('renew:success', function () { if (!that.isDestroyed) that.render();});
            Settings.on('change:sound-on', function () { if (!that.isDestroyed) that.render();});

            // attach the listener after render only to be sure we have access to App.chats
            // App.chats might not be abvailable  when we instanciate the view
            App.chats.on('change:unread_count', function (model, index) {
                this.handle_consume();
            }, this);
            App.Auth.on('change:is_admin', function (auth) {
                if (auth.get('is_admin') === true) {
                    this.$('#manage-link').removeClass('hide');
                }
            }, this);
        },
        onRender: function () {
            if (App.Auth.get('is_admin')) {
                this.$('#manage-link').removeClass('hide');
            }
        },
        handle_logout: function (e) {
            e.preventDefault();
            var Account = require('modules/account');
            App.layout.regionIntro.show(new Account.Views.LogoutView());
        },
        handle_consume: _.debounce(function () {
             this.after_consume(this);
        }, 1000),
        handle_sound: function () {
            Settings.set({'sound-on': !Settings.fetch('sound-on')});
        },
        after_consume: function (context) {
            var that = this;
            unconsumed = _.map(App.chats.models, function (model) {
                return model.get('unread_count');
            });

            this.sum = _.reduce(unconsumed, function (num1, num2) {
                return num1 + num2;
            }, 0);

            if (detectBrowser().indexOf('msie') === -1) {
                App.favicon.badge(this.sum);
            }

            if (_.isNaN(this.sum) || this.sum === 0) {
                that.$el.find('.active-chats').hide();
            }
            else {
                that.$el.find('.active-chats').text(this.sum).show();
            }
        },
        serializeData: function () {
            var capabilities;
            try {
                capabilities = Object.keys(App.Auth.get('capabilities').capability_keys).length;
            } catch (e) {
                capabilities = 0;
            }

            var business_user = App.Auth.get('business_id') != undefined;
            var integration_permission = true;

            // business account cant access zapier for now
            if (business_user) {
                integration_permission = false;
            }

            return {
                sound_on: Settings.fetch('sound-on'),
                avatar_src: this.avatar_src || false,
                user_name: this.user_name,
                manage_link: this.options.manage_link,
                sum: this.sum || false,
                is_pro: capabilities,
                webserver: App.webserver,
                business_user: business_user,
                integration_permission: integration_permission
            };
        },
        handle_click: function (e) {
            this.setActive($(e.currentTarget).closest('li'));
            if ($(e.currentTarget).attr('href') === '/vox') {
                App.vent.trigger('metrics:track', 'v4w/chats');
            } else {
                if ($('#intro_arrow_left').length !== 0) {
                    App.Settings.set({finished_setup: true});
                }
            }
            if ($(e.currentTarget).attr('href') === '/contacts') {
                App.vent.trigger('metrics:track', 'v4w/contacts');
            }

            if ($(e.currentTarget).attr('href') === '/upgrade') {
                App.vent.trigger('metrics:track', '/upgrade_screen', {
                    from: 'top_banner',
                    feature: 'general'
                });
            }
        },
        handle_manage_team: function () {
            App.Router.navigate('pay-for-others', {trigger: true});
        },
        handle_upgrade: function () {
            App.Router.navigate('upgrade', {trigger: true});
        },
        handle_redirect_manage: function (e) {
            e.preventDefault();
            //window.open("https://business.voxer.com");
        },
        setActive: function ($el) {
            if ($el.find('a').attr('href')) {
                this.$el.find('.active').removeClass('active');
                $el.addClass('active');
            }
        }
    });

    UI.Layouts.Shoulder = M.LayoutView.extend({
        template: 'layouts/shoulder',
        className: 'shoulder container no-padding',
        regions: {
            notifications: '.notifications',
            upgrade_region: '.upgrade_region',
            downgrade_region: '.downgrade_region'
        },
        initialize: function () {
            App.chats.on('change:active', function () {
                if (App.chats.where({active: true}).length > 0) {
                    this.$('.close-all-panels').show();
                }
                else {
                    this.$('.close-all-panels').hide();
                }
            }, this);
        },
        serializeData: function () {
            var data = this.options;
            data.sound_on = Settings.fetch('sound-on');
            data.notifications_on = Settings.fetch('notifications-on');

            return data;
        }
    });

    UI.Views.Notification = M.ItemView.extend({
        template: "ui/notification",
        events: {
            "click .desktop span": "handle_desktop_notification",
            "click .reply span": "handle_reply_pop_up",
            "click .alert .close": "handle_close_notification"
        },
        handle_close_notification: function(e) {
            
           $(e.target).parent().hide();
        },
        initialize: function () {
            var that = this;
            this.default_timeout = 1000;

            this.metrics_details = {};

            App.vent.on('metrics:track', function onMetricsTrack(event, data) {
                if (event === '/upgrade_screen') {
                    that.metrics_details = data;
                }
            });

            function onUpgradeSuccess() {
                that.$el.find('.alert.alert-success.got-pro').show()
                    .fadeOut(CONSTANTS.fadeOutDuration);
                App.vent.trigger('metrics:track', '/upgrade_successful', that.metrics_details);
                App.Router.navigate('chats', {trigger: true});
            }

            function onUpgradeError(error) {
                that.$el.find('.alert.alert-success.error-pro span').html(error);
                that.$el.find('.alert.alert-success.error-pro').show()
                    .fadeOut(CONSTANTS.fadeOutDuration);
            }

            App.vent.off('upgrade:success', onUpgradeSuccess);
            App.vent.on('upgrade:success', onUpgradeSuccess);

            App.vent.off('upgrade:error', onUpgradeError);
            App.vent.on('upgrade:error', onUpgradeError);

            function onAlertSuccess(message) {
                var notification = that.$el.find('.alert-success.default-notification');
                notification.find('span').html(message);
                notification.stop().css({opacity: 1});
                notification.show().fadeOut(5000);
            }
            App.vent.off('alert:success', onAlertSuccess);
            App.vent.on('alert:success', onAlertSuccess);

            function onAlertError(message) {
                var notification = that.$el.find('.alert-success.error-pro');
                notification.find('span').html(message);
                notification.stop().css({opacity: 1});
                notification.show().fadeOut(5000);
            }
            App.vent.off('alert:error', onAlertError);
            App.vent.on('alert:error', onAlertError);



            function onDowngradeError(error) {
                that.$el.find('.alert.alert-success.error-pro span').html(error);
                that.$el.find('.alert.alert-success.error-pro').show()
                    .fadeOut(CONSTANTS.fadeOutDuration);
            }
            function onDowngradeSuccess() {
                that.$el.find('.alert.alert-success.not-pro').show()
                    .fadeOut(CONSTANTS.fadeOutDuration);
            }
            App.vent.off('downgrade:error', onDowngradeError);
            App.vent.on('downgrade:error', onDowngradeError);
            App.vent.off('downgrade:success', onDowngradeSuccess);
            App.vent.on('downgrade:success', onDowngradeSuccess);


            function onRenewError(error) {
                that.$el.find('.alert.alert-success.error-pro span').html(error);
                that.$el.find('.alert.alert-success.error-pro').show()
                    .fadeOut(CONSTANTS.fadeOutDuration);
            }
            function onRenewSuccess() {
                that.$el.find('.alert.alert-success.pro-again').show()
                    .fadeOut(CONSTANTS.fadeOutDuration);
            }
            App.vent.off('renew:error', onRenewError);
            App.vent.on('renew:error', onRenewError);
            App.vent.off('renew:success', onRenewSuccess);
            App.vent.on('renew:success', onRenewSuccess);



            App.vent.on('avatar_bust', this.hideSpinner.bind(this));
            // If we miss the event somehow, we should still hide the spinner after 10 seconds
            _.delay(this.hideSpinner.bind(this), 10000);
        },
        serializeData: function () {
            var data = this.options;
            data.classVal = data.classVal || '';

            return data;
        },
        onRender: function() {
            App.shoulderLayout.downgrade_region.show(new UI.Views.DonwgradeModal());
        },
        fadeIn: function (e) {
            e.preventDefault();
            this.$el.show(500);
        },
        hideSpinner: function(duration) {
            this.$el.find('.load-all-spinner').fadeOut(duration || this.default_timeout);
            var business_id = App.Auth.get('business_id') != undefined;
            if (
              !Settings.fetch("desktop-notifications") &&
              !Settings.fetch("desktop-notifications-dismissed")
            ) {
              $(".alert-warning.desktop")
                .fadeIn(this.default_timeout)
                .on("click", function () {
                  // hide the desktop notification when clicked
                  $(this).fadeOut(this.default_timeout, function () {
                  });
                });
              Settings.set({ "desktop-notifications-dismissed": true });
            }
            if (
                Settings.fetch("reply-pop-up") &&
                !Settings.fetch("reply-pop-up-dismissed") && !business_id
              ) {
                $(".alert-warning.reply")
                  .fadeIn(this.default_timeout)
                  .on("click", function () {
                    // hide the reply notification when clicked
                    $(this).fadeOut(this.default_timeout, function () {
                    });
                  });
                Settings.set({ "reply-pop-up-dismissed": true });
              }
        },
        handle_desktop_notification: function(e) {
            Settings.set({'desktop-notifications': true});
            $(e.target).parent().fadeOut(this.default_timeout);
        },
        handle_reply_pop_up: function(e) {
            Settings.set({'reply-pop-up': true});
            $(e.target).parent().fadeOut(this.default_timeout);
        }
    });

    UI.Views.PayTeams = M.LayoutView.extend({
        template: "ui/pay_team",
        events: {
            "click #pay-others li.contact-item a": "handle_add_participant",
            "click #manage-subscriptions": "handle_manage_subscriptions",
            "click a.team-contact-link i.zmdi-close": "handle_remove_user",
            "click #pay-others #buyNow": "handle_buy",
            "change #pay-others input[type='radio']": "handle_select_plan",
            "keyup .search-bar input": "handle_search",
            "click .search-bar i.zmdi-close": "handle_clear_search"
        },
        regions: {
            contacts: '#pay-others .contacts-list'
        },
        initialize: function () {
            var that = this;

            this.added_users = {};
            this.remote_users = {};

            App.API.get_managed_subscriptions(null, {
                home_router: App.Settings.get('home_router')
            }, function (data) {
                that.remote_users = {};
                console.error(data);
            }, function (data) {
                if (!data)
                    return;

                for (var key in data) {
                    data[key].forEach(function(user_id) {
                        var profile = App.profiles.get(user_id);
                        if (profile) {
                            that.remote_users[user_id] = profile.attributes;
                            that.remote_users[user_id]['sku'] = key;
                        } else {
                            App.profiles.get_profile(user_id).then(function(){
                                that.remote_users[user_id] = App.profiles.get(user_id).attributes;
                                that.remote_users[user_id]['sku'] = key;
                                that.render();
                            });
                        }
                    });
                }

                if( !that.isDestroyed) {
                    that.render();
                }
            });

            this.plan = this.plan || "yearly";

            function onSubscriptionSuccess() {
                App.shoulderLayout.notifications.currentView.$el.find('.alert.subscription-updated')
                   .show().fadeOut(CONSTANTS.fadeOutDuration);

                App.vent.trigger('metrics:track', '/subscription_updated', {
                    plan: that.plan,
                    users_count: Object.keys(that.added_users).length +
                        Object.keys(that.remote_users).length
                });

                /*
                try {
                    history.back();
                } catch (e) {
                    console.error(e);
                }
                */
            }
            App.vent.off('subscription:success', onSubscriptionSuccess);
            App.vent.on('subscription:success', onSubscriptionSuccess);
        },
        serializeData: function() {
            var data = {};

            data.added_users = _.extend({}, this.added_users);
            data.monthly = CONSTANTS.upgrade_plans.monthly;
            data.yearly = CONSTANTS.upgrade_plans.yearly;
            data.usersCount = Object.keys(this.added_users).length;
            data.remote_users = Object.keys(this.remote_users).length;
            data.plan = this.plan;

            return data;
        },
        onBeforeRender: function() {
            this.search_value = this.$el.find('.search-bar input').val();
        },
        onRender: function () {
            var Contact = require('modules/contact'),
                Conview = Contact.Views.List.extend({
                    onBeforeRender: undefined,
                    childView: Contact.Views.Item.extend({
                        events: { }
                    }),
                    emptyView: function () {
                        return new Contact.Views.NoItemsView();
                    }
                }),
                that = this,
                added_users = Object.keys(that.added_users),
                remote_users = Object.keys(that.remote_users),
                collection;

            if (this.conview && this.conview.collection) {
                collection = this.conview.collection;
                collection.forEach(function(model) {
                    if (added_users.indexOf(model.attributes.user_id) !== -1) {
                        model.set({currentTeamMember: true});
                    } else {
                        model.set({currentTeamMember: false});
                    }

                    if (remote_users.indexOf(model.attributes.user_id) !== -1) {
                        model.set({hideTeamMember: true});
                    } else {
                        model.set({hideTeamMember: false});
                    }
                });
            } else {
                this.local_data = _.reject(App.profiles.map(function (m) {
                    if ((m.attributes.is_contact || m.attributes.is_team) && m.attributes.user_id !== App.Auth.get('rebelvox_user_id')) {
                        if (m.attributes.is_team === undefined) {
                            m.attributes.is_team = false;
                        }
                        if (m.attributes.is_contact === undefined) {
                            m.attributes.is_contact = false;
                        }
                        m.attributes.avatar_url = m.avatar_src();
                        m.attributes.username = m.attributes.username || "";

                        if (added_users.indexOf(m.attributes.user_id) !== -1) {
                            m.set({currentTeamMember: true});
                        } else {
                            m.set({currentTeamMember: false});
                        }

                        if (remote_users.indexOf(m.attributes.user_id) !== -1) {
                            m.set({hideTeamMember: true});
                        } else {
                            m.set({hideTeamMember: false});
                        }

                        return m.attributes;
                    }
                }), function (m) {
                    return m === undefined || (m.account_flags && m.account_flags.indexOf('user_deleted') !== -1) || (m.tags && m.tags.indexOf('deleted') != -1) || (m.accounts_flags && m.accounts_flags.indexOf('user_deleted') !== -1);
                });
                collection = new Backbone.Collection(this.local_data);
            }

            if (this.search_value) {
                this.$el.find('.search-bar input').val(this.search_value);
                that.$el.find('#pay-others .search-bar i.zmdi-close').removeClass('hide');
            }

            this.conview = new Conview({
                collection: collection
            });
            this.contacts.show(this.conview);

            if (this.scroll_target) {
                var scroll_target = this.$el.find("#pay-others .contacts-list a[user-id='" + this.scroll_target + "']"),
                    offset_height = scroll_target.offsetParent().height();
                scroll_target = scroll_target[0];

                if (scroll_target.offsetTop + scroll_target.offsetHeight > offset_height)
                    scroll_target.scrollIntoView();
            }

            this.$el.find('input[name="pay"][value="' + this.plan + '"]').attr('checked', true);

            this.$el.find('.search-bar input').focus();
        },
        payingOptionsTemplate: '<div class="btn-group col-xs-12 no-padding" data-toggle="buttons"><label class="btn btn-default col-xs-6 <%- (plan === "yearly") ? \'active\' : \'\' %>"><input type="radio" name="yearly" value="yearly" <%- (plan === "yearly") ? \'checked\' : \'\' %>><div class="pull-left "><i class="zmdi zmdi-circle-o"></i><i class="zmdi zmdi-dot-circle"></i></div><div class="pull-left text-left"><strong>$<%- (usersCount * yearly).toFixed(2)  %> / yearly</strong><br><span>$ <%- yearly %> per user/ year</span><span class="savings">Save 37%</span></div></label><label class="btn btn-default col-xs-6 <%- (plan === "monthly") ? \'active\' : \'\' %>"><input type="radio" name="monthly" value="monthly" <%- (plan === "monthly") ? \'checked\' : \'\' %> ><div class="pull-left"><i class="zmdi zmdi-circle-o"></i><i class="zmdi zmdi-dot-circle"></i></div><div class="pull-left text-left"><strong>$<%- (usersCount * monthly).toFixed(2) %> / monthly</strong><br><span>$ <%- monthly %> per user/ month</span><span class="savings">&nbsp;</span></div></label></div><div class="clearfix"></div><button id="buyNow" <%- (usersCount) ? \'\' : \'disabled="disabled"\' %> class="btn col-xs-12 text-center">Buy now</button>',
        addUserTemplate: '<li> <a class="team-contact-link" user-id="<%- user_id %>"> <div class="pull-left username  is-pro "> <%- name %> </div> <i class="pull-right zmdi  zmdi-close"></i> <div class="clearfix"></div> </a> </li>',
        handle_add_participant: function (e) {
            var that = this,
                user_id = $(e.currentTarget).closest('a.name').attr('user-id'),
                name = $(e.currentTarget).parent().find('.name span.name').html();

            // If user is already pro, don't allow him to be added
            if ($(e.currentTarget).find('.label.pro-user').length)
                return;

            if (Object.keys(that.added_users).indexOf(user_id) !== -1) {
                delete that.added_users[user_id];

                this.$el.find('.team-contact-link[user-id="' + user_id + '"]').parent().remove();
                $(e.currentTarget).find('i.zmdi-check').remove();
            } else {
                that.added_users[user_id] = {
                    user_id: user_id,
                    name: name
                };
                this.$el.find('.list-members').append(_.template(this.addUserTemplate, {
                    user_id: user_id,
                    name: name
                }));
                $(e.currentTarget).append('<i class="zmdi zmdi-check"></i>');
            }

            this.$el.find('.paying-options').html(
                _.template(this.payingOptionsTemplate, this.serializeData())
            );
            this.$el.find('.paying-members h2').html(
                _.template('Selected Members (<%- usersCount %>)', {
                    usersCount: Object.keys(this.added_users).length
                })
            );
        },
        handle_remove_user: function (e) {
            e.preventDefault();

            var that = this,
                node = $(e.currentTarget).closest('li'),
                user_id = node.attr('user-id') || node.children().attr('user-id'),
                model = this.conview.collection.findWhere({user_id: user_id}) || {};

            if (Object.keys(that.added_users).indexOf(user_id) !== -1) {
                delete that.added_users[user_id];
            }

            node.remove();
            $('#' + model.cid + ' .zmdi-check').remove();
            this.$el.find('.paying-options').html(
                _.template(this.payingOptionsTemplate, this.serializeData())
            );
            this.$el.find('.paying-members h2').html(
                _.template('Selected Members (<%- usersCount %>)', {
                    usersCount: Object.keys(this.added_users).length
                })
            );
        },
        handle_clear_search: function () {
            this.$el.find('.search-bar input').val("");
            this.conview = false;
            this.render();
        },
        handle_search: _.debounce(function (e) {
            var that = this,
                added_users = _.union(Object.keys(that.added_users), Object.keys(that.remote_users)),
                query = e.target.value;

            var local = $.map(this.local_data, function(user) {
                if (user.user_id.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                    user.username.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                    user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)

                    return user;
            });

            // Process the local values as soon as posible for a better UX
            process_sugestions(local);

            if (query.length) {
                that.$el.find('#pay-others .search-bar i.zmdi-close').removeClass('hide');
            } else {
                that.$el.find('#pay-others .search-bar i.zmdi-close').addClass('hide');
            }

            if (query.length > 2) {
                App.API.search({q: query}, {
                    router: App.Settings.get('home_router'),
                    session_key: App.Settings.get('session_key')
                }, function () {},
                function (value) {
                    var suggestions = [];

                    local.concat(value.rows).forEach(function(user) {
                        if (typeof user.is_team == "undefined") user.is_team = 0;
                        if (typeof user.avatar_url == "undefined") user.avatar_url = "/assets/img/person.png";
                        if (typeof user.name == "undefined") user.name = user.first + " " + user.last;

                        suggestions.push(user);
                    });

                    process_sugestions(_.uniq(suggestions, function(contact) {return contact.user_id}));
                });
            }

            function process_sugestions (data) {
                that.conview.collection.reset([], {silent: true});
                data.forEach(function(user) {
                    if (added_users.indexOf(user.user_id) !== -1) {
                        user.currentTeamMember = true;
                        user.vpro_account_badge = true;
                    } else {
                        user.currentTeamMember = false;
                    }

                    that.conview.collection.push(user);
                });
                that.conview.render();
            }
        }, 500),
        handle_buy: function (e) {
            var that = this,
                count = Object.keys(that.added_users).length,
                pay_load_data = {
                    token: undefined,
                    add: {},
                    remove: []
                },
                sku = "com.voxer.recurring.monthly.399",
                amount,
                description,
                total;


            if (that.plan === "yearly") {
                sku = "com.voxer.recurring.yearly.2999";
            }

            pay_load_data.add[sku] = Object.keys(that.added_users);

            amount = (that.plan === "yearly") ?
                CONSTANTS.upgrade_plans.yearly : CONSTANTS.upgrade_plans.monthly;
            amount = parseFloat(amount).toFixed(2);
            total = (count * amount).toFixed(2);
            description = that.plan.capitalize() + ' $' + total + ', for ' + count + ' people.';

            if (App.API.has_stripe_id()) {
                that.process_payment(pay_load_data);
            } else {
                App.stripeModal.open({
                    name: 'Voxer PRO',
                    email: App.MyProfile.get("email"),
                    description: description,
                    amount: total * 100,
                    token: function(token) {
                        pay_load_data['token'] = token.id;
                        that.process_payment.call(that, pay_load_data);
                    }
                });
            }
        },
        process_payment: function (pay_load_data) {
            var that = this;
            App.API.managed_subscriptions(pay_load_data, {
                home_router: App.Settings.get('home_router')
            }, function (data) {
                console.error(data);
                that.initialize();
                that.render();
                App.vent.trigger('upgrade:error', data.error);
            }, function (data) {
                App.vent.trigger('subscription:success', "subscriptions updated");
                App.pbr_summary.set(data);
                that.initialize();
                that.render();
            })
        },
        handle_select_plan: function (e) {
            var that = this,
                need_render = false;
            that.plan = e.target.getAttribute('value');

            Object.keys(that.added_users).forEach(function (user_id) {
                if (that.added_users[user_id]['sku'] &&
                    that.added_users[user_id]['sku'].indexOf(that.plan) !== -1) {
                    delete that.added_users[user_id];
                    need_render = true;
                }
            });

            if (need_render)
                that.render();
        },
        handle_manage_subscriptions: function () {
            App.Router.navigate("/manage-members", {trigger:true});
        }
    });

    UI.Views.ManageTeamSubscriptions = M.LayoutView.extend({
        template: "ui/manage_team_subscription",
        events: {
            "click #add-subscriptions": "handle_add_subscriptions",
            "keyup .monthly .search-bar input": "handle_monthly_search",
            "keyup .yearly .search-bar input": "handle_yearly_search"
        },
        regions: {
            monthlyContacts: '#pay-others .contacts-list.monthly',
            yearlyContacts: '#pay-others .contacts-list.yearly'
        },
        initialize: function () {
            var that = this;
            that.monthlySponsees = [];
            that.yearlySponsees = [];

            App.API._get_message(null, {
                home_router: App.Settings.get('home_router'),
                url: '2/cs/user/subscriptions'
            }, function (data) {
                that.monthlySponsees = [];
                that.yearlySponsees = [];
                console.error(data);
            }, function (data) {
                function addUser(user, sku) {
                    if (sku.indexOf('monthly') !== -1) {
                        that.monthlySponsees.push(user);
                    } else if (sku.indexOf('yearly') !== -1) {
                        that.yearlySponsees.push(user);
                    }
                }
                for (var key in data) {
                    data[key].sponsees.forEach(function(user_id) {
                        var profile = App.profiles.get(user_id),
                            sku = key;
                        if (profile) {
                            addUser(_.extend(profile.attributes, {
                                sku: sku
                            }, data[sku].subscription_data), sku);
                        } else {
                            App.profiles.get_profile(user_id).then(function(){
                                addUser(_.extend(App.profiles.get(user_id).attributes, {
                                    sku: sku
                                }, data[sku].subscription_data), sku);
                                that.render();
                            });
                        }
                    });
                };
                that.render();
            });
        },
        onRender: function () {
            var that = this,
                Contact = require('modules/contact'),
                Conview = Contact.Views.List.extend({
                    onBeforeRender: undefined,
                    onReorder: undefined,
                    onRender: undefined,
                    childView: Contact.Views.Item.extend({
                        template: 'contact/list_item_subscription',
                        events: {
                            "click i.zmdi-close-circle": "handle_remove"
                        },
                        serializeData: function () {
                            var data = Contact.Views.Item.prototype.serializeData.call(this),
                                model = this.options.model;

                            if (model.get('current_period_start')) {
                                data.current_period_start = new Date(model.get('current_period_start') * 1000).format('mmm dd yyyy');
                            }

                            if (model.get('current_period_end')) {
                                data.current_period_end = new Date(model.get('current_period_end') * 1000).format('mmm dd yyyy');
                            }

                            return data;
                        },
                        handle_remove: function (e) {
                            that.handle_remove.call(that, e, this.model);
                        }
                    }),
                    emptyView: function () {
                        return new Contact.Views.NoItemsView();
                    }
                });

            if (this.monthlySponsees.length || this.yearlySponsees.length) {
                mCollection = new Backbone.Collection(this.monthlySponsees);
                yCollection = new Backbone.Collection(this.yearlySponsees);

                this.mConview = new Conview({
                    collection: mCollection
                });
                this.monthlyContacts.show(this.mConview);

                this.yConview = new Conview({
                    collection: yCollection
                });
                this.yearlyContacts.show(this.yConview);
            }
        },
        serializeData: function () {
            var data = {};

            data.monthlyCount = this.monthlySponsees.length;
            data.yearlyCount = this.yearlySponsees.length;

            return data;
        },
        handle_add_subscriptions: function () {
            App.Router.navigate("/pay-for-others", {trigger:true});
        },
        handle_remove: function (event, model) {
            var that = this,
                sku = model.get('sku'),
                remove = {},
                el = $(event.target).closest('a.name');

            el.addClass('removing');
            remove[sku] = [model.get('user_id')];

            App.API.managed_subscriptions({
                token: undefined,
                add: {},
                remove: remove
            }, {
                home_router: App.Settings.get('home_router')
            }, function (data) {
                el.removeClass('removing');
                App.vent.trigger('upgrade:error', data.error || "Failed to remove the user.");
            }, function (data) {
                App.pbr_summary.set(data);
                if (sku.indexOf('monthly') !== -1) {
                    that.mConview.collection.remove(model);
                    that.$el.find('.count.monthly').html(that.mConview.collection.length);
                } else if (sku.indexOf('yearly') !== -1) {
                    that.yConview.collection.remove(model);
                    that.$el.find('.count.yearly').html(that.yConview.collection.length);
                }
            })
        },
        handle_monthly_search: _.debounce(function (ev) {
            var value = ev.target.value.toLowerCase(),
                that = this,
                filtered = this.monthlySponsees.filter(function (m) {
                    return m.name.toLowerCase().indexOf(value) !== -1 || m.username.toLowerCase().indexOf(value) !== -1;
                });
            this.mConview.collection.reset([], {silent: true});
            filtered.forEach(function (m) {
                that.mConview.collection.push(m);
            });
            this.mConview.render();
        }, 500),
        handle_yearly_search: _.debounce(function (ev) {
            var value = ev.target.value.toLowerCase(),
                that = this,
                filtered = this.yearlySponsees.filter(function (m) {
                    return m.name.toLowerCase().indexOf(value) !== -1 || m.username.toLowerCase().indexOf(value) !== -1;
                });
            this.yConview.collection.reset([], {silent: true});
            filtered.forEach(function (m) {
                that.yConview.collection.push(m);
            });
            this.yConview.render();
        }, 500)
    });

    UI.Views.UpgradeView = M.ItemView.extend({
        template: "ui/upgrade_view",
        events: {
            'click #update_card': "handle_update_card",
            "click .btn-monthly": "handle_upgrade",
            "click #cancel-pro": "handle_cancel_modal",
            "click #renew-pro": "handle_renew_modal",
            "click .btn-yearly": "handle_upgrade",
            "click .pay-others":"handle_pay_others_link"
        },
        initialize: function () {
            var that = this;
            this.metrics_details = {};

            App.pbr_summary.on("change", function() { if (!that.isDestroyed) that.render(); });
            App.Auth.on("change:capabilities", function() { if (!that.isDestroyed) that.render(); });
            App.vent.on('metrics:track', function onMetricsTrack(event, data) {
                if (event === '/upgrade_screen') {
                    that.metrics_details = data;
                }
            });
            App.vent.on("downgrade:success", function() { if (!that.isDestroyed) that.render(); });
            App.vent.on("renew:success", function() { if (!that.isDestroyed) that.render(); });
        },
        onRender:function(){
            this.$el.find('[data-toggle="popover"]').popover();
        },
        serializeData: function() {
            var data = this.options;
            var monthly = CONSTANTS.upgrade_plans.monthly;
            var yearly = CONSTANTS.upgrade_plans.yearly;
            var capabilities = App.Auth.get("capabilities");
            var customer = App.pbr_summary.get("customer");
            var sponsees = App.pbr_summary.get("sponsees");

            data.capabilities = capabilities;
            data.customer = customer;
            data.sponsees = data.sponsees;

            data.avatar_src = App.MyProfile.avatar_src();
            data.monthly = monthly;
            data.monthly_dollars = Math.floor(monthly);
            data.monthly_cents = ("" + Math.round(monthly * 100)).substr(-2);
            data.yearly = yearly;
            data.yearly_dollars = Math.floor(yearly);
            data.yearly_cents = ("" + Math.round(yearly * 100)).substr(-2);
            data.savings = "" + Math.round(100 * (12 * monthly - yearly) / (12 * monthly));

            data.renews = data.renews || false;
            data.card = false;
            data.stripe_subscriptions = 0;
            data.existing_sub = false;
            if (customer) {
                data.renews = customer.subscriptions.data.some(function (subscription) {
                    return !(subscription.cancel_at_period_end) && subscription.quantity;
                });
                data.card = customer.sources.data[0];
                data.stripe_subscriptions = customer.subscriptions.data.reduce(function (total, subscription) {
                    return total + subscription.quantity;
                }, 0);

                data.existing_sub = true;
            }

            data.monthly_count = 0;
            data.yearly_count = 0;
            if (sponsees) {
                Object.keys(sponsees).forEach(function (plan) {
                    if (/monthly/.test(plan)) {
                        data.monthly_count += sponsees[plan].length;
                    }
                    if (/yearly/.test(plan)) {
                        data.yearly_count += sponsees[plan].length;
                    }
                });
            }



            data.is_pro = 0;
            data.plan_period = null;
            data.plan_cost = null;
            data.expire_date = (new Date()).format("fullDate");
            data.expiry_date = new Date(0);
            data.payment_processor = null;
            if(capabilities && capabilities.expiry_date) {
                data.expire_date = new Date(capabilities.expiry_date * 1000 || Date.now()).format("fullDate");;
                data.payment_processor = capabilities.payment_processor;
                data.expiry_date = new Date(capabilities.expiry_date * 1000);
                var sku = capabilities.sku;
                if (sku) {
                    data.sku = sku;
                    sku = sku.split('.');
                    data.plan_cost = parseInt(sku.pop()) / 100;
                    var plan_period = sku.pop();
                    data.plan_period = plan_period.charAt(0).toUpperCase() + plan_period.slice(1);
                };

                sku = "default";

                if(capabilities.skus && capabilities.skus.length == 1){
                    sku =  capabilities.skus[0];
                }
                else if(capabilities.skus && capabilities.skus.length ==2){
                    sku =  capabilities.skus[1];
                }


                data.charged = CONSTANTS.skus[sku];

                try {
                    data.is_pro = Object.keys(App.Auth.get('capabilities').capability_keys).length;
                } catch (e) {
                    data.is_pro = 0;
                }
            }

            if(data.is_pro){
                if(data.monthly == data.charged){
                    data.subscription = 'Monthly';
                }
                else{
                    data.subscription = 'Yearly';
                }
            }else{
                data.subscription = '';
            }

            return data;
        },
        handle_update_card: function (e) {
            e.preventDefault();
            App.stripeModal.open({
                name: 'Voxer PRO',
                description: "Update Card Details",
                email: App.MyProfile.get("email"),
                panelLabel: "Update",

                token: function(token) {
                    App.API._post_message({
                        "purchase_type": "web",
                        "token": token
                    }, {
                        url: '1/cs/user/update_card',
                        home_router: Settings.fetch('home_router')
                    }, function(error) {
                        App.vent.trigger('upgrade:error', error.error);
                    }, function (customer) {
                        App.pbr_summary.set({"customer": customer});
                    });
                }
            });
        },
        handle_upgrade: function (e) {
            e.preventDefault();
            var target = $(e.target),
                amount;
            if (target[0] && target[0].tagName === "SPAN") {
                target = target.closest('button');
            }
            if (target[0] && target[0].tagName === "STRONG") {
                target = target.closest('a');
            }

            if (target.attr('class').indexOf('monthly') !== -1) {
                App.stripePlan = 'Monthly';
            } else if(target.attr('class').indexOf('yearly') !== -1) {
                App.stripePlan = 'Yearly';
            } else {
                return;
            }

            if (App.stripePlan === 'Monthly') {
                val = parseFloat(CONSTANTS.upgrade_plans.monthly);
            } else if (App.stripePlan === 'Yearly') {
                val = parseFloat(CONSTANTS.upgrade_plans.yearly);
            } else {
                return;
            }

            this.metrics_details.plan = App.stripePlan.toLocaleLowerCase();
            App.vent.trigger('metrics:track', '/upgrade_clicked', this.metrics_details);

            function modal_validate(token) {
                var sku = "com.voxer.recurring.yearly.2999", purchase_id;

                if (App.stripePlan === "Monthly") {
                    sku = "com.voxer.recurring.monthly.399";
                }

                if (token) {
                    purchase_id = sku + "_" + token.id;
                } else {
                    purchase_id = sku + "_" + Date.now();
                }
                var msg = {
                    "purchase_type": "web",
                    "sku": sku,
                    "purchase_id": purchase_id,
                };
                if (token) {
                    msg.token = token.id;
                }
                App.API._post_message(msg, {
                    url: '1/cs/user/validate_purchase',
                    home_router: Settings.fetch('home_router')
                }, function(error) {
                    App.vent.trigger('upgrade:error', error.error);
                }, function (response) {
                    App.Auth.set({'capabilities': response.capabilities});
                    App.vent.trigger('upgrade:success', response);
                    if(response.summary) {
                        App.pbr_summary.set(response.summary);
                    }
                });
            }

            var modal_config = {
                name: 'Voxer PRO',
                email: App.MyProfile.get("email"),
                token: modal_validate
            };
            if (App.stripePlan === "Monthly") {
                modal_config.amount = null;
                modal_config.panelLabel = "Start 7 day Free Trial";
                modal_config.description = '$' + val + '/month begins after 7 days';
            } else {
                modal_config.amount = val * 100;
                modal_config.panelLabel = null;
                modal_config.description = '$' + val + '/year begins after 7 days';
            }
            if (App.API.has_stripe_id()) {
                //modal_validate();
                $('#downgrade').modal();
                $('.pro-benefits').addClass('hide');
                $('.mary-modal').addClass('hide');
                if (App.stripePlan === "Monthly") {
                    $('#mary-confirm-monthly-modal').removeClass('hide');
                } else {
                    $('#mary-confirm-yearly-modal').removeClass('hide');
                }
            } else {
                App.stripeModal.open(modal_config);
            }
        },
        handle_cancel_modal: function (e) {
            var payment = App.Auth.get('capabilities').payment_processor;
            if (payment && payment === "web") {
                $('#downgrade').modal();
                $('.mary-modal').addClass('hide');
                $('#mary-modal').removeClass('hide');
                $('.pro-benefits').addClass('hide');
                //$('.cancel-pro-modal').removeClass('hide');
                App.vent.trigger('metrics:track', '/manage_subscription');
            } else {
                window.open(CONSTANTS.cancel_subscription_link, '_blank');
            }
        },
        handle_renew_modal: function (e) {
            var payment = App.Auth.get('capabilities').payment_processor;
            if (payment && payment === "web") {
                $('#downgrade').modal();
                $('.mary-modal').addClass('hide');
                $('#mary-confirm-renew').removeClass('hide');
                $('.pro-benefits').addClass('hide');
                App.vent.trigger('metrics:track', '/manage_subscription');
            } else {
                window.open(CONSTANTS.cancel_subscription_link, '_blank');
            }
        },
        handle_pay_others_link: function(e) {
            this.$el.remove();
            App.Router.navigate('pay-for-others', {trigger: true});
            App.vent.trigger("metrics:track", "/upgrade_teams")
        }
    });

    UI.Views.InvitesView = M.ItemView.extend({
        template: "ui/invites",
        events: {
            "click .invite-friends" :"handle_invite_friends",
            "click .add-more-invites": "handle_add_more_invites",
            "focus input[type=email]": "handle_clear_errors"
        },
        handle_continue_desktop: function(message, status, path, last_added) {
            if(path == '/sign_up/step5'){
                App.render_layout().then(function() {
                    App.Router.navigate('/chats/' + generate_thread_id([App.Auth.get('user_id'), 'voxer.bot@voxer.com']), {trigger: true});
                    App.layout.regionIntro.show(new App.UI.Views.WelcomeVoxer({}));
                    App.vent.trigger('metrics:track', 'v4w/chats', {first_time: true});
                });
            }
            else{
                var notification = $('.alert-'+status+'.default-notification');
                notification.find('span').html(message);
                notification.show().fadeOut(5000);
                App.Router.navigate('/chats/' + last_added, {trigger: true});
            }
        },
        handle_clear_errors:function(e){
            e.target.parentElement.className = 'fg-line';
        },
        handle_invite_friends:function(e){
            e.preventDefault();
            var path = window.location.pathname;
            var last_added; //get to the last added invite chat

            var that = this,
            people = this.$el.find('#invite-voxer input[name="invite-email"]').map(function (index, el) {
                var name;
                if (that._validate_email(el.value)){ //add only valid emails
                    that.$el.find('.invite-friends').addClass('disabled');
                    name = el.value;
                    return {
                        email: el.value,
                        name: name
                    }
                }
                else{
                    if(this.value !== ''){
                        this.parentElement.className = "fg-line error"; //show error
                    }
                    return false;
                }
            }).filter(function (index, value) {
                return value;
            }),
            deferreds = [];


            if (people.length) {
                people.each(function (index, peer) {
                    if(that._validate_email(peer.email)){
                        var deferred = $.Deferred(),
                            first = peer.name.split('@')[0],
                            last = peer.name.split('@')[1];

                        deferreds.push(deferred);
                        App.API.create_chat({
                            subject: peer.name,
                            user_ids: [App.Auth.get('user_id')],
                            invites: [{
                                emails: [peer.email],
                                first: first,
                                last: last,
                                phones: [],
                                selected_email: peer.email
                            }]
                        }, {
                            home_router: Settings.fetch('home_router')
                        }, function (error) {
                            console.error(error);
                            deferred.reject(error);
                        }, function (response) {
                            if(path == '/invites'){
                                App.chats.add(response);
                                last_added =  response.thread_id;
                            }
                            deferred.resolve(response);
                        });
                    }
                });
                $.when.apply($, deferreds).then(function () {
                    App.vent.trigger('metrics:track', 'v4w'+ path, {invited: people.length});
                    that.handle_continue_desktop("Your invitation has been sent", "success", path, last_added);
                });
            }
        },
        _validate_email: function (string) {
            var regexp =  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return string !== "" && regexp.test(string);
        },
        handle_add_more_invites: function(e) {
            e.preventDefault();
            var input = this.$el.find('#invite-voxer form .invite-user-details');
            input = $(input[input.length - 1]);
            input.after('<div class="invite-user-details">' +
                        '<div class="fg-line">' +
                          '<input type="email" name="invite-email"  class="form-control" title="email address" placeholder="Email address">' +
                        '</div>' +
                      '</div>');
        },
    });

    UI.Views.DonwgradeModal = M.ItemView.extend({
        template: "ui/downgrade_modal",
        events: {
            "click #canceling li a": "handle_reason",
            //"click #cancel-pro": "handle_cancel_modal",
            "click #mary-modal .btn-keep-subscription": "handle_keep_subscription",
            "click #mary-modal .btn-cancel-subscription": "handle_cancel_subscription",
            "click #mary-confirm-renew .btn-keep-subscription": "handle_keep_subscription",
            "click #mary-confirm-renew .btn-renew-subscription": "handle_renew_subscription",
            "click #canceling .btn-keep-subscription": "handle_keep_subscription",
            "click #canceling .btn-cancel-subscription": "handle_cancel_subscription",
            "click .payment p.pay-others": "handle_pay_others_link",
            "click .btn-cancel-upgrade": "handle_cancel_upgrade",
            "click .btn-confirm-upgrade-monthly": "handle_confirm_upgrade_monthly",
            "click .btn-confirm-upgrade-yearly": "handle_confirm_upgrade_yearly",
        },
        serializeData: function () {
            var data = this.options,
                capabilities = App.Auth.get("capabilities") || {},
                sku;

            data.monthly = CONSTANTS.upgrade_plans.monthly;
            data.yearly = CONSTANTS.upgrade_plans.yearly;
            data.expire_date = capabilities.expiry_date * 1000 || Date.now();
            data.expire_date = new Date(data.expire_date).format("fullDate");
            sku = (capabilities.skus && capabilities.skus.length) ? capabilities.skus[0] : "default";
            data.charged = CONSTANTS.skus[sku];

            return data;
        },
        onShow:function(e){
            var that = this;

            that.$el.find('.dropdown-toggle').dropdown();

            that.$el.find('#downgrade').on('hidden.bs.modal', function () {
                that.$el.find('.cancel-pro-modal').addClass('hide');
                that.$el.find('.pro-benefits').removeClass('hide');

                // Reset the reason
                that.$el.find('#canceling .btn-cancel-subscription').attr('disabled', true);
                that.$el.find('#canceling .btn.dropdown-toggle').html('SELECT YOUR REASON');
            });
        },
        /*
        handle_cancel_modal: function(e) {
            var payment = App.Auth.get('capabilities').payment_processor;

            if (payment && payment === "web") {
                $('.pro-benefits').addClass('hide');
                $('.cancel-pro-modal').removeClass('hide');
                App.vent.trigger('metrics:track', '/manage_subscription');
            } else {
                $(e.target).closest('.modal').modal('toggle');
                window.open(CONSTANTS.cancel_subscription_link, '_blank');
            }
        },
        */
        handle_reason: function (e) {
            this.reason = $(e.target).html();
            this.$el.find('#canceling .btn.dropdown-toggle').html(this.reason);
            this.$el.find('#canceling .btn-cancel-subscription').removeAttr('disabled');
        },
        confirm_upgrade: function(plan) {
            var sku = "com.voxer.recurring.yearly.2999", purchase_id;

            if (plan === "Monthly") {
                sku = "com.voxer.recurring.monthly.399";
            }

            purchase_id = sku + "_" + Date.now();
            var msg = {
                "purchase_type": "web",
                "sku": sku,
                "purchase_id": purchase_id,
            };
            App.API._post_message(msg, {
                url: '1/cs/user/validate_purchase',
                home_router: Settings.fetch('home_router')
            }, function(error) {
                App.vent.trigger('upgrade:error', error.error);
            }, function (response) {
                App.Auth.set({'capabilities': response.capabilities});
                App.vent.trigger('upgrade:success', response);
                if(response.summary) {
                    App.pbr_summary.set(response.summary);
                }
            });
        },
        handle_cancel_upgrade: function (e) {
            // Close the current modal and show the notification
            $(e.target).closest('.modal').modal('toggle');
            App.vent.trigger('metrics:track', '/manage_subscription/keep');
        },
        handle_confirm_upgrade_monthly: function (e) {
            // Close the current modal and show the notification
            $(e.target).closest('.modal').modal('toggle');
            //App.vent.trigger('metrics:track', '/manage_subscription/keep');
            this.confirm_upgrade("Monthly");
        },
        handle_confirm_upgrade_yearly: function (e) {
            // Close the current modal and show the notification
            $(e.target).closest('.modal').modal('toggle');
            //App.vent.trigger('metrics:track', '/manage_subscription/keep');
            this.confirm_upgrade("Yearly");
        },
        handle_keep_subscription: function (e) {
            // Close the current modal and show the notification
            $(e.target).closest('.modal').modal('toggle');
            App.vent.trigger('metrics:track', '/manage_subscription/keep');
        },
        handle_cancel_subscription: function (e) {
            var self = this;

            // Close the current modal and show the notification
            $(e.target).closest('.modal').modal('toggle');

            App.API.cancel_subscription(null, {
                home_router: Settings.fetch('home_router')
            }, function(error) {
                App.vent.trigger('downgrade:error', error);
            }, function(data) {
                var capabilities = App.Auth.get('capabilities');
                capabilities.renewed = false;

                // renewed flags set's anly after session refresh, so for this session,
                // since we succeded we will set it on client side
                App.Auth.set({capabilities: capabilities});
                App.pbr_summary.set(data);
                App.vent.trigger('metrics:track', '/manage_subscription/cancel',
                                { reason: self.reason });
                App.vent.trigger('downgrade:success');
            });
        },
        handle_renew_subscription: function (e) {
            var self = this;

            // Close the current modal and show the notification
            $(e.target).closest('.modal').modal('toggle');

            App.API.renew_subscription(null, {
                home_router: Settings.fetch('home_router')
            }, function(error) {
                App.vent.trigger('downgrade:error', error);
            }, function(data) {
                var capabilities = App.Auth.get('capabilities');
                capabilities.renewed = true;

                // renewed flags set's anly after session refresh, so for this session,
                // since we succeded we will set it on client side
                App.Auth.set({capabilities: capabilities});
                App.pbr_summary.set(data);
                App.vent.trigger('metrics:track', '/manage_subscription/renew',
                                { reason: self.reason });
                App.vent.trigger('renew:success');
            });
        },
        handle_pay_others_link: function(e) {
            App.Router.navigate('pay-for-others', {trigger: true});

            // Dismiss the current modal, and open the payment one
            $(e.target).closest('.modal').modal('toggle');
        }
    });

    UI.Layouts.SplitBody = M.LayoutView.extend({
        template: 'layouts/split_body',
        className: 'content-container container',
        regions: {
            left: '.left-content',
            workspace: '.workspace'
        }
    });

    UI.Layouts.ManageBody = M.LayoutView.extend({
        template: 'manage/manage_body',
        className: 'manage-container',
        regions: {
            body: '.content'
        }
    });

   UI.Views.IntroView = Backbone.Marionette.ItemView.extend({
        template: "ui/intro",
        tagName: 'div',
        initialize: function (options) {
            var that = this;
            this.name = options.Name;
            this.chrome = navigator.userAgent.indexOf("Chrome") !== -1;
            this.firefox =  navigator.userAgent.indexOf("Firefox") !==  -1;
            window.chrome = this.chrome;
            window.firefox = this.firefox;
            this.mic_selected = false;
            this.ie_browser = navigator.userAgent.indexOf('MSIE') !== -1 || navigator.userAgent.indexOf('Trident') !== -1;
            this.safari =  navigator.userAgent.indexOf('Safari') !== -1;
            this.edge = navigator.userAgent.indexOf('Edge') !== -1;
        },
        onRender: function () {
            var that = this;
            if (this.chrome) {
                this.show_chrome_permissions(function (err) {
                    if (err) {
                        that.$el.find('#ChromeButton').removeProp('disabled');
                        that.$el.find('#chrome-permission-warning').removeClass('hide');
                        trackJs.track("User denied permission to microphones");
                        return;
                    }
                    if (App.VoxerAudio.voxer_audio) {
                        return that.destroy();
                    }
                    that.next_page();
                });
            } else {
                this.interval = setInterval(function () {
                    if (document.getElementById('flash_box') !== null) {
                        that.next_page();
                        clearInterval(that.interval);
                    }
                }, 400);
            }
        },
        serializeData: function () {
            // find profile
            var data,
                profile,
                tmp,
                microphones = [],
                buffer_has_value;

            try {
                buffer_has_value = App.VoxerAudio.buffer_has_value;
            } catch (err) {

            }

            data = {
                buffer_has_value: buffer_has_value,
                name: this.name,
                chrome: this.chrome,
                voxer_audio: App.VoxerAudio.voxer_audio,
                ie_browser: this.ie_browser,
                firefox: this.firefox,
                safari: this.safari && !this.chrome,
                edge: this.edge

            };
            return data;
        },
        events: {
            'click .close-modal': 'destroy',
            'click .close': 'destroy',
            'click button#TestMicButton': 'destroy',
            'click button#ChromeButton': 'next_page'
        },
        show_chrome_permissions: function (callback) {
            window.URL = window.URL || window.webkitURL;
            navigator.getUserMedia  = navigator.getUserMedia || navigator.webkitGetUserMedia ||
                navigator.mozGetUserMedia || navigator.msGetUserMedia;

            callback = callback || function (err) {console.log(err); };
            App.VoxerAudio.bootstrapAudio(callback);
        },
        next_page: function (e) {
            var active = this.$el.find('.active'),
                page = Number(active.attr('data-page')),
                flash_box_bound,
                that = this;

            page += 1;

            active.removeClass('active').addClass('hide');
            this.$el.find('[data-page="1"]').removeClass('hide').addClass('active');
            if (App.VoxerAudio.voxer_audio === false && (page === 1 || !window.chrome)) {

                flash_box_bound = document.getElementById('flash_box').getBoundingClientRect();
                App.VoxerAudio.show_settings(flash_box_bound.left, flash_box_bound.top);

                App.VoxerAudio.$Events.on('flash.isMutedChange', function (event, data) {
                    that.switch_mute(data);
                });

                App.VoxerAudio.$Events.on('isShowing', function (event, data) {
                    if (data === false) {
                        App.VoxerAudio.$Events.off('flash.isMutedChange');
                        App.VoxerAudio.$Events.off('isShowing');
                        App.VoxerAudio.hide_settings();
                        that.destroy();
                    }
                });

                this.switch_mute();
            }
        },
        switch_mute: function (data) {
            var right_arrow = $('#flash-perm-arrow-right'),
                left_arrow = $('#flash-perm-arrow-left'),
                flash = $('#flash'),
                rect = document.getElementById('flash').getBoundingClientRect(),
                rect2 = document.getElementById('flash-permissions').getBoundingClientRect();

            data = (data === undefined) ? App.VoxerAudio.get_is_muted(): data;

            if (data) {
                left_arrow.css({top: rect.top - rect2.top + 70});
                right_arrow.addClass('hide');
            } else {
                left_arrow.css({top: rect.top - rect2.top + 82});
                right_arrow.removeClass('hide').css({top: rect.top - rect2.top + 110});
            }
        },
        destroy: function () {
            //App.Settings.set({'finished-setup': true});
            App.VoxerAudio.hide_settings();
            if (App.layout.regionSettings.currentView) {
                App.layout.regionSettings.currentView.workspace.currentView.render();
            }
            Backbone.Marionette.ItemView.prototype.destroy.apply(this, arguments);

            // Bootratap the audio again to check if user has changed the permisions
            App.VoxerAudio.bootstrapAudio(function (err) {
                console.error(err);
            });

        }
    });

    UI.context_button = {
        hide: function () {
            if (App.shoulderLayout) {
                App.shoulderLayout.$('.new_chat').parent().hide();
            }
        },
        show: function () {
            if (App.shoulderLayout) {
                App.shoulderLayout.$('.new_chat').parent().show();
            }
        }
    };

    UI.Views.CreateChatButton = M.ItemView.extend({
        template: function () {
            return _.template('<button type="button" class="btn new_chat">CREATE NEW CHAT</button>');
        }
    });
    UI.Views.CreateContactButton = M.ItemView.extend({
        template: function () {
            return _.template('<button type="button" class="btn new_contact">CREATE NEW CONTACT</button>');
        }
    });
    // attempt at managing regions
    UI.show_region = function (region) {
        $('.container-liquid').hide();
        if (region !== '') {
            region += '-';
        }
        $('#' + region + 'content').removeClass('hide').show();

        return this;
    };

    // set the tab title
    UI.setTitle = function (title) {
        var def = "Voxer";
        if (title) {
            document.title = def + ' - ' + title;
        }
    };

    UI.menu = function (fragment) {
        fragment = fragment || '/vox';

        $('.main-menu li').removeClass('active');
        $('.main-menu').find('a[href="' + fragment + '"]').parent().addClass('active');
    };

    UI.resizeChatPanels = function () {
        console.log('resizing');
        // resize all remaining chat panels in the workpsace
        var height,
            workspace_width = $('.workspace').width() - 21;
        if ($(".workspace").width() < 900) {
            $(".workspace").addClass("no-avatar");
        }
        else {
            $(".workspace").removeClass("no-avatar");
        }

        $('.chat-panel').css('width', workspace_width.toFixed(0) + 'px');
    };

    UI.Views.TaskModal = M.ItemView.extend({
        template: 'ui/confirm_modal',
        className: 'modal logout-modal',
        events: {
            "click .btn": "handle_task"
        },
        initialize: function () {
            if (!this.options.callback || typeof this.options.callback !== 'function') {
                throw "callback required !";
            }
        },
        onRender: function () {
            this.$el.attr('tabindex', '-1').modal();
        },
        serializeData: function () {
            return {
                headline: this.options.headline,
                info_text: this.options.info_text || '',
                ok_text: this.options.ok_text,
                cancel_text: this.options.cancel_text,
                task: this.options.task
            };
        },
        handle_task: function (event) {
            var that = this,
                the_task = this.options.task;

            this.options.callback(this, event);
        }
    });

    UI.Views.GIFsModal = M.ItemView.extend({
        template: 'ui/gif_s_modal',
        className: 'modal gif-modal',
        events: {
            "click .modal-body img": "handle_task",
            "click .close": "handle_close",
            "click .icon-search": "handle_icon",
            "keyup input": "handle_search",
            "wheel .modal-body": "handle_scroll"
        },
        initialize: function () {
            var that = this;

            if (!this.options.callback || typeof this.options.callback !== 'function') {
                throw "callback required !";
            }
            this.image_template = '<img src="<%- url %>" data-url="<%- data_url %>" data-height="<%- height %>" data-width="<%- width %>" />';
            that.paggination = {
                count: 0,
                offset: 0
            };
            this.search_value = "";

            this.fetch_gifs().done(function (data) {
                if (that.isRendered) {
                    data.left.forEach(function (gif) {
                        that.left_ul.append(_.template(that.image_template, gif));
                    });
                    data.right.forEach(function (gif) {
                        that.right_ul.append(_.template(that.image_template, gif));
                    });
                }
            }).fail(function (err) {
                console.error(err);
                that.$el.modal('hide');
                that.destroy();
            });
        },
        onRender: function () {
            this.$el.attr('tabindex', '-1').modal();

            this.search_input = this.$el.find('input');
            this.left_ul = this.$el.find('.modal-body ul.left');
            this.right_ul = this.$el.find('.modal-body ul.right');
        },
        handle_task: function (ev) {
            var that = this,
                height = ev.target.getAttribute('data-height'),
                width = ev.target.getAttribute('data-width'),
                link = ev.target.getAttribute('data-url'),
                icon = ev.target.getAttribute('src');

            this.options.callback({
                link: link,
                height: height,
                width: width,
                icon: icon
            });

            this.$el.modal('hide');
            this.destroy();
        },
        handle_scroll: function (ev) {
            var that = this,
                list,
                left,
                right;

            var parent_height = this.right_ul.parent().height(),
                height = this.right_ul.height(),
                top = this.right_ul.offset().top;

            if (height - parent_height + top - 300 > 0) {
                return
            }
            if (that.searching) {
                ev.preventDefault();
                return;
            } else {
                that.searching = true;

                this.fetch_gifs().done(function (data) {
                    that.searching = false;
                    if (that.isRendered) {
                        data.left.forEach(function (gif) {
                            that.left_ul.append(_.template(that.image_template, gif));
                        });
                        data.right.forEach(function (gif) {
                            that.right_ul.append(_.template(that.image_template, gif));
                        });
                    }
                }).fail(function (err) {
                    console.error(err);
                    that.$el.modal('hide');
                    that.destroy();
                });
            }
        },
        fetch_gifs: function () {
            var that = this,
                list = [],
                left = [],
                right = [],
                deferred = new $.Deferred(),
                get_data = {
                    limit: 30,
                    api_key: CONSTANTS.giphy_api_key,
                    rating: CONSTANTS.giphy_rating,
                    offset: that.paggination.count + that.paggination.offset
                },
                options = {
                    home_router: CONSTANTS.giphy_route,
                    insecure: true
                };

            if (this.search_value.length >= 3) {
                get_data.q = this.search_value;
                options.url = CONSTANTS.giphy_search_path;
            } else {
                options.url = CONSTANTS.giphy_trending_path;
            }

            App.API._get_message(get_data, options, function (error) {
                deferred.reject(error);
            },
            function (data) {
                if (data && data.data) {
                    try {
                        list = _.map(data.data, function (m) {
                            var n =  m.images.fixed_width || m.images.original;
                            n.data_url = m.images.downsized.url;

                            return n;
                        });

                        left = list.splice(0, parseInt(list.length / 2));
                        right = list.splice(0);
                    } catch (err) {
                        deferred.reject(err);
                    }
                    that.paggination = data.pagination;
                }

                deferred.resolve({
                    left: left,
                    right: right
                });
            });

            return deferred.promise();
        },
        handle_icon: function () {
            this.$el.find('input').focus();
        },
        handle_search: _.debounce(function (ev) {
            var val = this.search_input.val();

            if (this.search_value != val && (val.length >= 3 || val.length == 0)) {
                this.search_value = val;
                this.right_ul.html('');
                this.left_ul.html('');
                this.paggination = {
                    count: 0,
                    offset: 0
                };
                this.handle_scroll(ev);
            }
        }, 500)
    });
    // a generic modal for lists of users etc
    UI.Views.ListModal = M.ItemView.extend({
        template: 'ui/list_modal',
        className: 'modal list-modal logout-modal',        // TODO: change css to be able to remove the "logout-mdal" class
        events: {
            "click .btn": "handle_task"
        },
        initialize: function () {
            if (!this.options.callback || typeof this.options.callback !== 'function') {
                throw "callback required !";
            }
        },
        onRender: function () {
            this.$el.attr('tabindex', '-1').modal();
        },
        serializeData: function () {
            return {
                headline: this.options.headline || 'headline here',
                info_text: this.options.info_text || 'text here',
                ok_text: this.options.ok_text || 'ok text',
                cancel_text: this.options.cancel_text || 'cancel text here',
                task: this.options.task
            };
        },
        handle_task: function () {
            var that = this,
                the_task = this.options.task;

            this.options.callback();
        }
    });

    UI.Views.ShareVoxModal = UI.Views.ListModal.extend({
        template: 'ui/share_vox_modal',
        className: 'modal share-vox',
        onShow: function (e) {
            this.$el.attr('tabindex', '-1').modal('show');
            this.$el.find('input.vox-share').focus().select();
        },
        serializeData: function () {
            return {
                headline: this.options.headline,
                info_text: this.options.info_text || '',
                instructions: this.options.instructions || '',
                task: this.options.task
            };
        }
    });

    UI.Views.ShareProfileLinkModal = UI.Views.ListModal.extend({
        template: 'ui/share_profile_link_modal',
        className: 'modal share-profile-link',
        onShow: function (e) {
            this.$el.attr('tabindex', '-1').modal('show');
            this.$el.find('input.profile-share').focus().select();
        },
        serializeData: function () {
            return {
                headline: this.options.headline,
                info_text: this.options.info_text || '',
                task: this.options.task
            };
        }
    });

    GoogleDriveListItem = M.ItemView.extend({
        template: "ui/google_item",
        tagName: 'li',
        serializeData: function () {
            var data = {
                'webContentLink': this.model.get('webContentLink') || "",
                'thumbnailLink': this.model.get('thumbnailLink') || this.model.get('webContentLink') || "",
                'quotaBytesUsed': this.model.get('quotaBytesUsed') || 0,
                'title': this.model.get('title')
            }

            return data;
        }
    });
    UI.Views.WelcomeVoxer = Backbone.Marionette.ItemView.extend({
        template: "ui/download_app_modal",
        events: {
            'click .close': 'destroy'
        },
        destroy: function () {
            Backbone.Marionette.ItemView.prototype.destroy.apply(this, arguments);
        }
    });

    UI.ViewsContainer = new Backbone.ChildViewContainer();

    return UI;
});

