/*
 *
 */
/* jslint browser: true */

define('main',[

    // Libraries.
    'jquery',

    // The application.
    'app',

    // Misc.
    'routers/router',

    'main/auth',
    'main/api',
    'main/setup',
    'main/settings',
    'main/UI',
    'main/update_channel',
    'main/cache',

    'modules/chat',
    'modules/contact',
    'modules/message',
    'modules/voxer_audio',
    'modules/profile',

    'plugins/placeholder',
    //'plugins/multiregion',
    'constants'

], function ($, App, Router, Auth, API, Setup, Settings, UI, UpdateChannel, Cache, Chat, Contact, Message, Native, Profile) {
    'use strict';

    App.Auth = Auth;

    window.App = App;

    App.chats = new Chat.Collection();
    App.contacts = new Contact.Collection();
    App.profiles = new Profile.Collection();
    App.messages = new Message.Collection();
    App.MyProfile = new Profile.MyModel();
    App.Updates = new UpdateChannel.Channel();
    App.UI = UI;
    App.chat = Chat;
    App.Profile = Profile;
    App.API = new API();
    App.pbr_summary = new Backbone.Model();

    App.addInitializer(function () {
        console.log('CONTROLLER: initializer');
        // render layout components
        // main menu
        if (App.Auth.authenticated()) {
            App.layout.regionHeader.currentView.menuRegion.show(new UI.Views.MainMenu({manage_link: App.vbm}));
            // "shoulder"
            App.shoulderLayout = new UI.Layouts.Shoulder();
            App.layout.regionTopbar.show(App.shoulderLayout);
            App.shoulderLayout.notifications.show(new App.UI.Views.Notification({
                message: "",
                classVal: "warning"
            }));
        }

        Settings.delete('logged-in');
        // do the content layouts
        // chats
        App.layout.regionContent.show(new UI.Layouts.SplitBody());
        App.layout.regionContent.currentView.workspace = new Backbone.Marionette.MultiRegion({
            el: '#content .workspace'
        });
        var contact_split = new UI.Layouts.SplitBody();
        App.layout.regionContacts.show(contact_split);
        // store the contacts Layout for later use
        App.UI.ViewsContainer.add(contact_split, 'Contact.Layout');
    });

    // set up a global request/response handler to load
    // initial data to bootstrap the app
    App.reqres.setHandler('load:allthings', function () {
        // render menu if is not already rendered
        var menuRegion = App.layout.regionHeader.currentView.menuRegion;
        if (!menuRegion || !menuRegion.currentView || !menuRegion.currentView.isRendered)
            menuRegion.show(new UI.Views.MainMenu({manage_link: App.vbm}));

        // "shoulder"
        App.shoulderLayout = new UI.Layouts.Shoulder();
        App.layout.regionTopbar.show(App.shoulderLayout);
        App.shoulderLayout.notifications.show(new App.UI.Views.Notification({
            message: "",
            classVal: "warning"
        }));

        // PAINT THE LAYOUT SO WE CAN STREAM_RENDER
        if (!App.layout.regionContent.currentView.left) {
            App.splitBodyLayout = new UI.Layouts.SplitBody();
            App.layout.regionContent.show(App.splitBodyLayout);
        }

        var chatListLayout = new Chat.Layouts.List();
        App.layout.regionContent.currentView.left.show(chatListLayout);

        // shoulder
        UI.context_button.show();

        var col_view = new Chat.Views.List({
            referenceCollection: App.chats,
            viewPort: chatListLayout.$el.find('#chats-list')
        }).render();

        chatListLayout.selector.show(new Chat.Views.Selector());
        chatListLayout.list.show(col_view);

        App.allthingsLoaded = true;
    });

    function hasGetUserMedia() {
      return !!(navigator.getUserMedia || navigator.webkitGetUserMedia ||
                navigator.mozGetUserMedia || navigator.msGetUserMedia);
    }

    App.VoxerAudio = Native;

    if (window.trackJs === undefined) {
        window.trackJs = {
            track: function (log) { console.log(log); }
        }
    };

    App.reqres.setHandler("get:current:session", function () {
        return App.Auth;
    });

    // global event handler for a new chat panel
    App.vent.on("Chat:create_new", function () {
        // only render once
        if (App.layout.regionContent.currentView.workspace.$el
               .find('.new-chat-panel').length === 1) {
            return;
        }

        var model, panel;
        model = new Chat.Model({
            subject: "New Chat",
            title: "New Chat"
        });

        panel = new Chat.Layouts.Panel({
            model: model,
            id: 'panel-' + model.cid,
            className: 'chat-panel panel panel-default new-chat-panel'
        });

        // show the panel
        panel.render();
        App.layout.regionContent.currentView.workspace.show(panel);
        panel.ui.chat_interaction.hide();
        panel.ui.panel_menu.hide();

        // render the new user view into the panel
        var new_chat_view = new Chat.Views.NewChat({
            model: model,
            panel: panel
        });

        new_chat_view.on('render', function () {
            panel.local_data = _.reject(App.profiles.map(function (m) {
                if ((m.attributes.is_contact || m.attributes.is_team) &&
                            m.attributes.user_id !== App.Auth.get('rebelvox_user_id') &&
                            !m.is_deleted()) {
                    if (m.attributes.is_team === undefined) {
                        m.attributes.is_team = false;
                    }
                    if (m.attributes.is_contact === undefined) {
                        m.attributes.is_contact = false;
                    }
                    m.attributes.avatar_url = m.avatar_src();
                    m.attributes.username = m.attributes.username || "";
                    return m.attributes;
                }
            }), function (m) {
                return m === undefined || (m.account_flags && m.account_flags.indexOf('user_deleted') !== -1) ||
                       (m.accounts_flags && m.accounts_flags.indexOf('user_deleted') !== -1);
            });
        });

        // render the new chat form view
        panel.messages.show(new_chat_view);
    });

    //
    // global event to render the active chats stored in cache
    // currently triggered by message router on the "timeline_complete" message
    //
    App.vent.on('render_active_chats', _.debounce(function () {
        var active_chats = App.Settings.fetch('active_chats');
        try {
            active_chats = [JSON.parse(active_chats)];
            active_chats = active_chats.sort(function (a, b) {
                return a - b;
            })[0];
        } catch (e) {
            active_chats = {};
            App.Settings.set({'active_chats': active_chats});
        }

        // grab all cids for active chat models
        var active_cids = _.pluck(App.chats.where({active: true}), 'cid') || [];
        _.each(active_cids, function (cid) {
            var _view = App.UI.ViewsContainer.findByModelCid(cid);

            if (!_view)
                return;

            if (_view.regionManager.get('participants'))
                _view.regionManager.get('participants').destroy();

            _view.$el.hide();
        });

        App.chats.where({active: true}).forEach(function (chat) {
            // unset the active flag
            chat.set({active: false});
        });

        if (!Object.keys(active_chats).length && App.chats.length) {
            active_chats[App.chats.first().get('thread_id')] = (new Date()) / 1000;
            App.Settings.set({'active_chats': active_chats});
        }

        // loop through persisted open chats states and render those panels
        _.each(active_chats, function (element, index, list) {
            var model = App.chats.get(index);

            // we might have deleted that chat already ...
            if (model) {
                model.set({active: true});
                if (App.layout.regionContent.currentView.workspace.$el.find('.chat-panel#panel-' + model.cid).length === 0) {
                    var panel = new Chat.Layouts.Panel({
                        model: model,
                        id: 'panel-' + model.cid
                    });
                    panel.render();
                    App.layout.regionContent.currentView.workspace.show(panel);

                    var messages = model.get('messages');
                    // unset all "append" attributes
                    messages.invoke('set', 'append', false);

                    if (model.get('encrypted_chat')) {
                        panel.$el.html(
                            '<div class="private-caht-placeholder">' +
                                '<i class="zmdi zmdi-lock-outline"></i>' +
                                '<h1>Private Chat</h1>' +
                                '<div>This is a private chat and can only be unlocked from your mobile device.</div>' +
                             '</div>');

                        return;
                    }

                    var onMessages = function() {
                        if (panel.isDestroyed)
                            return;

                        var messagesView = new Chat.Views.Messages({
                            collection: messages
                        });
                        panel.messages.show(messagesView);
                    };

                    if (messages.models.length) {
                        onMessages();
                    } else {
                        // load the chat's timeline, then render
                        messages.timeline({
                            chat: model,
                            success: onMessages
                        });
                    }

                }
                else {
                    var panel = App.layout.regionContent.currentView.workspace.$el.find('.chat-panel#panel-' + model.cid);
                    panel.addClass('highlight');
                }
                App.layout.regionContent.currentView.workspace.$el
                   .scrollTo('#panel-' + model.cid, {duration: 400});
            } else if (index.indexOf('HL_') === -1) {
                var post_body = {
                    "thread_id": index,
                    "user_ids": [ App.Auth.get('user_id') ]
                };
                App.API.add_participants(post_body, {
                    router: App.Settings.fetch('home_router')
                }, function (data) {
                    console.log(data);
                }, function (data) {
                    var panel,
                        messages,
                        messagesView,
                        model;

                    if (data.reject_reason)
                        return;

                    if (App.chats.get(data.thread_meta.thread_id)) {
                        model = App.chats.get(data.thread_meta.thread_id);
                        model.set(data.thread_meta);
                    } else {
                        model = new App.chat.Model(data.thread_meta);
                        App.chats.add(model);
                    }

                    model.set({active: true});

                    panel = new Chat.Layouts.Panel({
                        model: model,
                        id: 'panel-' + model.cid
                    });
                    panel.render();
                    App.layout.regionContent.currentView.workspace.show(panel);

                    if (App.MyProfile.get('display_name')) {
                        model.get('messages').add({
                            alt_text: App.MyProfile.get('display_name') + "  has joined the chat.",
                            body: App.MyProfile.get('display_name') + "  has joined the chat.",
                            consumed: true,
                            content_type: "text",
                            normalized_create_time: data.normalized_create_time,
                            posted_time: data.posted_time,
                            from: App.Auth.get('user_id'),
                            recipients: data.thread_meta.recipients.recipients,
                            message_id: data.final_message_id,
                            subcontent_type: "add_participants",
                            subject: data.thread_meta.subject,
                            thread_id: data.thread_id
                        });
                    }

                    messagesView = new Chat.Views.Messages({
                        collection: model.get('messages')
                    });
                    panel.messages.show(messagesView);
                });
            }
        });
    }), 250);

    // start the application
    $(function () {
        console.log("MAIN: starting app");

        (function($) {
          return jQuery.fn.insertAt = function(index, element) {
            var lastIndex;
            if (index <= 0) return this.prepend(element);
            lastIndex = this.children().size();
            if (index >= lastIndex) return this.append(element);
            return $(this.children()[index - 1]).after(element);
          };
        })(jQuery);

        // determine which backend/router to talk to (ping)
        Setup._home_router().done(function (data) {
            var router = '',
                saved_messages,
                last_message,
                start_time;
            if (data && data.status !== 0 && data !== "") {
                try {
                    var config = JSON.parse(data);
                    if (typeof App !== undefined) {
                        //config.public_www = "https://www.voxer.com";
                        App.Config = new Backbone.Model(config);
                        Stripe.setPublishableKey(App.Config.get("stripe_publishable_key"));
                        App.stripeModal = StripeCheckout.configure({
                            key: App.Config.get("stripe_publishable_key"),
                            //image: '/assets/img/favicon.png'
                            //image: '/assets/img/web.png'
                            image: '/assets/img/walkie_border.jpg'
                        });
                    }
                    if (config.router) {
                        router = 'https://' + config.router;
                    }
                } catch (e) {

                }
            }

            App.Settings.set({home_router: router});
            // make sure flash is all up and running
            // start the application
            App.start();

            App.Router = Router;
            Backbone.history.start({
                pushState: true,
                hashChange: false
            });

            // Bypass all links clicks trough the Backbones router
            $(document).on('click', 'a', function (e) {
                var href = $(this).attr('href'),
                    exp = new RegExp("^(http|https|mailto):");

                // We have to allow complete links and catch only the internal ones
                if (href && !exp.test(href)) {
                    e.preventDefault();

                    App.Router.navigate(href, {trigger: true});
                }
            });

            //Add blue animated border and remove with condition when focus and blur
            $(document).on('focus', '.form-control', function(){
                $(this).closest('.fg-line').addClass('fg-toggled');
            })
            $(document).on('blur', '.form-control', function(){
                var p = $(this).closest('.form-group');

                if (p.hasClass('fg-float')) {
                    if (p.find('.form-control').val().length == 0) {
                        $(this).closest('.fg-line').removeClass('fg-toggled');
                    }
                } else {
                    $(this).closest('.fg-line').removeClass('fg-toggled');
                }
            });


            console.log("MAIN: app started");
            App.request('load:allthings');

            if (App.Auth.authenticated() && App.Settings.fetch('user_id')){
                saved_messages = Cache.fetch_timeline(App.Settings.fetch('user_id'))
            }

            // Get cached messages only if they are not older than 2 days,
            // Othervise the differance might be bigger than fatching an complete timeline, resulting in slower loading
            var days_differance = Math.floor((Date.now() - parseFloat( Cache.fetch_raw('start_time')) * 1000 )/1000/60/60/24);
            if (saved_messages && saved_messages.length && (isNaN(days_differance) || days_differance < 3)) {
                last_message = saved_messages[saved_messages.length - 1];
                App.Message.route(saved_messages, {live: false});
                App.vent.trigger('avatar_bust');

                start_time = Cache.fetch_raw('start_time') || last_message.posted_time || last_message.create_time;
            }

            App.Auth.getAuth().done(function (session_data) {
                console.log("MAIN: checking session good");

                App.Message.update_timeline(undefined, undefined, start_time).done(function () {
                    if (!placeholderIsSupported()) {
                        $.placeholder.shim();
                    }
                });
            }).always(function () {
                App.regionMain.$el.removeClass('hide');
            });
        }).fail(function (error) {
            alert("Oops! that didn't work. Try reloading.");
        });
    });
});

