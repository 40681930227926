/*
 * The router defines routes and their corresponding methods in the controller.
 */
/*jslint browser: true*/
define('routers/router',[
    "app",
    // Libraries.
    'backbone',

    // Modules.
    "main/UI",
    'routers/controller'

], function (App, Backbone, UI, controller) {
    'use strict';
    var Marionette = Backbone.Marionette;

    var Router = Marionette.AppRouter.extend({
        onRoute: function () {
            App.vent.trigger('route:changed');
        },
        before: function (route, params) {
            switch (route) {
                case "chats":
                case "chats/:thread":
                case "compose":
                    UI.show_region('');
                    break;
                case "upgrade":
                case "pay-for-others":
                    UI.show_region('upgrade-to-pro');
                    break;
                case "u/:username":
                case "contact/:id":
                case "contacts":
                case "contacts/:id":
                case "profile":
                case "myprofile":
                    UI.show_region('contacts');
                    break;
                case "invite":
                case "invites":
                    UI.show_region('invites');
                    break;
                case "integrations":
                    UI.show_region('integrations');
                    break;
                case "settings":
                case "settings/:type":
                    UI.show_region('settings');
                    break;
                case "support":
                case "feedback":
                    UI.show_region('support');
                    break;
                case "support_success":
                    // render login page if we are not autentificated
                    UI.show_region('support');
                    break;
            }

            if (route.length === 0 && params !== "") {
                route = [params];
            }


            if (route.length > 0 && "/".indexOf(route) !== -1) {
                try {
                    var currentUser = App.request("get:current:session");
                    if (currentUser === null) {
                        App.vent.trigger("auth:logged_out");
                        return false;
                    }
                } catch (e) {
                    if (e.message.indexOf("Handler not found for 'get:current:session'") === -1)
                        console.warn('Error: ' + e);

                    return false;
                }
            }
        },
        appRoutes: {
            '' : 'index',
            '/' : 'index',
            'chats': 'vox',
            'chats/:thread': 'vox_thread',
            'compose': 'new_chat',
            'upgrade': 'upgrade',
            'pay-for-others': 'pay_for_others',
            'manage-members': 'manage_members',
            'login': 'login',
            'logout': 'logout',
            'login_sso': 'login_sso',
            'sso/saml/slo': 'logout',
            'sso/saml/acs/:response': 'login_saml_sso',
            'authcallback/': 'authcallback',
            'forgot_password': 'forgot_password',
            'sign_up': 'sign_up',
            'sign_up/:step': 'sign_up',
            'change_password': 'change_password',
            'u/:username': 'u',
            'contact/:id': 'contact',
            'contacts': 'contacts',
            'contacts/:id': 'contacts',
            'profile': 'profile',
            'myprofile': 'profile',
            'integrations':'integrations',
            'invites': 'invites',
            'invite': 'invites',
            'settings': 'settings',
            'settings/:type': 'settings',
            'support': 'support',
            'support_success': 'support_success',
            'feedback': 'support',
        }
    });

    // return instanciated router
    return new Router({
        controller: controller
    });
});

