define('modules/contact',[
    // Global application context.
    "app",

    //misc
    "main/settings",
    "main/cache",

    // Third-party libraries.
    "backbone",

    "libs/minEmoji",
    "main/UI",
    // plugins
    "plugins/jquery.lazyload"
],

function (App, Settings, Cache, Backbone, minEmoji, UI) {
    var M = Backbone.Marionette;
    var Contact = App.module();

    Contact.Model = Backbone.Model.extend({
        idAttribute : "user_id"
    });

    Contact.Collection = Backbone.Collection.extend({
        model: Contact.Model,
        url: function () {
            return App.create_url('2/cs/contact_list');
        },
        comparator: function (model) {
            if (!model || !model.get('first') && !model.get('last')) {
                return false;
            }
            if (Settings.fetch('sort-by') === undefined || Settings.fetch('sort-by') === 'last-name') {
                return (model.get("last") + model.get("first")).toLowerCase();
            }
            else {
                return (model.get("first") + model.get("last")).toLowerCase();
            }
        },
        initialize: function () {
            this.on("add", function (model) {
                // change triggers "change event" which in turn triggers re-render unless {silent : true} option is set
                model.set({
                    name: model.get('first') + " " + model.get('last')
                }, {silent:true});
            });
        },
        fetch: function (options) {
            var deferred = new $.Deferred(),
                collection = this;

            options = options || {};
            options.dataType = 'text';
            options.success = function () {
                deferred.resolve(collection);
            };
            //Call Backbone's fetch
            Backbone.Collection.prototype.fetch.call(this, options);
            return deferred.promise();
        },
        parse: function (data) {
            var that = this, contacts = that.models, contacts_ids = [], contacts_list;
            data = data || "{contacts:[]}";
            contacts_list = JSON.parse(data);
            _.each(contacts_list.contacts, function (contact, user_id) {
                var tags = contact.tags;
                if (user_id !== Settings.fetch('username') && !contact.is_deleted()) {
                    contacts_ids.push(user_id);
                    contact.user_id = user_id;
                    that.add(contact);
                }
            });

            return that.models;
        }
    });

    Contact.Layouts.List = M.LayoutView.extend({
        template: 'contact/list_layout',
        className: 'panel panel-default',
        regions: {
            selector: '#contact-selector',
            list: '#contacts-list'
        },
        serializeData: function(){
            return business_user = App.MyProfile.get('business_ids') || false;
        }
    });


    Contact.Views.Selector = M.ItemView.extend({
        template: 'contact/contact-selector',
        className: 'contact-selector',
        events: {
            "click li": "handle_click",
            "keyup .navbar-search": "handle_search",
            "submit form": "handle_form_submit",
            "click .icon-remove-sign": "handle_clear_search"
        },
        initialize: function () {
            App.Settings.on('change:sort', function () {
                this.render();
            }, this);
            if (!this.options.context) {
                this.options.context = 'Contacts';
            }
            this.show_contacts();
            var that = this;
            App.MyProfile.on('change', function (profile) {
              if (!that.isDestroyed) {
                  that.show_contacts();
                  that.render();
              }
            });
        },
        onShow: function () {
            if (!placeholderIsSupported()) {
                $.placeholder.shim();
            }
        },
        serializeData: function () {
            var data = {};
            if (this.options.context) {
                data.sort = this.options.context;
            }
            else {
                data = this.options;
                data.sort = Settings.fetch('sort') || 'Contacts';
            }

            data.business_user = App.MyProfile.get('business_ids') || false;
            return data;
        },
        show_contacts: function (context) {
            if (this.options.context === 'Contacts') {
                this.options.list_view.collection.filter(function (profile) {
                    // return all profiles for contacts
                    // excluding teams, self as well as not showing deleted contacts
                    var tags = profile.get('tags'),
                        deleted = profile.is_deleted();

                    return !deleted && !profile.get('is_team') &&
                        profile.get('is_contact') === true &&
                        profile.get('user_id') !== App.Auth.get('rebelvox_user_id');
                });
            }
            else {
                delete this.options.list_view.options.active;
                this.options.list_view.collection.where({is_team: true});
            }
        },
        handle_click: function (e) {
            e.preventDefault();
            var el = e.target,
                context = $(el).text();

            if (context === this.options.context) {
                return;
            }
            // highlight the selected tab
            this.$('.active').removeClass('active');
            $(el).parent().addClass('active');

            // clear the search field
            this.$('input').val('');

            // store context
            this.options.context = context;
            App.Settings.set({sort: context});

            // show context related profiles list
            this.show_contacts();
        },
        handle_search: _.debounce(function (e) {
            e.preventDefault();
            e.stopPropagation();
            var me = App.Auth.get('rebelvox_user_id'),

                context = this.options.context || 'Contacts',
                query = this.$('.navbar-search input').val(),
                that = this;

            if (query !== "") {
                // search only in context
                if (query.length === 0) {
                    this.show_contacts(context);
                    this.$('.navbar-search .icon-remove-sign').addClass('hide');
                } else {
                    this.$('.navbar-search .icon-remove-sign').removeClass('hide');
                    // filter the collection  with search query
                    this.options.list_view.collection.filter(function (model) {
                        var name = model.get('name');
                        if (!name) {
                            return false;
                        }
                        if (context === 'Contacts') {
                            return (name.toLowerCase().indexOf(query.toLowerCase()) !== -1 && !model.get('is_team') && model.get('is_contact') && model.get('user_id') !== me);
                        }
                        else {
                            return (name.toLowerCase().indexOf(query.toLowerCase()) !== -1 && model.get('is_team'));
                        }
                    });

                    //search server
                    if (context === 'Contacts' && query.length >= 3) {
                        // filter the collection with search query
                        App.API.search({q: query}, {
                            router: App.Settings.get('home_router')
                        }, function (e) {
                            console.error(e);
                        }, function (value) {
                            App.profiles.add(value.rows);
                            that.options.list_view.collection.add(value.rows);
                        });
                    }
                }
            }
            else {
                //empty search, clear
                this.handle_clear_search();
            }
        }, 500),
        handle_form_submit: function (e) {
            e.preventDefault();
            return false;
        },
        handle_clear_search: function () {
            //var context = Settings.fetch('sort');
            this.$('input').val('');
            this.$('input').focus();
            if (!placeholderIsSupported()) {
                $.placeholder.shim();
            }
            this.$('.navbar-search .icon-remove-sign').addClass('hide');
            this.show_contacts();
        }
    });

    Contact.Views.Item = M.ItemView.extend({
        template: "contact/list_item",
        tagName: 'li',
        id: function () { return this.model.cid; },
        className: function () {
            var classname = 'contact-item';

            if (this.model.get("is_contact")) {
                classname += ' user-contact';
            }

            return classname;
        },
        events: {
            "click": "handle_click"
        },
        initialize: function () {
            App.profiles.on("reset", this.render, this);
        },
        serializeData: function () {
            // find profile
            var model = this.options.model,
            data,
            profile,
            avatar_src,
            name = model.get('name') || App.Profile.Model.prototype.name.call(model),
            tmp = [];

            avatar_src = App.Profile.Model.prototype.avatar_src.call(model);
            data = {
                is_team: model.get("is_team"),
                avatar_src: avatar_src,
                title: escapeHTML(model.get('title')),
                name: escapeHTML(name),
                username: (model.get("username") ? escapeHTML(model.get("username"))  : "").split(CONSTANTS.FOF_LABEL)[0],
                label: model.get("label") ? escapeHTML(model.get("label")) : "",
                first: escapeHTML(model.get("first")),
                is_pro: model.get("vpro_account_badge"),
                content: model.get("members") ? model.get("members") : model.get("teams"),
                currentTeamMember: model.get('currentTeamMember') || false,
                hideTeamMember: model.get('hideTeamMember') || false,
                user_id: model.get("user_id"),
                is_contact: model.get("is_contact")
            };

            if (data.is_team) {
                _.each(data.content, function (value) {
                    var profile = App.profiles.where({user_id: value});
                    if (profile[0] !== undefined) {
                        tmp.push(escapeHTML(profile[0].get('name')));
                    }
                });
                tmp = tmp.join();
                if (tmp.length > 30) {
                    data.content = tmp.substring(0, 30) + '...';
                } else {
                    data.content = tmp;
                }
            } else {
                //Do we not have data on there teams?
                if (model.get('teams') === undefined) {
                    var teams = App.profiles.where({'is_team': true });
                    _.each(teams, function (element) {
                        var members = element.get('members');
                        if (_.contains(members, model.get('user_id'))) {
                            tmp.push(escapeHTML(element.get('name')));
                        }
                    });
                    model.set({'teams': tmp});
                } else {
                    tmp = model.get('teams');
                }
                tmp = tmp.join(', ');
                if (tmp.length > 30) {
                    data.content = tmp.substring(0, 30) + '...';
                } else {
                    data.content = tmp;
                }
            }

            return data;
        },
        handle_click: function (e) {
            e.preventDefault();
            $('.contact-item.active').removeClass('active');
            this.$el.addClass('active');

            App.Router.navigate('/contact/' + this.model.get('user_id'), {trigger: true});
        }
    });

    Contact.Views.ReadRecipts = Contact.Views.Item.extend({
        template: "contact/list_read_recipts_item",
        serializeData: function () {
            // Extend the super class method
            var data = Contact.Views.Item.prototype.serializeData.call(this);
            data.read_at = this.model.get('read_at') ? format_message_timestamp(this.model.get('read_at')) : false;
            data.delivered = this.model.get('delivered_at') ? true : false;

            return data
        }
    });

    Contact.Views.FriendItem = Contact.Views.Item.extend({
        className: function () {
            var classname = 'friend contact-item';

            if (this.model.get("is_contact")) {
                classname += ' user-contact';
            }

            return classname;
        },
        template: "contact/friend_item"
    });

    Contact.Views.LikeRecipt = Contact.Views.Item.extend({
        template: "contact/list_like_recipts_item",
        serializeData: function () {
            if (!this.model.get('name')) {
                this.model.set({name : this.model.name()});
            }

            // Extend the super class method
            var data = Contact.Views.Item.prototype.serializeData.call(this);
            data.liked_at = this.model.get('liked_at') ? format_message_timestamp(this.model.get('liked_at')) : false;

            return data;
        }
    });

    Contact.Views.List = M.CollectionView.extend({
        tagName: 'ul',
        className: 'contact-list',
        childView: Contact.Views.Item,
        reorderOnSort: true,
        modelEvents: {
            "change collection": this.render
        },
        emptyView: function () {
            var context = Settings.fetch('sort');
            if (context === "Teams") {
                return new Contact.Views.NoTeamsView();
            }
            if (context !== "Contacts") {
                console.log("defaulting to Contacts; unhandled context:", context);
            }
            return new Contact.Views.NoContactsView();
        },
        onReorder: function () {
            // Cleanup
            this.$el.find('li.contacts-separator').remove();
            // append labels
            if (this.$el.find('li.user-contact').length) {
                this.$el.find('li.user-contact').first()
                    .before('<li class="contacts-separator">CONTACTS</li>');
                this.$el.find('li.user-contact').last()
                    .after('<li class="contacts-separator">OTHER USERS</li>');
            } else if (this.$el.find('li:not(.user-contact)').length) {
                this.$el.find('li:not(.user-contact)').first()
                    .before('<li class="contacts-separator">OTHER USERS</li>');
            } else {
                this.$el.append('<li class="contacts-separator">OTHER USERS</li>');
            }
        },
        initialize: function () {
            var _self = this;

            this.$('img.lazy').lazyload({
                container: this.$el.closest('.panel-body'),
                threshold: 200,
                effect: "fadeIn"
            });

            function onChangeSortBy() {
                if (!_self.isDestroyed) {
                    _self.render();
                } else {
                    App.Settings.off('change:sort-by', onChangeSortBy, this);
                }
            }
            App.Settings.on('change:sort-by', onChangeSortBy, this);

            function onContactsUpdated() {
                if (!_self.isDestroyed){
                    _self.render();
                } else {
                    App.vent.off('contacts:updated', onContactsUpdated, this);
                }
            }
            App.vent.on('contacts:updated', onContactsUpdated, this);

            function onContactsImported(collection) {
                _self.collection.reset(collection);
                App.vent.trigger('avatar_bust');
            }
            App.vent.on('contacts:imported', onContactsImported, this);

            if (!this.options.context) {
                this.options.context = 'Contacts';
            }

            this.item_height = 67;
            this.adding_count = 25;
        },
        onBeforeRender: function () {
            var sortby,
                adding_count = parseInt((this.$el.scrollTop() * 2 + this.$el.height()) / this.item_height + this.adding_count),
                _self = this;

            if (Settings.fetch('sort-by') === undefined || Settings.fetch('sort-by') === 'last-name') {
                sortby = function (m) {
                    return  m.get('last') && m.get('last').toLowerCase();
                };
            }
            else {
                sortby = function (m) {
                    return  m.get('first') && m.get('first').toLowerCase();
                };
            }

            // if (!this.backup_collection || this.backup_collection.length !== this.collection.length) { // this logic fails when switching between teams and contacts with the same length (which happens way more often than "never")
                var new_model_array = new Backbone.Collection(this.collection.sortBy(sortby));
                var me = new_model_array.findWhere({user_id: App.Auth.get('user_id')});
                new_model_array.remove(me, {silent: true});

                // remove deleted contacts and either teams or contacts depending on settings
                new_model_array = new_model_array.reject(function (p) {
                    var reject = false;
                    if (App.Settings.get('sort') === "Contacts") {
                        reject = p.get('is_team') === true;
                    }
                    else {
                        reject = p.get('is_team') !== true;
                    }
                    return !!(p.get('tags') && p.get('tags').indexOf('deleted') !== -1);

                });

                this.backup_collection = new_model_array;
                this.collection.reset(this.backup_collection.slice(0, this.adding_count), {silent:true});
                /*
            } else if (this.backup_collection) {
                if (adding_count > this.backup_collection.length - 1) {
                    adding_count = undefined;
                }
                this.collection.reset(this.backup_collection.slice(0, adding_count), {silent:true});
            }
            */

            this.$el.off('scroll');
            this.$el.on('scroll', function() {
                var collection = (_self.backup_collection.length < _self.collection.length) ? _self.collection : _self.backup_collection,
                    adding_count = parseInt((_self.$el.scrollTop() * 2 + _self.$el.height()) / _self.item_height + _self.adding_count);

                if (adding_count > collection.length - 1) {
                    adding_count = undefined;
                }

                _self.collection.set(collection.slice(0, adding_count));
            }.bind(this));
            this.collection.reset(this.backup_collection.slice(0, this.adding_count), {silent: true});
            this.collection.remove(App.Settings.get('user_id'));
        },
        // render view open before page reload
        onRender: function () {
            var active_profile = this.options.collection.get(this.options.active),
                first_contact,
                last_contact,
                is_contacts = App.Settings.get('sort') === "Contacts";

            if (this.options.active && active_profile) {
                this.$('.active').removeClass('active');
                this.$('#' + active_profile.cid).addClass('active');
                // get the canvas for the profile view to render
                var profile_region = App.UI.ViewsContainer.findByCustom('Contact.Layout').regionManager.get('workspace');
                // profile view
                var profile_view = new Contact.Views.Contact({
                    model: active_profile,
                    id: active_profile.cid
                });
                profile_region.show(profile_view);
            }

            // Cleanup
            this.$el.find('li.contacts-separator').remove();

            // append labels
            if (is_contacts && this.$el.find('li.user-contact').length) {
                this.$el.find('li.user-contact').first()
                    .before('<li class="contacts-separator">CONTACTS</li>');
                this.$el.find('li.user-contact').last()
                    .after('<li class="contacts-separator">OTHER USERS</li>');
            } else if (is_contacts && this.$el.find('li:not(.user-contact)').length) {
                this.$el.find('li:not(.user-contact)').first()
                    .before('<li class="contacts-separator">OTHER USERS</li>');
            } else if (is_contacts) {
                this.$el.append('<li class="contacts-separator">OTHER USERS</li>');
            }

            if (this.options.parent) {
                this.options.parent.html(this.$el.html());
            }
        }
    });

    Contact.Views.FriendsList = Contact.Views.List.extend({
        childView: Contact.Views.FriendItem,
        onBeforeRender: undefined,
        onRender: function () {
            if (this.options.parent) {
                this.options.parent.html(this.$el.html());
            }
        },
        emptyView: function () {
            return new Contact.Views.NoItemsView();
        }
    });

    Contact.Views.ReadReciptsList = Contact.Views.List.extend({
        onBeforeRender: undefined,
        childView: Contact.Views.ReadRecipts,
        emptyView: function () {
            return new Contact.Views.NoItemsView();
        }
    });

    Contact.Views.LikeReciptsList = Contact.Views.List.extend({
        childView: Contact.Views.LikeRecipt,
        onBeforeRender: undefined,
        onBeforeRender: function () {
        },
        emptyView: function () {
            return new Contact.Views.NoItemsView();
        },
        onRender: function () {
            if (this.options.parent) {
                this.options.parent.html(this.$el.html());
            }
        }
    });

    Contact.Views.EmptyContactsView = M.ItemView.extend({
        template: 'contact/panel/empty-contacts',
        className: function () {
            return 'contact-panel panel panel-default';
        },
        events: {
            "click .import-google": "handle_import_contacts"
        },
        serializeData: function () {
            return {
                show_import: !App.profiles || !App.profiles.length || App.profiles.length < 3
            };
        },
        handle_import_contacts: function() {
            var self = this,
                timestamp = Date.now() / 1000,
                url = App.API._create_url({
                    uri: 'tp/google/oauth/1',
                    query_params: {
                        user_id: App.Auth.get("user_id")
                    }
                }, {
                    home_router: App.Settings.get("home_router")
                });

            function onMessage(event) {
                if (event.data.error) {
                    handle_import_error(event.data.error);
                } else if (event.data.code) {
                    App.API.link_google_account({
                        user_id: App.Auth.get('user_id'),
                        state  : event.data.state,
                        code   : event.data.code
                    }, {
                        router: Settings.fetch('home_router')
                    }, handle_import_error, function() {
                        App.Message.update_timeline(timestamp, timestamp).then(function() {
                            App.vent.trigger('contacts:imported', App.profiles.models);
                        });
                    });
                }

                function handle_import_error(error) {
                    var message;
                    if (error.error) {
                        message = error.error;
                    } else if (typeof error === "string" && error.length) {
                        message = error;
                    } else {
                        message = "Server error";
                    }

                    // TODO: show the error
                    console.log(error);
                }


                window.popup.postMessage("Close", "*");
                window.removeEventListener("message", onMessage);
            }

            window.popup = window.open(url, "mywindow", "resizable=yes, height=550, width=500");
            window.addEventListener("message", onMessage, false);

            App.Account.Views.Login.prototype.poll_popup.call(self);
        }
    });

    Contact.Views.Contact = M.LayoutView.extend({
        template: 'contact/panel/contact_item',
        className: function () {
            return 'contact-panel panel panel-default';
        },
        events: {
            "click button.start": "handle_start_chat",
            "click button.delete-contact": "handle_delete_contact",
            "click button.add-contact": "handle_add_contact",
            "click .toggle-hidden": "handle_toggle",
            "click a.contact-link": "handle_route_contact",
            "click .more-friends": "hanlde_show_all_friends",
            "click .contact-info": "handle_show_friend",
            "click .contact-info .addcontact": "handle_add_friends_friend",
            "click #all-contacts .modal-header": "handle_close_all_contacts"
        },
        initialize: function () {
            var that = this,
                public_contacts = (typeof that.model.get('account_flags') === "undefined" || that.model.get('account_flags').indexOf('user_show_contacts_blocked') === -1) ? true : false,
                contacts_result = new $.Deferred(),
                profiles_result = new $.Deferred();

            if (public_contacts && that.model.get('contacts')) {
                contacts_result.resolve(that.model.get('contacts'));
            } else if (public_contacts) {
                App.API.contact_list({
                    user_id_search: this.model.get('user_id')
                }, {
                    router: Settings.fetch('home_router')
                }, function (err) {
                    contacts_result.reject(err);
                }, function (data) {
                    var keys = [];
                    for (var key in data.contacts) {
                        if (!data.contacts[key].tags || (data.contacts[key].tags.indexOf('deleted') === -1 && data.contacts[key].tags.indexOf('blocked') === -1))
                            keys.push(key);
                    }
                    keys = _.without(keys, App.Auth.get('user_id'));
                    contacts_result.resolve(keys);
                });
            }

            contacts_result.done(function (contacts) {
                that.model.set({contacts: _.without(contacts, that.model.get('user_id'))});

                if (that.model.get('profiles')) {
                    profiles_result.resolve(that.model.get('profiles'));
                } else {
                    // Get just random 10 profiles for first render, will fetch more if requested
                    App.profiles.get_profile(_.shuffle(that.model.get('contacts')).slice(0,10), false).then(function (profiles) {
                        profiles = profiles.filter(function (p) {
                            // Filter out the profiles that have the privacy enambled
                            return (typeof p.account_flags === "undefined" || p.account_flags.indexOf('user_privacy_mode_enabled') === -1) ? true : false;
                        });
                        that.model.set({profiles: profiles});
                        profiles_result.resolve(profiles);
                    });
                }
            }).fail(function () {
                var spiner = that.$el.find('.friends-list li.spiner');
                spiner.find('img').remove();
                spiner.append('<span> Limit reached. Please try again in 60s.</span>');
            });

            profiles_result.done(function (profiles) {
                that.random_friends = profiles;
                if (that.isRendered) {
                    this.friends_list = new Contact.Views.FriendsList({
                        collection: new Backbone.Collection(that.random_friends),
                        parent: that.$el.find('.friends-list')
                    });

                    if(this.friends_list.collection.length <= 5){
                        that.$el.find('.more-friends').addClass('hide');
                    }
                    this.friends_list.render();
                }
            })
        },
        regions: {
            timeline: '#timeline-container'
        },
        serializeData: function () {
            var model = this.options.model,
                data,
                teams_text = [],
                member_of_teams = _.compact(App.profiles.map(function (team) {
                    if (team.get('is_team')) {
                        if (team.get('members').indexOf(model.get('user_id')) !== -1) {
                            return team;
                        }
                    }
                }));

            if (model.get("members")) {
                teams_text = model.get("members");
            }
            else {
                _.each(member_of_teams, function (team) {
                    teams_text.push({id: "c-" + team.get('user_id'), name: team.get('name')});
                });
            }

            data = {
                is_team: model.get("is_team"),
                avatar_src: model.avatar_src(),
                name: escapeHTML(model.name()),
                first: escapeHTML(model.get("first") || ""),
                username: (model.get("username") ? escapeHTML(model.get("username"))  : "").split(CONSTANTS.FOF_LABEL)[0],
                location: model.get("location") ? escapeHTML(model.get("location")) : "",
                content: model.get("members") ? model.get("members") : 'No Members',
                is_contact: model.get('is_contact') || false,
                is_deleted: model.is_deleted(),
                pro: model.get('vpro_account_badge'),
                fullname: model.get('display_name'),
                status_message: model.get('status_message') ||  "Send me a message",
                public_contacts: (typeof model.get('account_flags') === "undefined" || model.get('account_flags').indexOf('user_show_contacts_blocked') === -1) ? true : false
            };

            if (data.is_team) {
                data.is_contact = false;
                var tmp = [];
                _.each(teams_text, function (value) {
                    var profile = App.profiles.findWhere({user_id: value}), user;
                    if (profile !== undefined) {
                        user = {
                            name: escapeHTML(profile.get('name')),
                            username: escapeHTML(profile.get('username')),
                            id: profile.get('user_id'),
                            image: profile.avatar_src()
                        };
                        tmp.push(user);
                    }
                });
                tmp = _.sortBy(tmp, function (p) { return p.last; });
                data.content = tmp;
            }
            else {
                data.content = teams_text;
            }

            data.description = model.get('description') || "";

            return data;
        },
        hanlde_show_all_friends: function (e) {
            var that = this,
                profiles_result = new $.Deferred(),
                contacts = that.model.get('contacts'),
                profiles = that.model.get('profiles');

            if (contacts && contacts.length &&
                profiles && profiles.length && profiles.length === contacts.length) {
                profiles_result.resolve(profiles);
            } else if (contacts && contacts.length) {
                App.profiles.get_profile(contacts, false).then(function (profiles) {
                    profiles = profiles.filter(function (p) {
                        // Filter out the profiles that have the privacy enambled
                        return (typeof p.account_flags === "undefined" || p.account_flags.indexOf('user_privacy_mode_enabled') === -1) ? true : false;
                    });
                    profiles_result.resolve(profiles);
                });
            } else {
                return;
            }

            profiles_result.done(function (profiles) {
                that.full_list_friends.collection.reset(profiles);
                that.full_list_friends.collection.comparator = function (a, b) {
                    return (a.get('is_contact') === b.get('is_contact')) ? (a.get('first').toLowerCase() > b.get('first').toLowerCase() ? 1 : -1) : a.get('is_contact') ? -1 : 1;
                }
                that.full_list_friends.collection.sort();
                that.full_list_friends.render();
                that.$el.find('#all-contacts').modal();
                $('.modal-backdrop').addClass('hide');
            });
        },
        handle_show_friend: function (e) {
            var user_id = e.currentTarget.getAttribute('user-id'),
                user_name = e.currentTarget.getAttribute('user-name');

            if (user_id !== this.model.get('user_id')) {
                App.Router.navigate("/u/" + user_name, { trigger: true });
            } else {
                this.handle_close_all_contacts();
            }
        },
        handle_add_friends_friend: function (e) {
            e.preventDefault();
            e.stopPropagation();
            var currentTarget = $(e.currentTarget),
                user_id = currentTarget.attr('user-id'),
                first  = currentTarget.parent().find('.name').text().split(' ')[0];

            App.profiles.add_contact(user_id, {
                success: function () {
                    currentTarget.parent().append('<span class="mutualfriend">' +
                                                  first + ' is mutual friend</span>');
                    currentTarget.remove();
                },
                error: function () {
                }
            });
        },
        handle_close_all_contacts: function (e) {
            this.$el.find('#all-contacts').modal('hide');
        },
        handle_start_chat: function (e) {
            e.preventDefault();
            // need to require chat module here since it won't load with the initial require for circular reference reasons
            var Chat = require('modules/chat');

            var that = this,
                party = [this.model.get('user_id'), App.Auth.get('rebelvox_user_id')],
                thread_id = generate_thread_id(party),
                chat, $new_chat_title = this.$('.new-chat-title');

            if (this.model.get('is_team')) {
                if ($new_chat_title && $new_chat_title.val().replace(/ /g, '') === "") {
                    // set error state on the field (by adding class to parent)
                    this.$('.new-chat-title').val('').parent().addClass('has-error');
                    this.$('.new-chat-title').one('keyup', function () {
                        $(this).parent().removeClass('has-error');
                    });
                    return;
                }
            }

            // check if we have a chat already, then just activate
            if (App.chats.pluck('thread_id').indexOf(thread_id) !== -1 && (this.model.get('is_contact') || this.model.get('is_team'))) {
                // activate the "vox" tab and show the new chat panel
                App.Router.navigate('/chats/' + thread_id, {trigger: true});
            }
            else {
                if (this.model.get('is_contact') || this.model.get('is_team')) {
                    // start thread
                    if (this.model.get('is_team')) {
                        chat = new Chat.Model({
                            groups: [that.model.get('user_id')],
                            title: $new_chat_title.val(),
                            subject: $new_chat_title.val()
                        });
                    }
                    else {
                        chat = new Chat.Model({
                            recipients: [that.model.get('user_id')],
                            title: that.model.get('name')
                        });
                    }
                    // add thread to chatslist
                    chat.generate_thread_id();
                    if (this.model.get('is_team')) {
                        chat.save();
                    }
                    App.chats.add(chat);
                    // activate the "vox" tab and show the new chat panel
                    App.Router.navigate('/chats/' + chat.get('thread_id'), {trigger: true});

                } else {
                    App.profiles.add_contact(that.model.get('user_id'), {
                        success: function () {
                            that.model.set({'is_contact': true});
                            that.render();

                            // Cache the profile
                            App.vent.trigger('post_message', {
                                profiles: [that.model.attributes],
                                content_type: "public_profiles",
                                deleted: false
                            });
                        },
                        error: function () {
                            //debugger;
                        }
                    });
                }
            }

            App.vent.trigger('metrics:track', 'v4w/open_chat', {
                source: 'contacts'
            });
        },
        handle_add_contact: function (e) {
            var changes = {},
                that = this,
                event = e;
            changes[this.model.get('user_id')] = {
                remove: ["deleted"]
            }

            App.API.change_contacts_mods({
                changes: changes
            }, {
                home_router: Settings.fetch('home_router')
            }, function(e) {
                console.error(e);
            }, function(response) {
                if (response.args && response.args.success) {
                    var tags = that.model.get('tags') || [],
                        account_flags = that.model.get('account_flags') || [];
                    tags = _.without(tags, "deleted");
                    account_flags = _.without(account_flags, "deleted");
                    that.model.set({tags: tags, account_flags: account_flags})
                    App.profiles.add(that.model.set());

                    // Cache the profile
                    var post_message = {
                        content_type: "contacts",
                        create_time: Date.now(),
                        contacts: {},
                        message_id: App.API._generate_message_id(),
                        update: true,
                    };
                    post_message.contacts[that.model.get('user_id')] = {
                        tags: tags,
                        account_flags: account_flags
                    };
                    App.vent.trigger('post_message', post_message);
                    App.vent.trigger('contacts:updated');
                    that.render();
                }
            });
        },
        handle_delete_contact: function (e) {
            var changes = {},
                that = this,
                event = e;
            changes[this.model.get('user_id')] = {
                add: ["deleted"]
            }

            App.API.change_contacts_mods({
                changes: changes
            }, {
                home_router: Settings.fetch('home_router')
            }, function(e) {
                console.error(e);
            }, function(response) {
                if (response.args && response.args.success) {
                    // Cache the profile
                    var post_message = {
                        content_type: "contacts",
                        create_time: Date.now(),
                        contacts: {},
                        message_id: App.API._generate_message_id(),
                        update: true,
                    };
                    post_message.contacts[that.model.get('user_id')] = {
                        account_flags: ["deleted"]
                    };
                    App.vent.trigger('post_message', post_message);
                    tags = that.model.get('tags') || [];
                    tags.push('deleted');
                    that.model.set({tags: tags});
                    App.profiles.add(that.model.set());
                    App.vent.trigger('contacts:updated');
                    that.render();
                }
            });
        },
        handle_toggle: function (e) {
            e.preventDefault();
            $(e.currentTarget).toggleClass('hide');
            this.$('.toggle-hide').toggleClass('hide');
            this.$('.toggle-hide input').focus();
        },
        handle_route_contact: function (e) {
            e.preventDefault();
            // hack awayyyyyy!!
            var id = $(e.currentTarget).attr('id').replace('c-', '');
            if (App.profiles.get(id).attributes.is_team) {
                $('.contact-selector ul li:last a').trigger('click');
            }
            else {
                $('.contact-selector ul li:first a').trigger('click');
            }
            Settings.set({'active-contact': id});
            App.Router.navigate('/contact/' + id, {trigger: true});
        },
        onRender: function(){
            var that  =  this;
            if (!this.friends_list && this.random_friends) {
                this.friends_list = new Contact.Views.FriendsList({
                    collection: new Backbone.Collection(this.random_friends),
                    parent: this.$el.find('.friends-list')
                });

                if(this.friends_list.collection.length <= 5){
                    this.$el.find('.more-friends').addClass('hide');
                }

                this.friends_list.render();

            } else if (this.friends_list && this.random_friends) {
                this.friends_list.options.parent = this.$el.find('.friends-list');
                this.friends_list.onRender();
            }

            this.full_list_friends = new Contact.Views.FriendsList({
                collection: new Backbone.Collection([]),
                parent: this.$el.find('.full-friends-list')
            });

            this.$el.find('[data-toggle="tooltip"]').tooltip();

            this.$el.find('#all-contacts').on('show.bs.modal', function(){
                that.$el.css('overflow', 'hidden')
            });

            this.$el.find('#all-contacts').on('hidden.bs.modal', function(){
                that.$el.css('overflow', 'auto')
            });


            if( !this.model.get('is_team') ){
                Contact.Views.MyProfile.prototype.show_timeline.call(this);
            }
        }
    });

    Contact.Views.NoItemsView = M.ItemView.extend({
        template: "contact/no-item-found",
        tagName: 'li',
        className: 'contact-item empty'
    });

    Contact.Views.NoTeamsView = M.ItemView.extend({
        template: "contact/no-teams-found",
        tagName: 'li',
        className: 'contact-item empty'
    });

    Contact.Views.NoContactsView = M.ItemView.extend({
        template: "contact/no-contacts-found",
        tagName: 'li',
        className: 'contact-item empty'
    });


    //todo redo all this so it uses either city or location, not both.
    Contact.Views.MyProfile = M.LayoutView.extend({
        template: 'contact/panel/my_profile',
        className: function () {
            return 'contact-panel panel panel-default my-profile';
        },
        id: function () {
            return 'my-profile';
        },
        events: {
            "click button.vox-me": "handle_start_chat",
            "click button.update-btn": "handle_update_profile",
            "click button.share-my-profile" : "show_share_profile_modal",
            "click button.edit-profile" : "show_edit_profile_modal",
            "click button.embed-profile" : "show_embed_profile_modal",
            "click .share-profile" : "handle_share_profile",
            "keyup input[name='username']": "handle_username_check",
            "keyup input[type='text']": "handle_change_check",
            "click .search-privacy .btn-group": "handle_change_check",
            "click .public-contacts .btn-group": "handle_change_check",
            "click .more-friends": "hanlde_show_all_friends",
            "click .contact-info": "handle_show_friend",
            "click #all-contacts .modal-header": "handle_close_all_contacts"
        },
        regions: {
            timeline: '#timeline-container'
        },
        onDestroy:function () {
            this.$el.find('.modal').modal('hide');
        },
        initialize: function () {
            var sponsor_id = App.Auth.get('capabilities') ? App.Auth.get('capabilities').sponsor_id : false;
            if (sponsor_id) {
                var that = this,
                    profile = App.profiles.get(sponsor_id);
                if (profile) {
                    this.sponsor_name = profile.name();
                } else {
                    App.profiles.get_profile(sponsor_id).then(function () {
                        profile = App.profiles.get(sponsor_id);
                        that.sponsor_name = profile.name();
                        that.render();
                    });
                }
            }

            this.usernameChecked = false;
            this.model.on('change', function (model) {
                var changed = model.changedAttributes() || {};
                if (!this.isDestroyed && !changed.avatar_busted) {
                    this.render();
                }
            }, this);
            this.random_friends = _.shuffle(App.profiles.filter(function (p) {
                // Filter out the profiles that have the privacy enambled and do not show own profile - also do not show deleted contacts
                return p.get('is_contact') &&
                       p.get('user_id') != App.Auth.get('user_id') &&
                       (typeof p.get('tags') === "undefined" || p.get('tags').indexOf('deleted') === -1) &&
                       (typeof p.get('account_flags') === "undefined" || p.get('account_flags').indexOf('user_privacy_mode_enabled') === -1);
            })).slice(0, 10);

            //Filter out own user and deleted contacts
            this.all_friends_list = _.shuffle(App.profiles.filter(function (p) {
                return p.get('is_contact') &&
                       p.get('user_id') != App.Auth.get('user_id') &&
                       (typeof p.get('tags') === "undefined" || p.get('tags').indexOf('deleted') === -1);
            }));

        },
        onRender: function () {
            var that = this;
            App.vent.trigger('metrics:track', '/profile');
            var input = that.$('input[type="file"]');
            input[0].addEventListener('change', function(event){
                App.profileImageUploadHandler(event, function() {
                    that.$el.find('.avatar-holder img').attr("src", "/assets/img/ajax-loader-medium.gif");
                }, function(data) {

                    var results = {};
                    try {
                        results = JSON.parse(data);
                    } catch (e) {

                    }
                    that.$el.find("#edit-profile").modal('hide');

                    that.model.set({'avatar_bust': true});
                    App.vent.trigger('avatar_bust', true);

                    that.model.unset('canvas_image');

                    that.$el.find('.avatar-holder img').attr("src", that.model.avatar_src());
                    that.$el.find('.row.contact-profile').css('background-image', 'url(' + that.model.avatar_src() + ')');
                    // metrics
                    App.vent.trigger('metrics:track', '/profile/picture');

                });
            }, false);

            delete this.friends_list;
            this.friends_list = new Contact.Views.FriendsList({
                collection: new Backbone.Collection(that.random_friends),
                parent: that.$el.find('.friends-list')
            });
            this.friends_list.render();

            if(this.friends_list.collection.length <= 5){
                this.$el.find('.more-friends').addClass('hide');
            }

            delete this.full_list_friends;
            this.full_list_friends = new Contact.Views.FriendsList({
                collection: new Backbone.Collection(that.all_friends_list),
                parent: that.$el.find('.full-friends-list')
            });

            this.$el.find('[data-toggle="tooltip"]').tooltip();

            this.$el.find('#all-contacts').on('show.bs.modal', function(){
                that.$el.css('overflow', 'hidden')
            });

            this.$el.find('#all-contacts').on('hidden.bs.modal', function(){
                that.$el.css('overflow', 'auto')
            });

            this.show_timeline();
        },
        show_timeline: function () {
            var that = this,
                user_id = this.model.get('user_id') || App.Auth.get('user_id'),
                thread_id = "PC_" + user_id + "_" + user_id,
                deferred = new $.Deferred(),
                ReverseMessages;

            if (!App.chat || !App.Auth.authenticated())
                return;

            ReverseMessages = App.chat.Messages.extend({
                comparator: function (m) {
                    return -right_time(m.attributes);
                }
            });

            if (user_id === App.Auth.get('user_id') && !App.chats.get("PC_" + user_id + "_" + user_id)) {
                App.API.thread_details("PC_" + user_id + "_" + user_id, {
                    home_router: App.Settings.get('home_router')
                },
                function(data, xhr) {
                    if (xhr.status === 404) {
                        App.API._post_message({
                            thread_id: thread_id,
                            create_time: (new Date()).getTime() / 1000,
                            recipients: [user_id],
                            subject: "My timeline",
                        }, {
                            url: '2/cs/start_thread',
                            home_router: App.Settings.get('home_router')
                        }, function (err) {
                            console.error(err);
                            deferred.reject(err);
                        }, function (data) {
                            deferred.resolve(data);
                        });
                    }
                }, function(data) {
                    deferred.resolve(data);
                })
            } else {
                deferred.resolve();
            }
            deferred.then(function() {
                var timeline_model = App.chats.get("PC_" + user_id + "_" + user_id),
                    models = [];
                if (!timeline_model) {
                    timeline_model = new App.chat.Model({
                        title: "My timeline",
                        subject: "My timeline",
                        thread_id: thread_id
                    });
                    App.chats.add(timeline_model);
                } else {
                    models = timeline_model.get('messages').models;
                }

                timeline_model.set({
                    messages: new ReverseMessages()
                });

                var messages = timeline_model.get('messages');
                models.forEach(function (m) {
                    messages.add(m, {silent: true});
                });

                if (!this.timeline_view || this.timeline_view.isDestroyed) {
                    this.timeline_view = new App.chat.Layouts.TimelinePanel({
                        model: timeline_model,
                        id: "timeline-" + timeline_model.cid,
                        user_id: user_id
                    });
                }

                if (this.timeline_view && !this.timeline_view.isDestroyed)
                    this.timeline.show(this.timeline_view);

                var onMessages = function(data) {
                    var likes = [];
                    if (that.timeline_view.isDestroyed)
                        return;


                    if (data && data.length) {
                        data = _.filter(data, function (m) {
                            if (m.content_type === "like_receipt") {
                                likes.push(m);
                            }

                            return m.content_type !== "read_receipt" && m.content_type !== "like_receipt";
                        });

                        messages.add(data, {silent: true});
                    }

                    if (likes.length) {
                        likes.forEach(function(like) {
                            _.forEach(like["like_times"], function (prop, key) {
                                var m = messages.get(key);
                                if (m) {
                                    like_times = m.get('like_times') || {};
                                    length = Object.keys(like_times).length;

                                    if (like.like) {
                                        like_times[like.from] = prop;
                                    } else {
                                        if (like_times[like.from] && like_times[like.from] < prop) {
                                            delete like_times[like.from];
                                        }
                                    }

                                    m.set({ 'like_times': like_times });
                                    if (length !== Object.keys(like_times).length) {
                                        m.trigger('change:like_times', 'like_times');
                                    }
                                }
                            });
                        });

                        delete likes;
                    }
                    var messagesView = new App.chat.Views.Messages({
                        collection: messages
                    });
                    that.timeline_view.messages.show(messagesView);
                };

                if (messages.models.length) {
                    onMessages();
                    // load the chat's timeline, then render
                    messages.timeline({
                        chat: timeline_model,
                        success: onMessages,
                        remote: true
                    });
                } else {
                    // load the chat's timeline, then render
                    messages.timeline({
                        chat: timeline_model,
                        success: onMessages,
                        remote: true
                    });
                }
            }.bind(this));
        },
        serializeData: function () {
            var model = App.MyProfile,
                member_of_teams = [],
                data, teams_text = '',
                is_pro = false;

            member_of_teams = _.compact(App.profiles.map(function (team) {
                if (team.get('is_team')) {
                    if (team.get('members').indexOf(App.Auth.get('user_id')) !== -1) {
                        return team;
                    }
                }
            }));

            if (member_of_teams.length > 0) {
                _.each(member_of_teams, function (team) {
                    teams_text += '<a href="/contact/'  + team.get('user_id') + '" id="c-' + team.get('user_id') + '" class="contact-link">' + team.get('name') + '</a>, ';
                });
                teams_text = teams_text.slice(0, -2);
            }
            else {
                teams_text = 'Not member of any teams';
            }

            try {
                is_pro = App.Auth.get('capabilities') && Object.keys(App.Auth.get('capabilities').capability_keys).length;
            } catch (err) {
                console.log(err);
            }

            return {
                sponsor_name: this.sponsor_name || false,
                avatar_src: model.avatar_src(),
                first: model.get("first"),
                last: model.get("last"),
                username: model.get("username"),
                email: model.get('email'),
                location: model.get("city"),
                content: teams_text,
                is_pro : is_pro,
                fullname: model.get('display_name'),
                profileLink: (window.location.origin +'/u/'+ this.model.get("username")).toString(),
                status_message: model.get('status_message') || "Send me a message",
                user_privacy_mode_enabled: (typeof model.get('account_flags') !== "undefined" && model.get('account_flags').indexOf('user_privacy_mode_enabled') !== -1) ? true : false,
                user_show_contacts: (typeof model.get('account_flags') === "undefined" || model.get('account_flags').indexOf('user_show_contacts_blocked') === -1) ? true : false
            };
        },
        handle_change_check: _.debounce(function () {
            var username = this.$el.find('input[name="username"]').val(),
                email = this.$el.find('input[name="email"]').val(),
                first = this.$el.find('input[name="first"]').val(),
                last = this.$el.find('input[name="last"]').val(),
                city = this.$el.find('input[name="city"]').val(),
                status_message = this.$el.find('input[name="status_message"]').val(),
                user_privacy_mode_enabled = this.$el.find('.search-privacy .btn-group .active [type="radio"]').data(),
                user_show_contacts = this.$el.find('.public-contacts .btn-group .active [type="radio"]').data(),
                model_user_show_contacts = (typeof this.model.get('account_flags') === "undefined" || this.model.get('account_flags').indexOf('user_show_contacts_blocked') === -1) ? true : false,
                model_user_privacy_mode_enabled = (typeof this.model.get('account_flags') !== "undefined" && this.model.get('account_flags').indexOf('user_privacy_mode_enabled') !== -1) ? true : false,
                email_regex = new RegExp("[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?"),
                $message = this.$el.find('.alert-danger.message');

            $message.html('').hide();

            if (!email || !email.match(email_regex)) {
                $message.append('Please enter a valid email address <br/>').show();
            }
            if (username.length > 25) {
                $message.append('Username can\'t be longer than 25 characters! <br/>').show();
            }
            if (first.length > 50) {
                $message.append('First name can\'t be longer than 50 characters! <br/>').show();
            }
            if (last.length > 50) {
                $message.append('Last name can\'t be longer than 50 characters! <br/>').show();
            }
            if (city.length > 100) {
                $message.append('Location can\'t be longer than 100 characters! <br/>').show();
            }

            if ($message.html().length) {
                this.$el.find('.update-btn').attr('disabled', 'disabled');
            } else if (this.model.get('first') !== first ||
                this.model.get('last') !== last ||
                this.model.get('city') !== city  ||
                this.model.get('status_message') !==  status_message ||
                this.model.get('email') !== email ||
                model_user_privacy_mode_enabled !== user_privacy_mode_enabled.value ||
                model_user_show_contacts !== user_show_contacts.value) {
                this.$el.find('.update-btn').removeAttr('disabled');
            } else  {
                this.$el.find('.update-btn').attr('disabled', 'disabled');
            }

        }, 250),
        handle_start_chat: function (e) {
            e.preventDefault();
            var that = this,
                me = App.Auth.get('rebelvox_user_id'),
                thread_id = generate_thread_id([me, me]);

            // does hotline exist already ?
            if (App.chats.pluck('thread_id').indexOf(thread_id) !== -1) {
                // activate the "vox" tab and show the new chat panel
                App.Router.navigate('/chats/' + thread_id, {trigger: true});
            }
            else {
                // start new thread
                var chat = new App.chat.Model({
                    recipients: [me],
                    title: "Note to Self",
                    subject: "Note to Self"
                });

                // add thread to chatslist
                chat.generate_thread_id();
                App.chats.add(chat);

                // activate the "vox" tab and show the new chat panel
                App.Router.navigate('/chats/' + chat.get('thread_id'), {trigger: true});
            }

            App.vent.trigger('metrics:track', '/profile/notetoself');
        },
        handle_username_check: _.debounce(function(e) {
            e.preventDefault();

            if (!e.target.value) {
                return;
            }

            var that = this,
                $message = this.$el.find('.alert-danger.message');

            App.API.is_username_available({
                user_id: App.Auth.get('user_id'),
                username: e.target.value
            },
            {
                router: Settings.fetch('home_router')
            },
            function (err) {
                $message.html(err.error).show();
                that.usernameChecked = false;
            }, function (data) {
                if(data.available) {
                    that.usernameChecked = true;
                    that.$el.find('.update-btn').removeAttr('disabled');
                }
                $message.hide();
            });
        }, 500),
        handle_update_profile: function (e) {
            e.preventDefault();
            // TODO: set the button to "...saving"

            var model = this.model, my_id = App.Auth.get('rebelvox_user_id'),
                form = this.$('form'), data = JSON.stringify(this.$('form').serializeArray()),
                $message = this.$el.find('.alert-danger.message'),
                username = form.find('input[name=username]').val(),
                user_privacy_mode_enabled = this.$el.find('.search-privacy .btn-group .active [type="radio"]').data().value,
                user_public_contacts = this.$el.find('.public-contacts .btn-group .active [type="radio"]').data().value,
                account_flags = this.model.get('account_flags') || [],
                privacy_mode_index = account_flags.indexOf('user_privacy_mode_enabled'),
                show_contacts_blocked_index = account_flags.indexOf('user_show_contacts_blocked'),
                that = this,
                username_promise = $.Deferred(),
                mods_changed_promise = $.Deferred(),
                updateprofile_promise = $.Deferred();

            data = {
                "content_type"  : "profile",
                "from"          : my_id,   // me
                "to"            : [my_id],
                'profile' : {
                    //device_info: this.mode.get('device_info'),
                    //dialing_code: ""
                    display_name: form.find('input[name=first]').val() + " " + form.find('input[name=last]').val(),
                    email: form.find('input[name=email]').val(),
                    first: form.find('input[name=first]').val(),
                    last: form.find('input[name=last]').val(),
                    city: form.find('input[name=city]').val(),
                    status_message: form.find('input[name=status_message]').val()
                }
            };

            if (App.MyProfile.get('username') !== username && that.usernameChecked) {
                // Spwan an new non-blocking task so we can continue with others profiles fields
                setTimeout(function() {
                    App.API.change_username({
                        user_id: App.Auth.get('user_id'),
                        username: username
                    }, {
                        router: Settings.fetch('home_router')
                    }, function (err) {
                        username_promise.resolve({error: true, message: "Error updating username"});
                    }, function (data) {
                        App.Auth.set({username: username});
                        App.MyProfile.set({username: username}, {silent: true});
                        that.render();
                        username_promise.resolve(data);
                    });
                }, 0);
            } else {
                username_promise.resolve();
            }

            if ((user_privacy_mode_enabled && privacy_mode_index === -1) ||
                (!user_privacy_mode_enabled && privacy_mode_index !== -1) ||
                (!user_public_contacts && show_contacts_blocked_index === -1) ||
                (user_public_contacts && show_contacts_blocked_index !== -1)) {
                var changes = {};
                changes[App.Auth.get('rebelvox_user_id')] = {
                    add: [],
                    remove: []
                };

                if (user_privacy_mode_enabled && privacy_mode_index === -1) {
                    account_flags.push('user_privacy_mode_enabled');
                    changes[App.Auth.get('rebelvox_user_id')].add.push("user_privacy_mode_enabled");
                } else if (!user_privacy_mode_enabled && privacy_mode_index !== -1) {
                    account_flags.splice(privacy_mode_index, 1);
                    changes[App.Auth.get('rebelvox_user_id')].remove.push("user_privacy_mode_enabled");
                }

                if (!user_public_contacts && show_contacts_blocked_index === -1) {
                    account_flags.push('user_show_contacts_blocked');
                    changes[App.Auth.get('rebelvox_user_id')].add.push("user_show_contacts_blocked");
                } else if (user_public_contacts && show_contacts_blocked_index !== -1) {
                    account_flags.splice(show_contacts_blocked_index, 1);
                    changes[App.Auth.get('rebelvox_user_id')].remove.push("user_show_contacts_blocked");
                }


                App.API._post_message({
                    changes: changes
                }, {
                    url: '2/cs/mod_account_flags',
                    home_router: Settings.fetch('home_router')
                }, function(e) {
                    mods_changed_promise.resolve({error: true, message: e});
                }, function(response) {
                    App.MyProfile.set({account_flags: account_flags}, {silent: true});
                    mods_changed_promise.resolve(response);
                });
            } else {
                mods_changed_promise.resolve();
            }

            if (data.profile.first.replace(/\s+/g, '').length === 0) {
                $message.html("Enter a First Name").show();
                return;
            } else if (data.profile.last.replace(/\s+/g, '').length === 0) {
                $message.html("Enter a Last Name").show();
                return;
            }

            var changed = false;
            _.without(Object.keys(data.profile), 'status_message').forEach(function (key) {
                if (model.get(key) !== data.profile[key]) {
                    changed = true;
                }
            });

            if (!changed) {
                that.render();
                $('.modal-backdrop.in').remove();
                return;
            }

            $(e.currentTarget).button('loading');
            $message.hide();

            App.API.update_profile({
                email: data.profile.email,
                first: data.profile.first,
                last: data.profile.last,
                more: {
                    city: data.profile.city,
                    status_message: data.profile.status_message
                }
            }, {
                router: "https://" + App.Auth.get('home_router').address
            }, function (value) {
                updateprofile_promise.resolve({error: true, message: value.error});
            }, function (value) {
                data.profile.location = data.profile.city;
                data.profile.status_message = data.profile.status_message;

                // If name changed let's delete the cached initials image so we can recreate it.
                if (that.model.get('first') !== data.profile.first ||
                    that.model.get('last') !== data.profile.last) {
                    Cache.delete('profile_avatar_' + model.get('user_id'));
                    App.MyProfile.set(data.profile);
                    App.vent.trigger('avatar_bust', true);
                } else {
                    App.MyProfile.set(data.profile);
                    $(e.currentTarget).button('complete');
                    setTimeout(function () {
                        $(e.currentTarget).button('reset');
                    }, 3000);
                }
                App.vent.trigger('metrics:track', '/profile/update');
                updateprofile_promise.resolve(value);
            });

            $.when(updateprofile_promise, mods_changed_promise, username_promise)
             .then(function (updateprofile, mods_changed, username) {
                var error_message = (updateprofile && updateprofile.error && updateprofile.message) ||
                    (mods_changed && mods_changed.error && mods_changed.message) ||
                    (username && username.error && username.message);
                if (error_message) {
                    $message.html(error_message).show();
                    $(e.currentTarget).button('reset');
                } else {
                    that.render();
                    $('.modal-backdrop.in').remove();
                }
            });
        },
        show_share_profile_modal: function(e) {
            this.$el.find("#share-profile").modal();
        },
        show_edit_profile_modal: function(e) {
            this.$el.find("#edit-profile").modal();
        },
        handle_share_profile: function(e) {
            e.preventDefault();
            var target = $(e.target).attr('data-target')
                    || $(e.target).parent().attr('data-target'),
                that = this, sharing_url;

            if (!target) {
                return;
            }

            const _url = location.origin || "https://web.voxer.com"

            sharing_url = _url + "/u/" + App.MyProfile.get('username');

            switch (target) {
            case "email":
                window.location.href = 'mailto:?subject=My Voxer Profile'+
                    '&body=Vox me on Voxer! ' + sharing_url;
                App.vent.trigger('metrics', '/share_profile', {"type": "self", "method": "email"});
                break;
            case "link":
                var modal = new UI.Views.ShareProfileLinkModal({
                    headline: 'Copy the link to share',
                    callback: function () {
                    },
                    info_text: sharing_url
                });
                $("#share-profile").modal("hide");
                App.vent.trigger('metrics', '/share_profile', {"type": "self", "method": "link"});
                App.layout.regionIntro.show(modal);
                break;
            case "facebook":
                FB.ui({
                    method: 'feed',
                    link : sharing_url,
                    caption: "Vox me on Voxer! " + sharing_url
                });
                App.vent.trigger('metrics', '/share_profile', {"type": "self", "method": "facebook"});
                break;
            case "twitter":
                var popup = window.open("https://twitter.com/share?url=" + sharing_url +
                    "&text=Vox me on Voxer!" +
                    "&hashtags=Voxer",
                    "_blank", "toolbar=yes, scrollbars=yes, resizable=yes, top=500, left=500, width=500, height=550");
                App.vent.trigger('metrics', '/share_profile', {"type": "self", "method": "twitter"});
                break;
            default:
               console.log(target);
            }
        },
        show_embed_profile_modal: function(e) {
            $("#embed-profile").modal();
            $("#embed-profile").on('shown.bs.modal', function () {
                $('textarea').focus().select();
            });
        },
        hanlde_show_all_friends:function(e){
            this.$el.find('#all-contacts').modal();
            $('.modal-backdrop').addClass('hide');
            var that = this;
            this.full_list_friends.render();
        },
        handle_close_all_contacts: function (e) {
            this.$el.find('#all-contacts').modal('hide');
        },
        handle_show_friend: function (e) {
            var user_id = e.currentTarget.getAttribute('user-id'),
                user_name = e.currentTarget.getAttribute('user-name');

            if (user_id !== this.model.get('user_id')) {
                App.Router.navigate("/u/" + user_name, { trigger: true });
            } else {
                this.handle_close_all_contacts();
            }
        },
    });


    Contact.Views.Picker = M.CompositeView.extend({
        template: "contact/picker",
        childView: Contact.Views.Item,
        emptyView: Contact.Views.NoItemsView,
        initialize: function () {
            this.$('img.lazy').lazyload({
                container: this.$el.closest('.panel-body'),
                threshold: 200,
                effect: "fadeIn"
            });
        }
    });

    Contact.Views.ShallowList = new M.ItemView({
        template: 'contact/shallow_list',
        serializeData: function () {
            return {
                collection: this.collection
            };
        }
    });

    return Contact;
});

