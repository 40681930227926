/*
 * A basic Cache
 */
define('main/cache',[
    // Libraries.
    'jquery',
    'underscore',
    'backbone',

    // Plugins.
    'libs/sha512',
    'plugins/jquery.cookie'
],
function ($, _, Backbone) {
    'use strict';

    var Cache = Backbone.Model.extend({
        defaults: function () {
            return {
                available: false,
                blocked: false,
                localStorage: false
            };
        },
        initialize : function () {
            var that = this;
            this.set({
                available: true,
                localStorage: this.supports_local_storage()
            });

            $.cookie.defaults.json = true;
            $.cookie.defaults.security = true;

            window.addEventListener("beforeunload", function () {
                if (App.VoxerAudio.recording) {
                    App.VoxerAudio.stop_recording();
                }
                if (that && that.get('localStorage') === true &&
                        !that.get('blocked') && App.Settings.fetch('cache-timeline') && App.Settings.get('first_render')) {
                    try {
                        var threads = {},
                            profile = {
                                content_type: "profile",
                                profile: App.MyProfile.attributes
                            },
                            user_settings = {
                                content_type: "user_settings",
                                user_settings: App.Settings.get("user_settings")
                            },
                            contacts = _.extend(App.container, {
                                content_type: "contacts"
                            }),
                            profiles_meta = {
                                content_type: "public_profiles",
                                profiles: App.profiles.map(function (msg) {
                                    if (!msg.attributes.image_id && !msg.attributes.image_url)
                                        delete msg.attributes.avatar_url;

                                    delete msg.attributes.contacts;
                                    delete msg.attributes.profiles;

                                    return msg.attributes;
                                })
                            },
                            threads_meta = App.chats.map(function (msg) {
                                msg = _.extend(msg.attributes, {
                                    content_type: "thread_meta"
                                });
                                if (msg.unread_count && msg.unread_count > 0) {
                                    msg.messages.each(function (msg) {
                                        msg.set({ forced_cache: true });
                                    });
                                }
                                threads[msg.thread_id] = msg.messages;
                                if (!msg.create_time && !msg.last_modified_time && msg.last_message) {
                                    msg.last_modified_time = right_time(msg.last_message);
                                }

                                delete msg.messages;
                                delete msg.is_pressed;
                                delete msg.should_be_sending;
                                delete msg.receiving;
                                delete msg.popout_chat;

                                return msg;
                            }),
                            tmp = [];
                        try {
                            profile.profile.user_id = App.Auth.get('rebelvox_user_id');
                        } catch (err) {
                            console.error(err);
                        }
                        profiles_meta.profiles.unshift(profile);

                        tmp.push(profile);
                        tmp.push(user_settings);
                        tmp.push(contacts);
                        tmp.push(profiles_meta);

                        tmp = _.union(tmp, threads_meta);


                        Object.keys(threads).forEach(function(thread_id) {
                            if (!threads[thread_id] || !threads[thread_id].length)
                                return;

                            var messages = threads[thread_id].map(function(msg) {
                                msg = msg.attributes;

                                delete msg.playback_rate;

                                if (!msg.live_audio) {
                                    msg.update = false;
                                }

                                if (msg.forced_cache) {
                                    delete msg.forced_cache;
                                    tmp.push(msg);
                                }

                                return JSON.stringify(msg);
                            });

                            // Cache maximum 10 messages
                            if (messages.length > 10)
                                messages = messages.splice(-10, messages.length - 1);

                            if (messages.length)
                                localStorage.setItem(
                                    'timeline_' + App.Auth.get('user_id') + thread_id,
                                    messages.join("\r\n")
                                );
                        });

                        tmp = tmp.map(function (el, index) {
                            el.update = false;

                            return JSON.stringify(el);
                        });


                        if (tmp.length > 2500)
                            throw new Error('To many cached messages');

                        localStorage.setItem('timeline_' + App.Auth.get('user_id'), tmp.join("\r\n"));
                    } catch (err) {
                        if (that.isQuotaExceeded(err)) {
                            that.set({blocked: true});
                            localStorage.clear();
                        }
                    }
                } else if (that && App.Auth.get('user_id')) {
                    that.delete(App.Auth.get('user_id'));
                }
            }.bind(this));
        },
        fetch : function (key) {
            var tmp = null;

            if (!key) {
                return null;
            }

            if (this.get('localStorage') === true) {
                //Allowing cookies, use localStorage
                tmp = localStorage[key];
                if (tmp !== undefined) {
                    tmp = JSON.parse(tmp);
                }
            } else {
                //Use cookies cause im bad
                tmp = $.cookie(key);
                if (tmp !== undefined) {
                    tmp = JSON.parse(tmp);
                }
            }
            if (tmp === "true") {
                tmp = true;
            } else if (tmp === "false") {
                tmp = false;
            }
            return tmp;
        },
        fetch_raw : function (key) {

            if (!key) {
                return null;
            }

            if (this.get('localStorage') === true) {
                return localStorage[key];
            } else {
                return false;
            }
        },
        sync : function (key, data) {

            if (typeof key === "undefined" || typeof data === "undefined") {
                return null;
            }

            if (this.get('localStorage') === true) {
                try {
                    localStorage.setItem(key, JSON.stringify(data));
                } catch (e) {
                    if (this.isQuotaExceeded(e)) {
                        this.set({blocked: true});
                        localStorage.clear();
                    }
                }
                this.set(key, data);
            } else {
                $.cookie(key, JSON.stringify(data));
                this.set(key, data);
            }
        },
        fetch_timeline : function(user_id) {            
            var tmp = null;

            if (user_id) {                
                tmp = localStorage['timeline_' + user_id];
            }

            if (!tmp || !App.Settings.fetch('cache-timeline')) {
                return null;
            } else {
                tmp = tmp.split("\r\n");
                tmp = _.map(tmp, function (message) {
                    return JSON.parse(message);
                });

                return tmp;
            }
        },
        fetch_chats : function(user_id, thread_id) {
            var tmp = null;

            if (user_id) {
                tmp = localStorage['timeline_' + user_id + thread_id];
            }

            if (!tmp || !App.Settings.fetch('cache-timeline')) {
                return null;
            } else {
                tmp = tmp.split("\r\n");
                tmp = _.map(tmp, function (message) {
                    return JSON.parse(message);
                });

                return tmp;
            }
        },
        sync_raw : function (key, data) {
            var that = this;

            if (typeof key === "undefined" || typeof data === "undefined") {
                return null;
            }

            if (this.get('localStorage') === true && !that.get('blocked')) {
                try {
                    localStorage.setItem(key, data);
                } catch (e) {
                    if (that.isQuotaExceeded(e)) {
                        that.set({blocked: true});
                        localStorage.clear();
                    }
                }
                this.set(key, data);
            }
        },
        delete : function (key) {
            //todo accept arrays

            if (!key) {
                return null;
            }

            if (this.get('localStorage') === true) {
                return localStorage.removeItem(key);
            } else {
                return $.removeCookie(key, '');
            }
            this.unset(key);
        },
        destroy: function () {
            if (this.get('localStorage') === true) {
                localStorage.clear();
            } else {
                var cookies = document.cookie.split(";");
                for (var i = 0; i < cookies.length; i++) {
                    this.delete(cookies[i].split("=")[0]);
                }
            }
            this.clear();
        },
        all : function () {
            var values, i, value, val;
            if (this.get('localStorage') === true) {
                for (i in window.localStorage) {
                    val = localStorage.getItem(i);
                    value = val.split(",");
                    values[i] = value[1];
                }
            } else {
                var pairs = document.cookie.split(";");
                var cookies = {};
                for (i = 0; i < pairs.length; i++) {
                    var pair = pairs[i].split("=");
                    cookies[pair[0]] = unescape(pair[1]);
                }
                values = cookies;
            }
            return values;
        },
        supports_local_storage: function () {
            try {
                return 'localStorage' in window && window['localStorage'] !== null;
            } catch (e) {
                return false;
            }
        },
        isQuotaExceeded : function (e) {
          var quotaExceeded = false;

          if (e) {
            if (e.code) {
              switch (e.code) {
                case 22:
                  quotaExceeded = true;
                  break;
                case 1014:
                  // Firefox
                  if (e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
                    quotaExceeded = true;
                  }
                  break;
              }
            } else if (e.number === -2147024882) {
              // Internet Explorer 8
              quotaExceeded = true;
            }
          }

          return quotaExceeded;
        }
    });


    return new Cache();

});
