/**
 * @fileOverview
 * @author Mitchell DeMarco
 * @version 0.1
 */

/**
 * API Class
 * A module representing the voxer server calls.
 * @name API
 * @return API
 * @version 1.0
 * @class API
 */

(function (name, definition) {
    if (typeof module !== 'undefined') {
        module.exports = definition();
    } else if (typeof define === 'function' && typeof define.amd === 'object') {
        define('main/api',definition);
    } else {
        this[name] = definition();
    }
}('API', function () {
    /** Properties of the module. */
    var home_router;
    /** @constructor */
    API = function (config) {
        if (config) {
            this.home_router = config.home_router || undefined;
        }
    };

    /**
     * Set the router to use.
     * @name API#set
     * @function
     * @param {object} home_router - object key/val to set
     * @example
     * API.set({home_router: 'https://one.voxer.com'});
     * API.set({home_router: 'https://one.voxer.com'});
     */
    API.prototype.set = function (object) {
        for (var key in object) {
            this[key] = object[key];
        }
        return this;
    };

    /**
     * Parses a server response
     * @name API#_parse_response
     * @private
     * @function
     * @param {string} response - data for the post_body
     * @param {function} error - error callback
     * @param {function} success - success callback
     * @returns {Object} xhr - xDomainObject
     * @example
     * API._post_message({q: 'sample'}, {url: '4/cs/progressive_search', home_router: 'https://...'}, function () {}, function () {});
     */
    API.prototype._parse_response = function (response, status, error, success) {
        var body;
        try {
            body = JSON.parse(response.responseText);
        } catch (err) {
            body = response.responseText;
        }
        if (status >= 400) {
            return error(body, response, "Error");
        }

        // Handle 302 Found responses
        if (status === 302) {
            const redirectUrl = response.getResponseHeader("Location");
            console.log("Redirecting to " + redirectUrl);
            if (redirectUrl) {
                window.location.href = redirectUrl; // Redirect to the new URL
                return;
            } else {
                return error("302 Found but no Location header in the response", response, "Error");
            }
        }
        console.log("Success: " + status);
        return success(body, status, response);
    }

    /**
     * Send a post request to the server
     * @name API#_post_message
     * @private
     * @function
     * @param {object} data - data for the post_body
     * @param {object} options - options for the ajax call and the url to call.
     * @param {function} error - error callback
     * @param {function} success - success callback
     * @returns {Object} xhr - xDomainObject
     * @example
     * API._post_message({q: 'sample'}, {url: '4/cs/progressive_search', home_router: 'https://...'}, function () {}, function () {});
     */
    API.prototype._post_message = function (data, options, error, success) {
        error = error || function () {};
        success = success || function () {};
        options = options || {};
        var url,
            now = (new Date()).getTime() / 1000,
            post_data = {
                "message_id": options.message_id || this._generate_message_id(),
                "create_time": now,              // MUST be recreated everytime for a send, even if retrying to send after error
                "model": navigator.userAgent.toLowerCase()
            }, post_body, xhReq, that = this;

        url = options.url;

        // add the parameters specific to that message
        for (var key in data) {
            var obj = data[key];
            post_data[key] = obj;
        }

        if (options.query === undefined) {
            options.query = {now: now};
        } else {
            options.query.now = now;
        }

        // create the URL
        if (options.home_router) {
            url = this._create_url({uri: url, query_params: options.query}, {
                home_router: options.home_router
            });
        } else {
            return error('Home router required');
        }

        post_body = JSON.stringify(post_data) + "\r\n";

        if (this._is_server()) {
            if (this.url_parser === undefined) {
                this.url_parser = require('url');
            }

            if (this.https === undefined) {
                this.https = require('https');
            }
            var url_parse = this.url_parser.parse(url);
            var path = url.substring(options.home_router.length, url.length);
            var server_options = {
                host: options.home_router,
                port: 443,
                path: path,
                method: "POST",
                headers: {
                    accept: "*/*",
                    'Content-Length': post_body.length
                }
            };

            var req = this.https.request(server_options, function (res) {
                res.setEncoding('utf8');
                var buffer = [];
                res.on('data', function (d) {
                    buffer.push(d);
                });

                res.on('end', function (d) {
                    var data_obj;
                    if (options.raw) {
                        data_obj = xhReq.responseText.slice(xhReq.responseText.indexOf("\r\n") + 2, xhReq.responseText.length);
                        success(data_obj);
                    } else {
                        that._parse_response(buffer.join(''), error, success);
                    }
                });
            });

            req.write(post_body);
            req.end();

            req.on('error', function (e) {
                error(e);
            });
        } else {
            if ('withCredentials' in new XMLHttpRequest()) {
                xhReq = new XMLHttpRequest();
                xhReq.withCredentials = true;
            } else {
                xhReq = new XDomainRequest();
            }

            if (options.asynchronousRequest === false && navigator.userAgent.indexOf("Firefox") ===  -1) {
                xhReq.open("POST", url, false);
            } else {
                xhReq.open("POST", url);
                xhReq.timeout = options.timeout || 10000;
            }


            xhReq.onload = function () {
                var data_obj;
                that._parse_response(xhReq, xhReq.status, error, success);
            };

            xhReq.onerror = function (e) {
                try {
                    console.log("error in _post_message " + JSON.parse(xhReq.responseText));
                } catch (err) {
                    console.log('Unknown error in _post_message: ' + err);
                }
                error(xhReq);
            };

            xhReq.onprogress = function (e) {

            };

            xhReq.ontimeout = function (e) {
                console.log("Server timeout out");
                error(xhReq);
            };

            // Remove this once the headers set on server allows cross domain requests
            if (url.indexOf('tp/google/connect/1') === -1) {
                xhReq.setRequestHeader('Content-Type','text/plain');
            } else {
                xhReq.setRequestHeader('Content-Type','application/json');
            }
            xhReq.send(post_body);

            return xhReq;
        }


    };

    /**
     * Send a get request to the server
     * @name API#_get_message
     * @private
     * @function
     * @param {object} data - data to append to end of url
     * @param {object} options - options for the ajax call
     *  @param {string} url - the server endpoint to call
     * @param {function} error - error callback
     * @param {function} success - success callback
     * @returns {Object} xhr - xDomainObject
     * @example
     * API._get_message({q: 'sample'}, {url: '4/cs/progressive_search'', home_router: 'https://...'}, function () {}, function () {});
     */
    API.prototype._get_message = function (data, options, error, success) {
        options = options || {};
        error = error || function () {};
        success = success || function () {};

        var url = options.url,
            now = (new Date()).getTime() / 1000,
            post_body, xhReq, that = this;

        if (options.query === undefined) {
            options.query = {now: now};
        } else {
            options.query.now = now;
        }

        if (options.home_router) {
            url = this._create_url({uri: url, query_params: data}, {
                home_router: options.home_router
            });
        } else {
            return error('Home router required');
        }

        if (this._is_server()) {
            if (this.url_parser === undefined) {
                this.url_parser = require('url');
            }

            if (this.https === undefined) {
                this.https = require('https');
            }
            var url_parse = this.url_parser.parse(url);
            var path = url.substring(options.home_router.length, url.length);
            var server_options = {
                host: options.home_router,
                port: 443,
                path: path,
                method: "GET",
                headers: {
                    accept: "*/*"
                }
            };

            var req = this.https.request(server_options, function(res) {
                res.setEncoding('utf8');
                var buffer = [];
                res.on('data', function (d) {
                    buffer.push(d);
                });

                res.on('end', function (d) {
                    var data_obj;
                    if (options.raw) {
                        data_obj = xhReq.responseText.slice(xhReq.responseText.indexOf("\r\n") + 2, xhReq.responseText.length);
                        success(data_obj);
                    } else {
                        that._parse_response(buffer.join(''), error, success);
                    }
                });
            });

            req.end();

            req.on('error', function (e) {
                error(e);
            });

        } else {
            if ('withCredentials' in new XMLHttpRequest()) {
                xhReq = new XMLHttpRequest();

                if (!options || !options.insecure)
                    xhReq.withCredentials = true;
            } else {
                xhReq = new XDomainRequest();
            }

            xhReq.open("GET", url);

            xhReq.timeout = 10000;
            xhReq.onload = function () {
                var data_obj;
                that._parse_response(xhReq, xhReq.status, error, success);
            };

            xhReq.onerror = function (e) {
                try {
                    console.log("error in _get_message " + JSON.parse(xhReq.responseText));
                } catch (err) {
                    console.log('Unknown error in _post_message: ' + err);
                }
                error(xhReq);
            };

            xhReq.onprogress = function (e) {

            };

            xhReq.ontimeout = function (e) {
                console.log("Server timeout out");
                error(xhReq);
            };

            xhReq.send(null);

            return xhReq;
        }


    };

    /**
     * Cancel the subscription
     * @name API#get_offsets
     * @function
     * @public
     * @param {object} params - Message or messages ID to return offsets for
     * @param {object} options
     * @param {function} error - error callback
     * @param {function} success - success callback
     */
    API.prototype.get_offsets = function (params, options, error, success) {
        var options = options || {},
            home_router = this.home_router || options.home_router,
            get_offsets = (params instanceof Array) ? params.join(',') : params;

        return this._get_message({
            get_offsets: get_offsets
        }, {
            url: 'get_offsets/1',
            home_router: home_router
        }, error, success);
    }

    /**
     * Cancel the subscription
     * @name API#cancel_subscription
     * @function
     * @public
     * @param {object} params - All data of message to send
     * @param {object} options
     * @param {function} error - error callback
     * @param {function} success - success callback
     */
    API.prototype.cancel_subscription = function (params, options, error, success) {
        var options = options || {},
            home_router = this.home_router || options.home_router;

        return this._post_message(params, {
            url: '1/cs/user/cancel_subscription',
            home_router: home_router
        }, error, success);
    }

    /**
     * Cancel the subscription
     * @name API#renew_subscription
     * @function
     * @public
     * @param {object} params - All data of message to send
     * @param {object} options
     * @param {function} error - error callback
     * @param {function} success - success callback
     */
    API.prototype.renew_subscription = function (params, options, error, success) {
        var options = options || {},
            home_router = this.home_router || options.home_router;

        return this._post_message(params, {
            url: '1/cs/user/renew_subscription',
            home_router: home_router
        }, error, success);
    }

    /**
     * Get a sharable link for audio message
     * @name API#share_message
     * @function
     * @public
     * @param {object} params - All data of message to send
     * @param {object} options
     * @param {function} error - error callback
     * @param {function} success - success callback
     */
    API.prototype.share_message = function (params, options, error, success) {
        var options = options || {},
            home_router = this.home_router || options.home_router;

        return this._post_message(params, {
            url: '1/cs/share_message',
            home_router: home_router
        }, error, success);
    }

    API.prototype.thread_details = function (thread_id, options, error, success) {
        var url = '2/cs/get_thread_details',
            options = options || {};


        home_router = this.home_router || options.home_router;

        return this._get_message({thread_id: thread_id},
                                 {url: url, home_router: home_router},
                                 error, success);
    };

    /**
     * Send a text message to a chat
     * @name API#send_text
     * @function
     * @public
     * @param {object} params - All data of message to send
     *  @param {object} from - who is sending the message
     *  @param {string} subject - subject of thread
     *  @param {string} thread_id - thread to send message to
     *  @param {string=} geo - location of message being sent
     * @param {object} options
     * @param {function} error - error callback
     * @param {function} success - success callback
     * @example
     * API.send_text({from: 'test', subject: 'Hi', thread_id: '111', geo: {longitude: 1, latitude: 1, altitude:1 }});
     */
    API.prototype.send_text = function (params, options, error, success) {
        options = options || {};

        var data, router, from, subject, body, thread_id, geo;

        data = {
            content_type: "text"
        };

        if (params === undefined || params.from === undefined || params.subject === undefined ||
            params.thread_id === undefined || params.body === undefined ||
            params.body.length === 0 || typeof params !== "object") {
            return error('Missing fields');
        }

        $.extend(data, params);


        router = this.home_router || options.router;

        return this._post_message(data, {url: '2/cs/post_message', home_router: router}, error, success);
    };

    /**
     * Send a file message to a chat
     * @name API#send_file
     * @function
     * @public
     * @param {object} params - All data of message to send
     *  @param {object} file_link_uri - who is sending the message
     *  @param {string} file_name - name of file being upload
     *  @param {string} file_extension - type of file
     *  @param {string=} file_size - Size of file
     *  @param {string} provider - provider
     *  @param {array=} icons - icons
     * @param {object} options
     * @param {function} error - error callback
     * @param {function} success - success callback
     * @example
     * API.send_text({from: 'test', subject: 'Hi', thread_id: '111', geo: {longitude: 1, latitude: 1, altitude:1 }});
     */
    API.prototype.send_file = function (params, options, error, success) {
        var data, router, file_link_uri, file_name, file_extension,
            file_size, file_icon_uri_list, provider;

        data = {
            subcontent_type: "file_share",
            file_link_uri: params.file_link_uri,
            file_name: params.file_name,
            file_extension: params.file_extension,
            file_size: params.file_size,
            provider: params.provider,
            file_icon_uri_list: (typeof params.string === 'number') ? [params.icons] : params.icons
        };

        if (data.file_link_uri === undefined || data.file_name === undefined ||
            data.file_size === undefined || data.provider === undefined) {
            return error('Missing fields');
        }

        $.extend(data, params);

        API.prototype.send_text(data, options, error, success);
    };

    /**
     * Revox a existing chat message
     * @name API#revox
     * @function
     * @public
     * @param {object} params - data of the revoxed message
     *  @param {string} thread_id - thread id to revox message to
     *  @param {string} message_id - message to be revoxed
     *  @param {string=} geo - data of the revoxed message
     * @param {object} options
     * @param {function} error - error callback
     * @param {function} success - success callback
     * @example
     * API.revox({thread_id: '1111', message_id: '111', create_time: '111', geo: {longitude: 1, latitude: 1, altitude:1 }});
     */
    API.prototype.revox = function (params, options, error, success) {
        var router;

        if (params === undefined || params.thread_id === undefined || params.message_id === undefined || typeof params !== "object") {
            return error('Missing fields');
        }

        options = options || {};

        router = this.home_router || options.router;

        return this._post_message(params, {url: 'message/revox/1', home_router: router}, error, success);
    };

    /**
     * Recall a message
     * @name API#Recall
     * @function
     * @public
     * @param {object} params - data of the recalled message
     *  @param {string} thread_id - thread id of message to remove
     *  @param {string} message_id - message to be removede
     * @param {object} options
     * @param {function} error - error callback
     * @param {function} success - success callback
     * @example
     * API.recall({thread_id: '1111', message_id: '111', create_time: '111'});
     */
    API.prototype.recall = function (params, options, error, success) {
        var router;

        if (params === undefined || params.thread_id === undefined || params.message_ids === undefined) {
            return error('Missing fields');
        }

        options = options || {};

        router = this.home_router || options.router;

        return this._post_message(params, {url: '2/cs/recall_messages', home_router: router}, error, success);
    };

    /**
     * Search for users
     * @name API#search
     * @function
     * @public
     * @param {object} params
     *  @param {string} q - what to search for
     *  @param {number=} limit - max amount of results to return, defaults to 50.
     * @param {object} options - options for the ajax call
     *  @param {string} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     * @example
     * API.search({q: 'John Candy', limit: 100}, {home_router: 'http//..'});
     */
    API.prototype.search = function (params, options, error, success) {
        options = options || {};

        if (params === undefined || params.q === undefined || params.q.length === 0 || typeof params !== "object") {
            return error('No search string provided');
        } else if (params.q.length < 4) {
            return error('Search string provided too short');
        } else if (params.q.length > 25) {
            return error('Search string provided too long');
        }

        var data = {
            "q": params.q,
            "index": "profiles",
            "limit": params.limit || 50
        }, router;

        router = this.home_router || options.router;

        return this._post_message(data, {url: '4/cs/progressive_search', home_router: router}, error, success);
    };


    API.prototype.get_teams = function (options, error, success) {
        return this._get_message({}, {
            url: '/2/cs/teams',
            query: {},
            home_router: this.home_router || options.home_router
        }, error, success)
    }

    /**
     * Sign in and get a session key
     * @name API#oauth
     * @function
     * @public
     * @param {object} data
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     * @example
     * API.auth({username: 'JohnCandy', hash: '1asdf'}, {home_router: 'http//..'});
     */
    API.prototype.auth_redirect = function (params, options, error, success) {
        options = options || {};
        var data, version;

        var router = this.home_router || options.router;

        return this._get_message(data, {url: '', home_router: router}, error, success);
    };

    /**
     * Sign in and get a session key
     * @name API#auth
     * @function
     * @public
     * @param {object} data
     *  @param {string} username - username or email
     *  @param {number} hash - hashed password of user
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     * @example
     * API.auth({username: 'JohnCandy', hash: '1asdf'}, {home_router: 'http//..'});
     */
    API.prototype.auth = function (params, options, error, success) {
        var that = this;
        options = options || {};
        var data, version;

        if (params === undefined) {
            return error('Missing fields');
        }
        if (params.sso_type === 'ping' && (params.token !== undefined || params.sso_ping_saml_auth)) {
            return this.auth_sso_ping(params, options, error, success);
        } else if (params.code !== undefined || params.token !== undefined) {
            return this.auth_sso_google(params, options, error, success);
        } else if (params.accessToken !== undefined) {
            return this.auth_sso_facebook(params, options, error, success);
        }

        if (params.username === undefined || params.hash === undefined || typeof params !== "object") {
            return error('Missing fields');
        }

        version = params.version || '4.4.9';
        data = {
            "credential_flavor": "voxer",
            "auth_version": 3,
            "device": "Browser",
            "system_name": 'web_browser',
            "client_version": version,
            "user_identifier": params.username,
            "response": params.hash,
            "recaptcha": params.recaptcha
        };

        var worker = new Worker(URL.createObjectURL(new Blob([
            "function kt(base, user_id, hardness, c) {\
                crypto.subtle.digest('SHA-256', new TextEncoder().encode(base + c))\
                .then(function (h) {\
                    if (new Uint16Array(h)[0] & hardness) kt(base, user_id, hardness, ++c);\
                    else postMessage({base:base, user_id: user_id, pow:c});\
                });\
            };\
            onmessage = function (e) {\
                kt(e.data[0], e.data[1], e.data[2], 0);\
            }"
        ], {type: 'application/javascript'})));

        worker.onmessage = function (e) {
            that._post_message(e.data, {
                url: "nonce/lookup/1",
                home_router: window.location.host,
                timeout: options.timeout
            }, error, function (data) {
                if (data && data.rebelvox_user_id) {
                    success(data);
                } else {
                    console.log('nonce error' , data);
                    error(data);
                }
            });
        };

        if (params.uuid !== undefined) {
            data.uuid = params.uuid;
        }

        var router = this.home_router || options.router;

        function stream_start_session() {
            var stream = App.VoxerAudio.client.createStream({
                content_type: "start_session",
                data: data
            });
            stream.on("data", function (data) {
                try {
                    var obj = JSON.parse(data);
                    if (obj.error) {
                        console.log("data error" + obj.error);
                        error(obj);
                    } else {
                        worker.postMessage([obj.nonce, obj.user_id, obj.hardness]);
                    }
                } catch (e) {
                    console.log("data try/catch" + e.message);
                    error(e);
                }
            });
            stream.on("error", function (data) {
                console.log("stream error" + data);
                error(data);
            });
        }

        if (App.VoxerAudio.socket_connected) {
            stream_start_session();
        } else {
            App.VoxerAudio.connect_ws(App.webserver, stream_start_session);
        }
    };



    API.prototype.auth_sso_google = function (params, options, error, success) {
        options = options || {};
        var data, version;

        if (params === undefined ||  (params.code === undefined && params.token === undefined)) {
            return error('Missing fields');
        }

        version = params.version || '1.0.0';

        data = {
            "credential_flavor": "voxer",
            "auth_version": 3,
            "device": "Browser",
            "system_name": 'web_browser',
            "client_version": version,
            "redirect_url": 'https://localhost:3080/oauth.html'
        };

        if (params.code) {
            data['sso_query_string'] = 'code=' + params.code;
        } else {
            data['sso_token'] = params.token;
        }

        if (params.uuid !== undefined) {
            data.uuid = params.uuid;
        }

        var router = this.home_router || options.router;

        return this._post_message(data, {
            url: '3/cs/start_session_google',
            home_router: router,
            timeout: options.timeout
        }, error, success);
    };

    API.prototype.auth_sso_facebook = function (params, options, error, success) {
        options = options || {};
        var data, version;

        if (params === undefined || params.accessToken === undefined) {
            return error('Missing fields');
        }

        version = params.version || '1.0.0';

        data = {
            "credential_flavor": "voxer",
            "auth_version": 3,
            "device": "Browser",
            "system_name": 'web_browser',
            "client_version": version,
            "access_token": params.accessToken
        };

        if (params.code) {
            data['sso_query_string'] = 'code=' + params.code;
        } else {
            data['sso_token'] = params.token;
        }

        if (params.uuid !== undefined) {
            data.uuid = params.uuid;
        }

        var router = this.home_router || options.router;

        return this._post_message(data, {
            url: '3/cs/start_session',
            home_router: router,
            timeout: options.timeout
        }, error, success);
    };

    API.prototype.auth_sso_ping = function (params, options, error, success) {
        options = options || {};
        var data, version;

        if (params === undefined ||  (params.code === undefined && params.token === undefined && !params.sso_ping_saml_auth)) {
            return error('Missing fields');
        }

        version = params.version || '1.0.0';

        data = {
            credential_flavor: "voxer",
            auth_version: 3,
            device: "Browser",
            system_name: 'web_browser',
            client_version: version,
            business_id: params.business_id,
            sso_type: params.sso_type
        };

        if (params.code) {
            data['sso_query_string'] = 'code=' + params.code;
        } else if (params.token) {
            data['sso_token'] = params.token;
        } else if (params.sso_ping_saml_auth) {
            data = Object.assign({}, data, params)
        }

        var router = this.home_router || options.router;

        return this._post_message(data, {
            url: '3/cs/start_session_sso',
            home_router: router,
            timeout: options.timeout
        }, error, success);
    };

    /**
     * check if email is owned by a sso
     * @name API#sso_provider
     * @function
     * @public
     * @param {object} data
     *  @param {string} email - email to check
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     * @example
     * API.sso_provider({home_router: 'http//..'});
     */
    API.prototype.sso_provider = function (params, options, error, success) {
        options = options || {};

        var router = (options.router || this.home_router),
            data = {};

        if (params === undefined || params.email === undefined || typeof params !== "object") {
            return error('Missing fields');
        }

        data = {
            "email": params.email,
            "client_type": "web"
        };
    
        return this._get_message(data, {home_router: router, url: '3/cs/sso_provider'}, error, success);
    };

    /**
     * Config returns server configurations instead of bootstrapping it like a normal human would
     * @name API#config
     * @function
     * @public
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     * @example
     * API.config({home_router: 'http//..'});
     */
    API.prototype.config = function (options, error, success) {
        options = options || {};

        var router = options.home_router;

        return this._get_message({}, {home_router: router, url: 'config'}, error, success);
    };



    /**
     * return the timeline of a thread, or session
     * @name API#timeline
     * @function
     * @public
     * @param {object} params - options for timeline call
     *  @param {string=} descending - true if desceneding order
     *  @param {string=} thread_id - ID of thread to get timeline for
     *  @param {string=} end_time - oldest message to grab
     *  @param {string=} start_time - newest message to grab
     * @param {object} options - options for the ajax call
     * @param {function} error - error callback
     * @param {function} success - success callback
     * @function
     * @example
     * API.timeline('12341251_1233', {home_router: 'http//..'});
     */
    API.prototype.timeline = function (params, options, error, success) {
        options = options || {};

        if (params.descending === undefined) {
            params.descending = true;
        }

        var router = options.router || this.home_router,
            url = router + '/' + (params.version || 3) + '/cs/timeline',
            data = {
                "descending": params.descending
            };

        if (params.thread_id) {
            data.thread_id = params.thread_id;
        }

        if (params.end_time) {
            data.end_time = params.end_time;
        }

        if (params.start_time) {
            data.start_time = params.start_time;
        }

        data.limit = params.limit || 200;

        return this._get_message(data, {home_router: router, raw: true, url: url}, error, success);
    };

    /**
     * Our server requires ackowedgment of success. Always ack after calling timeline (will probable just automate this step)
     * @name API#ack_timeline
     * @function
     * @public
     * @param {object} params - options for timeline call
     *  @param {string=} high_water_mark - last time you got a timeline
     * @param {object} options - options for the ajax call
     * @param {function} error - error callback
     * @param {function} success - success callback
     * @function
     * @example
     * API.timeline('12341251_1233', {home_router: 'http//..'});
     */
    API.prototype.ack_timeline = function (params, options, error, success) {
        options = options || {};

        if (params === undefined && params.high_water_mark === undefined) {
            return error('No high water mark provided');
        }

        var data = {
            "high_water_mark": params.high_water_mark
        };

        var home_router = options.router || this.home_router;

        return this._post_message(data, {url: "1/cs/ack_timeline", home_router: home_router}, error, success);
    };

    /**
     * Returns a list of all chats of user
     * @name API#chats_list
     * @function
     * @public
     * @param {string} params - does not take any parameters
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     * @example
     * API.chats_list({}, {home_router: 'http//..'});
     */
    API.prototype.chats_list = function (params, options, error, success) {
        options = options || {};

        var router = options.router || this.home_router;

        return this._get_message(null, {url: "2/cs/chats_list", home_router: router}, error, success);
    };

    /**
     * Returns a list of all contacts of user
     * @name API#chats_list
     * @function
     * @public
     * @param {string} params - does not take any params
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     * @example
     * API.contact_list({}, {home_router: 'http//..'});
     */
    API.prototype.contact_list = function (params, options, error, success) {
        options = options || {};

        var router = options.router || this.home_router;

        return this._get_message(params, {url: "2/cs/contact_list", home_router: router}, error, success);
    };

    /**
     *  Modifies the tags for the specified contacts
     * @name API#change_contacts_mods
     * @function
     * @public
     * @param {object} params - params for the ajax call
     *  @param {string} changes - object with users ids as keys and changes to be made
     *   {<userID>:{add:[<tagToBeAdded>], remove:[<tagToBeRemoved>]}
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     * @example
     * API.contact_list({}, {home_router: 'http//..'});
     */
    API.prototype.change_contacts_mods = function (params, options, error, success) {
        options = options || {};

        var home_router = options.home_router || this.home_router;

        App.API._post_message(params, {url: "/contacts/mod_tags/1", home_router: home_router}, error, success);
    };

    /**
     *  Modifies the tags for the specified contacts
     * @name API#add_web_hook_zapier
     * @function
     * @public
     * @param {object} params - params for the ajax call
     *  @param {string} changes - object with users ids as keys and changes to be made
     *   {<userID>:{add:[<tagToBeAdded>], remove:[<tagToBeRemoved>]}
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     * @example
     * API.contact_list({}, {home_router: 'http//..'});
     */
    API.prototype.add_web_hook_zapier = function (params, options, error, success) {
        options = options || {};

        var home_router = options.home_router || this.home_router;

        App.API._post_message(params, { url: "2/cs/user_settings", home_router: home_router }, error, success);
    };

    /**
     * Returns a list of all teams the user is a member off
     * @name API#teams
     * @function
     * @public
     * @param {string} params - does not take any params
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     * @example
     * API.contact_list({}, {home_router: 'http//..'});
     */
    API.prototype.teams = function (params, options, error, success) {
        options = options || {};

        var router = options.router || this.home_router;

        return this._get_message(null, {url: "2/cs/teams", home_router: router}, error, success);
    };

    /**
     * Send a email to a forgotten password
     * @name API#forgot_password
     * @function
     * @public
     * @param {string} email - Email address to send forgot email to
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     * @example
     * API.forgot_password('test@email.com', {home_router: 'http//..'});
     */
    API.prototype.forgot_password = function (email, options, error, success) {
        options = options || {};

        if (email === undefined || email.length === 0 || typeof email === "object") {
            return error('No email provided');
        }

        var router = options.router || this.home_router,
            url = router + '/forgot_password',
            data = {
                email: encodeURIComponent(email)
            };

        return this._get_message(data, {home_router: router, url: url}, error, success);
    };

    /**
     * Send a email to support
     * @name API#support
     * @todo this function is not ready for use
     * @function
     * @public
     * @param {object} params - object holding parameters
     *  @param {string} subject - subject of support email
     *  @param {string} description - description of problem
     *  @param {string} email - email address of user
     *  @param {object=} more - optional addition info
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     */
    API.prototype.support = function (params, options, error, success) {
        options = options || {};

        if (typeof params !== 'object' || params.subject === undefined ||
            params.description === undefined || params.email === undefined) {
            return error('Missing fields');
        }

        var post_data = {
                "subject": params.subject,
                "description": params.description,
                "email": params.email
            }, more = params.more || {},
            router = options.router || this.home_router,
            url = router + "/api/1/support";
        var data = {};

        for (var key1 in post_data) {
            data[key1] = post_data[key1];
        }
        for (var key2 in params.more) {
            data[key2] = params.more[key2];
        }

        return this._get_message(data, {home_router: router, url: url}, error, success);
    };

    /**
     * Update a users profile
     * @name API#update_profile
     * @function
     * @public
     * @param {object} params - object holding parameters
     *  @param {string} email - Email address of user
     *  @param {string} first - first name of user
     *  @param {string} last - last name of user
     *  @param {object=} more - optional more
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     */
    API.prototype.update_profile = function (params, options, error, success) {
        options = options || {};
        var data = {}, post_data = {}, router, message_ids, more;


        if (typeof params !== 'object' || params.email === undefined ||
            params.first === undefined || params.last === undefined) {
            return error('Missing fields');
        }

        post_data = {
            "email": params.email,
            "first": params.first,
            "last": params.last
        };

        more = params.more || {};

        for (var key1 in post_data) {
            data[key1] = post_data[key1];
        }
        for (var key2 in params.more) {
            data[key2] = params.more[key2];
        }

        router = this.home_router || options.router;

        return this._post_message(data, {home_router: router, url: '2/cs/edit_profile'}, error, success);
    };

    API.prototype.link_google_account = function (params, options, error, success) {
        options = options || {};
        var router, message_ids, more;

        router = this.home_router || options.router;

        return this._get_message(params, {home_router: router, url: 'tp/google/connect/1'}, error, success);
    };

    /**
     * Change a users password
     * @name API#change_password
     * @function
     * @public
     * @param {object} params - object holding parameters
     *  @param {string} password_id - server required identification of login
     *  @param {string} password - new password for user
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     */
    API.prototype.change_password = function (params, options, error, success) {
        options = options || {};
        var data = {}, router;

        if (typeof params !== 'object' || params.password_id === undefined ||
            params.password === undefined) {
            return error('Missing fields');
        }

        data = {
            "password": params.password,
            "password_id": params.password_id
        };

        router = this.home_router || options.router;

        return this._post_message(data, {home_router: router, url: '2/cs/change_password'}, error, success);
    };

    /**
     * Change a users name
     * @name API#change_username
     * @function
     * @public
     * @param {object} params - object holding parameters
     *  @param {string} user_id - ID of the user to set
     *  @param {string} username - New username
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     */
    API.prototype.change_username = function (params, options, error, success) {
        options = options || {};
        var router;

        router = this.home_router || options.router;

        return this._post_message(params, {home_router: router, url: '2/cs/username_for_user_id'}, error, success);
    };

    /**
     * Checks if an username is available
     * @name API#is_username_available
     * @function
     * @public
     * @param {object} params - object holding parameters
     *  @param {string} user_id - ID of the user
     *  @param {string} username - username to check
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     */
    API.prototype.is_username_available = function(params, options, error, success) {
        options = options || {};
        var router;

        router = this.home_router || options.router;

        return this._get_message(params, {home_router: router, url: '/username/available/1'}, error, success);
    };

    /**
     * Used to consume messages in batches or individually.
     * @name API#consume_message
     * @function
     * @public
     * @param {array} params - array of message ids to consume, requires thread id
     *  @param {array} message_ids - array of message ids to consume, requires thread id
     *  @param {string=} thread_id - thread id to consume, or thread of id of message ids being conusmed
     *  @param {string=} hwm - high water mark to consume all messages from that point in time.
     *  @param {string=} unconsumed_count - the amount of unconsumed messages, defaults to 0
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     */
    API.prototype.consume_message = function (params, options, error, success) {

        var data = {
            message_id: params.message_id || this._generate_message_id()
        }, router, message_ids;

        message_ids = params.message_ids || [];

        message_ids =  (message_ids instanceof Array) ? message_ids : [message_ids];

        if (message_ids[0] !== undefined) {
            data.message_ids = message_ids;
        }

        if (params.thread_id) {
            data.thread_id = params.thread_id;
        }

        if (params.hwm || params.hwm === 0) {
            data.hwm = params.hwm;
        }

        if (params.unconsumed_count) {
            data.unconsumed_count = params.unconsumed_count;
        } else {
            data.unconsumed_count = 0;
        }

        router = this.home_router || options.router;

        return this._post_message(data, {url: '3/cs/consume_messages', home_router: router}, error, success);
    };

    /**
     * Used to like messages in batches or individually.
     * @name API#like_messages
     * @function
     * @public
     * @param {object} params - message_ids to like, thread_id and user_id
     *  @param {array} message_ids - array of message ids to like, requires thread id
     *  @param {string=} thread_id - thread id to consume, or thread of id of message ids being conusmed
     *  @param {string=} from - user_id of the peer liking the message
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     */
    API.prototype.like_messages = function (params, options, error, success) {
        var data = { message_id: params.message_id || this._generate_message_id(), like_times: {}, like: true },
            router,
            message_ids;

        message_ids = params.message_ids || [];

        message_ids =  (message_ids instanceof Array) ? message_ids : [message_ids];

        if (message_ids[0] !== undefined && params.thread_id && params.from) {
            data.message_ids = message_ids;
            data.thread_id = params.thread_id;
            data.from = params.from;
        } else {
            return error('Missing fields');
        }

        if (typeof params.like !== "undefined") {
            data.like = params.like;
        }

        data.content_type = "like_receipt";
        data.create_time = Date.now() / 1000;

        data.message_ids.forEach(function (message_id) {
            data.like_times[message_id] = data.create_time;
        });

        home_router = this.home_router || options.router;


        return this._post_message(data, {
            url: '3/cs/like_messages',
            home_router: home_router
        }, error, success);
    };

    /**
     * Create a chat between two users, or between many
     * @name API#create_thread
     * @function
     * @public
     * @param {object} params - object containing parameters
     *  @param {array} user_ids - array of user_ids to create chat with
     *  @param {string} group_ids - array of groups to make chat with
     *  @param {string} subject - subject of chat
     *  @param {array} invites - Arey of invites data objects
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     */
    API.prototype.create_chat = function (params, options, error, success) {
        var data = {},
            router,
            thread_id,
            subject,
            user_ids = params.user_ids,
            group_ids = params.group_ids,
            invites;

        if (user_ids !== undefined) {
            user_ids =  (params.user_ids instanceof Array) ? params.user_ids : [params.user_ids];
        } else {
            user_ids = [];
        }

        if (group_ids !== undefined) {
            group_ids =  (params.group_ids instanceof Array) ? params.group_ids : [params.group_ids];
        } else {
            group_ids = [];
        }

        if (params.invites !== undefined) {
            invites = (params.invites instanceof Array) ? params.invites : [params.invites];
        } else {
            invites = [];
        }

        if (typeof params !== 'object' ||
                (!user_ids.length && !group_ids.length && !invites.length) ||
                params.subject === undefined) {
            return error('Missing fields');
        }

        if ((user_ids.length + group_ids.length + invites.length < 2)) {
            return error('Cannot create chat with less then 2 users or groups');
        }

        if (user_ids.length > 2 || group_ids.length > 0 || invites.length > 0) {
            thread_id = this._generate_message_id();
        }
        else {
            thread_id = 'HL_' + [user_ids[0], user_ids[1]].sort().join('_');
        }

        data = {
            thread_id: thread_id,
            create_time: (new Date()).getTime() / 1000,
            recipients: user_ids,
            groups: group_ids,
            subject: params.subject,
            invites: invites
        };

        home_router = this.home_router || options.home_router;

        return this._post_message(data, {url: '2/cs/start_thread', home_router: home_router}, error, success);
    };

    /**
     * Add participants to a chat
     * @name API#add_participants
     * @function
     * @public
     * @param {object} params - object containing parameters
     *  @param {array} thread_id - ID of thread to add participents to
     *  @param {string} group_ids - array of groups to make chat with
     *  @param {string} user_ids - array of users to make chat with
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     */
    API.prototype.add_participants = function (params, options, error, success) {

        var data = {}, router, user_ids = params.user_ids, group_ids = params.group_ids;

        if (user_ids !== undefined) {
            user_ids =  (params.user_ids instanceof Array) ? params.user_ids : [params.user_ids];
        } else {
            user_ids = [];
        }

        if (group_ids !== undefined) {
            group_ids =  (params.group_ids instanceof Array) ? params.group_ids : [params.group_ids];
        } else {
            group_ids = [];
        }

        if (typeof params !== 'object' || (user_ids.length === 0 && group_ids.length === 0) ||
            params.thread_id === undefined) {
            return error('Missing fields');
        }

        if (params.thread_id.indexOf("HL_") !== -1) {
            return error('Cannot add a user or group to a hotline');
        }

        data = {
            thread_id: params.thread_id,
            user_ids: user_ids,
            groups: group_ids
        };

        router = this.home_router || options.router;

        return this._post_message(data, {url: 'chat/add_participants/1', home_router: router}, error, success);
    };

    /**
     * Remove participants to a chat
     * @name API#remove_participants
     * @function
     * @public
     * @param {object} params - object containing parameters
     *  @param {array} thread_id - ID of thread to remove participents from
     *  @param {string} group_ids - array of groups to make chat with
     *  @param {string} user_ids - array of users to make chat with
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     */
    API.prototype.remove_participants = function (params, options, error, success) {

        var data = {}, router, subject, user_ids = params.user_ids, group_ids = params.group_ids;

        if (user_ids !== undefined) {
            user_ids =  (params.user_ids instanceof Array) ? params.user_ids : [params.user_ids];
        } else {
            user_ids = [];
        }

        if (group_ids !== undefined) {
            group_ids =  (params.group_ids instanceof Array) ? params.group_ids : [params.group_ids];
        } else {
            group_ids = [];
        }

        if (typeof params !== 'object' || (user_ids.length === 0 && group_ids.length === 0) ||
            params.thread_id === undefined) {
            return error('Missing fields');
        }

        if (params.thread_id.indexOf("HL_") !== -1) {
            return error('Cannot remove a user or group to a hotline');
        }

        data = {
            thread_id: params.thread_id,
            user_ids: user_ids,
            groups: group_ids
        };

        router = this.home_router || options.router;

        return this._post_message(data, {url: 'chat/remove_participants/1', home_router: router}, error, success);
    };

    /**
     * Leave a chat and never recieve messages from it again
     * @name API#leave_chat
     * @function
     * @public
     * @param {object} params - object containing parameters
     *  @param {string} thread_id - ID of thread to leave
     *  @param {Integer=} unconsumed_count - Defaults to 0
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     */
    API.prototype.leave_chat = function (params, options, error, success) {

        var data = {}, router, thread_id, unconsumed_count;

        if (typeof params !== 'object' || params.thread_id === undefined) {
            return error('Missing fields');
        }

        if (params.thread_id.indexOf("HL_") !== -1) {
            return error('Cannot leave a hotline, try delete_chat instead');
        }

        unconsumed_count = params.unconsumed_count || 0;

        data = {
            thread_id: params.thread_id,
            unconsumed_count: unconsumed_count
        };

        router = this.home_router || options.router;

        return this._post_message(data, {url: '2/cs/leave_chat', home_router: router}, error, success);
    };

    /**
     * Delete a chat until next message is recieved
     * @name API#delete_chat
     * @function
     * @public
     * @param {object} params - object containing parameters
     *  @param {string} thread_id - ID of thread to leave
     *  @param {Integer=} unconsumed_count - Defaults to 0
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     */
    API.prototype.delete_chat = function (params, options, error, success) {

        var data = {}, router, thread_id, unconsumed_count;

        if (typeof params !== 'object' || params.thread_id === undefined) {
            return error('Missing fields');
        }

        unconsumed_count = params.unconsumed_count || 0;

        delete localStorage['timeline_' + App.Auth.get('user_id') + data.thread_id];

        data = {
            thread_id: params.thread_id,
            unconsumed_count: unconsumed_count
        };

        router = this.home_router || options.router;

        return this._post_message(data, {url: 'chat/delete', home_router: router}, error, success);
    };

    /**
     * Change a chat title
     * @name API#change_title
     * @function
     * @public
     * @param {object} params - object containing parameters
     *  @param {string} thread_id - ID of thread to change title
     *  @param {Integer} chat_name - name to change chat title to
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     */
    API.prototype.change_title = function (params, options, error, success) {

        var data = {}, router;

        if (typeof params !== 'object' || params.thread_id === undefined || params.chat_name === undefined) {
            return error('Missing fields');
        }


        data = {
            content_type: "modify_chat_name",
            thread_id: params.thread_id,
            chat_name: params.chat_name
        };
        router = this.home_router || options.router;

        return this._post_message(data, {url: 'chat/modify_name/1', home_router: router}, error, success);
    };


    /**
     * Add a user to contacts
     * @name API#add_contact
     * @function
     * @public
     * @param {object} params - object containing parameters
     *  @param {array} user_ids - array of user_ids to create chat with
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     */
    API.prototype.add_contact = function (params, options, error, success) {

        var data = {}, router, user_ids = params.user_ids;

        if (user_ids !== undefined) {
            data.contact_user_id =  (user_ids instanceof Array) ? user_ids : [user_ids];
        } else {
            return error('Missing fields');
        }

        router = this.home_router || options.router;

        return this._get_message(data, {url: 'add_contact', home_router: router}, error, success);
    };

    /**
     * Delete a user from contacts
     * @name API#delete_contact
     * @function
     * @public
     * @param {object} params - object containing parameters
     *  @param {array} user_ids - array of user_ids to create chat with
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     */
    API.prototype.delete_contact = function (params, options, error, success) {

        var data = {'changes': {}}, router, user_ids = params.user_ids;

        if (user_ids !== undefined) {
           user_ids =  (user_ids instanceof Array) ? user_ids : [user_ids];
        } else {
            return error('Missing fields');
        }

        for( var x = 0; x < user_ids.length; x++) {
            data.changes[user_ids[x]] = {add:['deleted']};
        }

        router = this.home_router || options.router;

        return this._post_message(data, {url: '2/cs/mod_contact_tags', home_router: router}, error, success);
    };

    /**
     * Block a user
     * @name API#block_contact
     * @function
     * @public
     * @param {object} params - object containing parameters
     *  @param {array} user_ids - array of user_ids to create chat with
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     */
    API.prototype.block_contact = function (params, options, error, success) {

        var data = {'changes': {}}, router, user_ids = params.user_ids;

        if (user_ids !== undefined) {
            user_ids =  (user_ids instanceof Array) ? user_ids : [user_ids];
        } else {
            return error('Missing fields');
        }

        for( var x = 0; x < user_ids.length; x++) {
            data.changes[user_ids[x]] = {add:['deleted']};
        }

        router = this.home_router || options.router;

        return this._post_message(data, {url: '2/cs/mod_contact_tags', home_router: router}, error, success);
    };

    /**
     * Retrieves the collection of subscriptions, each with a list of associated users
     * @name API#get_managed_subscriptions
     * @function
     * @public
     * @param {object} params - object containing parameters
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     */
    API.prototype.get_managed_subscriptions = function (params, options, error, success) {
        options = options || {};
        var home_router;

        home_router = this.home_router || options.home_router;

        return this._get_message(params, {
            home_router: home_router,
            url: '1/cs/user/subscriptions'
        }, error, success);
    };

    /**
     * Modifies the collection of subscriptions for a user through an add array and a remove array.
     * The token is necessary when the user isn't already a stripe customer.
     * @name API#managed_subscriptions
     * @function
     * @public
     * @param {object} params - object containing parameters
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     */
    API.prototype.managed_subscriptions = function (params, options, error, success) {
        options = options || {};
        var home_router;

        home_router = this.home_router || options.home_router;

        return this._post_message(params, {
            home_router: home_router,
            url: '1/cs/user/subscriptions'
        }, error, success);
    };

    /**
     * Sign up a user to voxer
     * @name API#signup
     * @function
     * @public
     * @param {object} params - object containing parameters
     *  @param {string} first - first name
     *  @param {string} last - last name
     *  @param {string} email - email
     *  @param {string} pass - sha512 of the users password
     *  @param {string=} phone - phone number of user
     *  @param {string=} dial_code - dial code of user
     *  @param {string=} c_version - The version number of the Voxer client that is trying to start the session.
     *  @param {string=} model -  Model of the user's mobile handset. For browser-based clients this should indicate the browser being used (Safari, Chrome, etc).
     *  @param {string=} s_version - Name of the operating system hosting the client.
     *  @param {string=} uuid - unique piece of data to the client to use as a uuid
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     * @returns {JSON} returns a json object of created user
     */
    API.prototype.signup = function (params, options, error, success) {
        const version = (params && params.version) ? params.version : '4.4.9';

        var data = {
                device: 'Browser',
                system_name: 'web_browser',
                client_version: version,
                recaptcha_response: options.recaptcha_response,
                first: params.f_name,
                last: params.l_name,
                email: params.email,
                clienthash: params.pass,
                phone: params.phone,
                model: params.model,
                s_version: params.s_version,
                uuid: params.uuid,
                suggested_user_id: params.suggested_user_id,
                opt_in_marketing: params.opt_in_marketing,
            },
            router;

        router = this.home_router || options.router;

        if (router === undefined) {
            return error('Missing router');
        }

        if (data === undefined || data.first === undefined || data.last === undefined || data.email === undefined || data.clienthash === undefined) {
            return error('Missing parameter');
        }

        return this._post_message(data, {url: '2/cs/signup', home_router: router}, error, success);
    };

    /**
     * Get a public profile
     * @name API#public_profile
     * @function
     * @public
     * @param {object} params - object containing parameters
     * @param {array} username - username of the user to get profile of
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     * @returns {JSON} returns a json object of user profiles
     */
    API.prototype.username_public_profile = function (params, options, error, success) {
        var data = {},
            router

        if (params.username === undefined) {
            return error('Missing fields');
        }

        router = this.home_router || options.router

        data.username = params.username
        data.profile_by = 'username'

        return this._get_message(data,
            { url: "1/cs/username_public_profile", home_router: router },
             error, success)
    };

    /**
     * Get a public profile
     * @name API#public_profile
     * @function
     * @public
     * @param {object} params - object containing parameters
     *  @param {array} user_ids - list of users to get profiles of
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     * @returns {JSON} returns a json object of user profiles
     */
    API.prototype.public_profile = function (params, options, error, success) {
        var data = {}, router, user_ids;

        if (params.user_ids !== undefined) {
            user_ids =  (params.user_ids instanceof Array) ? params.user_ids : [params.user_ids];
        } else {
            user_ids = [];
        }

        router = this.home_router || options.router;

        if (user_ids.length === 0) {
            return error('Missing fields');
        }

        data.uids = user_ids;

        return this._post_message(data, {url: '3/cs/public_profiles', home_router: router}, error, success);
    };

    /**
     * Get a teams profile
     * must be a member of the team to get its profile
     * @name API#team_profile
     * @function
     * @public
     * @param {object} params - object containing parameters
     *  @param {array} team_ids - list of teams to get profiles of
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     * @returns {JSON} returns a json object of user profiles
     */
    API.prototype.team_profile = function (params, options, error, success) {
        var data = {}, router, team_ids = params.team_ids;

        if (team_ids !== undefined) {
            team_ids =  (team_ids instanceof Array) ? team_ids : [team_ids];
        } else {
            team_ids = [];
        }

        router = this.home_router || options.router;

        if (team_ids.length === 0) {
            return error('Missing fields');
        }

        data.team_ids = team_ids;

        return this._post_message(data, {url: '2/cs/team_profiles', home_router: router}, error, success);
    };

    /**
     * Change a chats controlled status
     * @name API#controlled_chat
     * @function
     * @public
     * @param {object} params - object containing parameters
     *  @param {string} thread_id - thread to change
     *  @param {Bool} controlled - should this chat be controlled or not
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     * @returns {JSON} returns a json object of user profiles
     */
    API.prototype.controlled_chat = function (params, options, error, success) {
        var data = {}, router, thread_id = params.thread_id, controlled = params.controlled;

        router = this.home_router || options.router;

        if (thread_id === undefined || controlled === undefined) {
            return error('Missing fields');
        }

        data.thread_id = thread_id;
        data.controlled_chat = controlled;

        return this._post_message(data, {url: 'chat/controlled_chat/1', home_router: router}, error, success);
    };

    /**
     * Turn the notification for a specific chat off
     * @name API#suppress_notifications
     * @function
     * @public
     * @param {object} params - object containing parameters
     *  @param {string} thread_id - thread to change
     *  @param {string} from - users id
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     * @returns {JSON} returns a json object sith the result value
     */
    API.prototype.suppress_notifications = function (params, options, error, success) {
        var data = {
            content_type: 'suppress_notifications',
            create_time: Date.now() / 1000,
            message_id: this._generate_message_id()
        };

        params = params || {};

        if (params.from === undefined || params.thread_id === undefined) {
            return error('Missing fields');
        }

        params = _.extend(params, data);

        home_router = this.home_router || options.home_router;

        return this._post_message(params, {
            url: 'chat/suppress_notifications/1',
            home_router: home_router
        }, error, success);
    };

    /**
     * Turn the notification for a specific chat on
     * @name API#enable_notifications
     * @function
     * @public
     * @param {object} params - object containing parameters
     *  @param {string} thread_id - thread to change
     *  @param {string} from - users id
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     * @returns {JSON} returns a json object sith the result value
     */
    API.prototype.enable_notifications = function (params, options, error, success) {
        var data = {
            content_type: 'enable_notifications',
            create_time: Date.now() / 1000,
            message_id: this._generate_message_id()
        };

        params = params || {};

        if (params.from === undefined || params.thread_id === undefined) {
            return error('Missing fields');
        }

        params = _.extend(params, data);

        home_router = this.home_router || options.home_router;

        return this._post_message(params, {
            url: 'chat/enable_notifications/1',
            home_router: home_router
        }, error, success);
    };

    /**
     * Turn the extreme notification on for a specific chat
     * @name API#enable_extreme_notifications
     * @function
     * @public
     * @param {object} params - object containing parameters
     *  @param {string} thread_id - thread to change
     *  @param {string} from - users id
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     * @returns {JSON} returns a json object sith the result value
     */
    API.prototype.enable_extreme_notifications = function (params, options, error, success) {
        var data = {
            content_type: 'enable_extreme_notifications',
            create_time: Date.now() / 1000,
            message_id: this._generate_message_id()
        };

        params = params || {};

        if (params.from === undefined || params.thread_id === undefined) {
            return error('Missing fields');
        }

        params = _.extend(params, data);

        home_router = this.home_router || options.home_router;

        return this._post_message(params, {
            url: 'chat/enable_extreme_notifications/1',
            home_router: home_router
        }, error, success);
    };

    /**
     * Modify the tags for multiple chats
     * @name API#mod_thread_tags
     * @function
     * @public
     * @param {object} params - object containing parameters
     *  @param {object} changes - threads to change
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     * @returns {JSON} returns a json object sith the result value
     */
    API.prototype.mod_thread_tags = function (params, options, error, success) {
        if (params === undefined || params.changes === undefined) {
            return error('Missing fields');
        }

        home_router = this.home_router || options.home_router;

        return this._post_message(params, {
            url: '2/cs/mod_thread_tags',
            home_router: home_router
        }, error, success);
    }

    /**
     * Create a url from parameters
     * @name API#_create_url
     * @function
     * @private
     * @returns {string} returns a string with the full url
     */
    API.prototype._create_url = function (params, options) {
        var query_params = params.query_params || {};
        options = options  || {};
        options.home_router = options.home_router || this.home_router;


        var url;

        if (!options.home_router) {

        } else {
            if (options.home_router.indexOf('https://')) {
                options.home_router = 'https://' + options.home_router;
            }

            // do not wrap get_body calls
            if (params.uri.indexOf('get_body') === -1) {
                //query_params.wrapped = true;
            }

            if (params.uri.indexOf('://') === -1) {
                url = options.home_router + "/" + params.uri;
            }
            else {
                url = params.uri;
            }

            if (Object.keys(query_params).length > 0) {
                url += "?";
            }
        }
        for (var arg in query_params) {
            if (query_params[arg]) {
                if (url[url.length - 1] === '?') {
                    url += arg + "=" + query_params[arg].toString();
                } else {
                    url += "&" + arg + "=" + query_params[arg].toString();
                }
            }
        }
        return url;
    };

    /**
     * Generate a message id
     * @name API#_generate_message_id
     * @function
     * @private
     * @returns {string} returns a string that can be used as a message id for voxer.
     */
    API.prototype._generate_message_id = function () {
        return new Date().getTime() + "_" + Math.floor((Math.random() * 10000000000) + 1000);
    };

    /**
     * Check if javascript is being run on server or client
     * @name API#_is_server
     * @function
     * @private
     * @returns {bool} returns true is code is running on server
     */
    API.prototype._is_server = function () {
        return ! (typeof window != 'undefined' && window.document);
    }

    API.prototype.has_stripe_id = function () {
        var customer = App.pbr_summary.get("customer");
        var capabilities = App.Auth.get("capabilities");
        return !!((capabilities && capabilities.has_stripe_id) || (customer && customer.id));
    }

    /**
     * Delete a user account
     * @name API#delete_account
     * @function
     * @public
     * @param {object} params - object containing parameters
     * @param {object} options - options for the ajax call
     *  @param {string=} home_router - home_router to use
     * @param {function} error - error callback
     * @param {function} success - success callback
     */
    API.prototype.delete_account = function (params, options, error, success) {

        options = options || {};
        var data = {}, router;

        if (typeof params !== 'object' || params.user_id === undefined ||
            params.user_id === undefined) {
            return error('Missing fields');
        }

        data = {
            "delete_source": 'webclient'
        };

        router = this.home_router || options.router;

        return this._post_message(
            data,
            {
                url: '2/cs/user_delete_account',
                home_router: router
            },
            error,
            success
        );
    };

    /**
     * Search message globally
     * @name API#search_message
     * @function
     * @public
     * @param {object} params - All data of message to send
     * @param {object} options
     * @param {function} error - error callback
     * @param {function} success - success callback
     */
        API.prototype.search_message = function (params, options, error, success) {
            var options = options || {},
                home_router = this.home_router || options.router;

            return this._post_message(params, {
                url: '/1/chat/search_messages',
                home_router: home_router
            }, error, success);
    };

    /**
     * Transcribe audio message
     * @name API#transcribe_message
     * @function
     * @public
     * @param {object} params - All data of message to send
     * @param {object} options
     * @param {function} error - error callback
     * @param {function} success - success callback
     */
    API.prototype.transcribe_message = function (params, options, error, success) {
        var options = options || {},
            home_router = this.home_router || options.router;

        return this._get_message(params, {
            url: '/message/transcribe/3',
            home_router: home_router
        }, error, success);
    }

    /**
     * Summarize audio message
     * @name API#summarize_message
     * @function
     * @public
     * @param {object} params - All data of message to send
     * @param {object} options
     * @param {function} error - error callback
     * @param {function} success - success callback
     */
    API.prototype.summarize_message = function (params, options, error, success) {
        var options = options || {},
            home_router = this.home_router || options.router;

        return this._get_message(params, {
            url: '/message/summarize/1',
            home_router: home_router
        }, error, success);
    }

    /**
     * Resend verificatio email
     * @name API#resend_verify_email
     * @function
     * @public
     * @param {object} params - All data of message to send
     * @param {object} options
     * @param {function} error - error callback
     * @param {function} success - success callback
     */
    API.prototype.resend_verify_email = function (params, options, error, success) {
        var options = options || {},
            home_router = this.home_router || options.router;

        return this._get_message(params, {
            url: '/2/cs/resend_verify_email',
            home_router: home_router
        }, error, success);
    }

    /**
     * Submit feedback
     * @name API#submit_feedback
     * @function
     * @public
     * @param {object} params - All data of message to send
     * @param {object} options
     * @param {function} error - error callback
     * @param {function} success - success callback
     */
    API.prototype.submit_feedback = function (params, options, error, success) {
        var options = options || {},
            home_router = this.home_router || options.router;

        return this._post_message(params, {
            url: '/submit_feedback/1',
            home_router: home_router
        }, error, success);
    }

    return API;
}));

