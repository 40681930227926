define('main/settings',[
    // Libraries.
    'jquery',
    'underscore',
    'backbone',
    'main/cache'
], function ($, _, Backbone, Cache) {
    'use strict';

    var Settings = Backbone.Model.extend({
        defaults: function () {
            return {
                'talk-method': 'PTT',
                'sort-by': 'first-name',
                'sound-on': true,
                'sound-level': 4,
                'finished_setup': false,
                'mic_index': 0,
                'location': false,
                'notifications-on': true,
                'desktop-notifications': false,
                'zapier-pop-up': false,
                'cache-timeline': true,
                'user_settings': {},
                'geo': undefined
            };
        },
        initialize : function () {
            var keys = _.keys(this.attributes);
            _.each(keys, function (key) {
                var tmp = Cache.fetch(key);
                if (tmp !== undefined) {
                    this.set(key, tmp);
                }
            }, this);

            this.on('change', function () {
                var attributes = this.changedAttributes();
                this.save(attributes);
            });

            this.on("change:location", function () {
                if (window.App !== undefined) {
                    App.set_location();
                }
            });
        },
        fetch : function (key) {
            var tmp;
            if (this.has(key)) {
                tmp = this.get(key);
            }
            else {
                tmp = Cache.fetch(key);
                var val = {};
                val[key] = tmp;
                this.set(val);
            }
            if (tmp === "true") {
                tmp = true;
            } else if (tmp === "false") {
                tmp = false;
            }
            return tmp;
        },
        delete : function (key, silent) {
            this.unset(key);
            Cache.delete(key);
            if (!silent) {
                this.initialize();
            }
        },
        save : function (values) {
            var keys = (values === undefined) ? _.keys(this.attributes) : _.keys(values);
            _.each(keys, function (key) {
                Cache.sync(key, this.get(key));
            }, this);
        },
        destroy: function () {
            this.clear({silent: true});
            Cache.destroy();
            Cache.initialize();
            this.set(this.defaults());
        },

        set_volume: function () {
            var volume = this.fetch('sound-level') + '';
            if (volume === '2') {
                volume = 10;
            } else if (volume === '4') {
                volume = 50;
            } else if (volume === '6') {
                volume = 70;
            } else {
                volume = 100;
            }
            
            return volume;
        }
    });

    return new Settings();
});
