var client;
var live_audio_playing = {};
var playing_message_id;
var playing_watchers = [];

function set_client(in_client) {
    if (client === in_client) {
        return;
    }
    client = in_client;
    _start_playing();
}

function set_playing(message_id) {
    if (message_id === playing_message_id) {
        return;
    }
    playing_message_id = message_id;
    playing_watchers.forEach(function(watcher) {
        watcher();
    });
    _start_playing();
}

define('modules/voxer_audio_player',[
    "app", "binaryjs"
], function(App, binaryjs) {
    function get_live_audio(message_id, onStartCallback, onStopCallback, onWhilePlaying, position) {
        var sound = live_audio_playing[message_id];
        if (!sound) {
            sound = {
                message_id: message_id,
                state: "stopped",
                completed: false,
                outOfSequence: false,
                play: play,
                stop: stop,
                pause: pause,
                resume: resume,
                playbackRate: playbackRate,
                setPosition: setPosition
            };
            playing_watchers.push(function() {
                if (playing_message_id === message_id) {
                    sound.state = "playing";
                    sound.onStartCallback(sound);
                    sound.onWhilePlaying(sound.position);
                } else {
                    if (sound.state === "playing") {
                        if (sound.position) {
                            sound.state = "paused";
                        } else {
                            sound.state = "stopped";
                        }
                        sound.onStopCallback(sound);
                    }
                }
            });
        }
        sound.onStartCallback = onStartCallback;
        sound.onStopCallback = onStopCallback;
        sound.onWhilePlaying = onWhilePlaying;
        sound.position = position || 0;
        function play() {
            set_playing(message_id);
            onStartCallback(sound);
        }
        function stop() {
            sound.position = 0;
            sound.onWhilePlaying(sound.position);
            set_playing(null);
        }
        function pause() {
            set_playing(null);
        }
        function resume() {
            set_playing(message_id);
        }
        function playbackRate(rate) {
            console.log("voxer_audio playbackRate", rate, message_id);
        }
        function setPosition(in_position) {
            sound.position = in_position;
            set_playing(message_id);
        }
        live_audio_playing[message_id] = sound;
        return sound;
    };

    return {set_client: set_client, live_audio_playing: live_audio_playing, get_live_audio: get_live_audio}
});

var BUFFER_SIZE = 512;
var MAX_AHEAD = 2;
var decoder = new Worker("/assets/js/decoder.js");
var silent_buffer = new Float32Array(BUFFER_SIZE);
var timeout;

var audioContext;
function _start_playing() {
    if (audioContext) {
        audioContext.close();
        audioContext = null;
    }
    if (timeout) {
        clearTimeout(timeout);
    }
    var sound = live_audio_playing[playing_message_id];
    if (!App.VoxerAudio.socket_connected) {
        App.VoxerAudio.connect_ws(App.webserver)
        return
    }
    if (!client || !sound) {
        return;
    }
    sound.position = Math.max(0, Math.min(sound.position || 0));
    var message = (App.messages.get(playing_message_id) || {}).attributes || {};
    audioContext = new window.AudioContext();
    var sample_rate = audioContext.sampleRate || 44100;
    var playback_ratio = Math.round((512 * sample_rate) / 44100) / 512; //more complicated fractions make the playback clicky
    var expected_buffers_length = Math.ceil((message.audio_duration_ms || 0) * sample_rate * 0.001 / BUFFER_SIZE);
    if (!sound.is_fully_loaded) {
        _fill_buffers(sound);
    }
    var playing_index = Math.round(sound.position * expected_buffers_length);
    var buffer_duration = playback_ratio * BUFFER_SIZE / sample_rate;
    var offset = -playing_index * buffer_duration;
    var last_position = sound.position;
    function play_buffers() {
        if (sound !== live_audio_playing[playing_message_id]) {
            return;
        }
        if (last_position !== sound.position) {
            _start_playing();
            return;
        }
        while (playing_index < sound.buffers.length) {
            var should_play_at = playing_index * buffer_duration + offset;
            if (should_play_at > audioContext.currentTime + MAX_AHEAD) {
                break;
            }
            var buffer = audioContext.createBuffer(1, BUFFER_SIZE, sample_rate);
            buffer.getChannelData(0).set(sound.buffers[playing_index]);
            var bufferSource = audioContext.createBufferSource();
            bufferSource.loop = false;
            bufferSource.playbackRate.value = 1 / playback_ratio;
            bufferSource.buffer = buffer;
            bufferSource.connect(audioContext.destination);
            if (should_play_at < audioContext.currentTime) {
                offset -= should_play_at - audioContext.currentTime;
                bufferSource.start(0);
            } else {
                bufferSource.start(should_play_at);
            }
            playing_index++;
        }
        var duration_s = Math.max(0.001 * message.audio_duration_ms || 0, sound.buffers.length * buffer_duration);
        var current_s = audioContext.currentTime - offset;
        var max_s = playing_index * buffer_duration;
        sound.position = Math.min(1, Math.min(max_s, current_s) / duration_s);
        last_position = sound.position;
        sound.onWhilePlaying(sound.position);
        //console.log("current_s:", current_s, "max_s", max_s, "offset", offset, sound.message_id);
        if (sound.is_fully_loaded && current_s >= max_s) {
            sound.stop();
            return;
        }
        timeout = setTimeout(play_buffers, 10);
    }
    play_buffers();
}

function _fill_buffers(sound) {
    if (sound.is_downloading) {
        return;
    }
    sound.is_downloading = true;
    sound.buffers = [];
    var channel = new MessageChannel();
    decoder.postMessage('channel', [channel.port2]);
    var current_buffer = new Float32Array(BUFFER_SIZE);
    var current_buffer_position = 0;
    function write_to_buffer(partial_buffer) {
        partial_buffer.forEach(function(amplitude) {
            current_buffer[current_buffer_position] = amplitude / 32768;
            current_buffer_position++;
            if (current_buffer_position >= BUFFER_SIZE) {
                sound.buffers.push(current_buffer);
                current_buffer_position = 0;
                current_buffer = new Float32Array(BUFFER_SIZE);
            }
        })
    }
    function check_fully_loaded() {
        if (received_end && queue_size === 0) {
            sound.buffers.push(current_buffer);
            sound.is_fully_loaded = true;
            sound.is_downloading = false;
            console.log("fully transcoded", sound.message_id);
        }
    }
    var queue_size = 0;
    var received_end = false;
    var offset = 0; //Math.floor(sound.position * message.audio_length_bytes);
    stream = client.createStream({content_type: "raw_live_audio", message_id: sound.message_id, offset: offset});
    stream.on("data", function(data) {
        if (data && data.byteLength) {
            queue_size++;
            channel.port1.onmessage = function on_decoded_handler(e) {
                if (e.data.is_done) {
                    queue_size = 0;
                }
                if (e.data.buffer) {
                    write_to_buffer(new Int16Array(e.data.buffer))
                }
                check_fully_loaded();
            };
            channel.port1.postMessage({cmd: 'decode_buffer', buffer: data});
        }
    });
    stream.on("end", function() {
        console.log("received end", sound.message_id);
        received_end = true;
        check_fully_loaded();
    });
    stream.on("error", function(data) {
        console.log("error", data);
        sound.is_downloading = false;
        sound.stop();
    });
}
;
